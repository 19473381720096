import React from "react";
import {FlexRowContainer} from "../../styledcomponents/MiscStyledComponents";
import styled from "styled-components";

const PaginationWrapper = styled.div`
  margin-top: 1rem;
`

const PaginationButton = styled.button`
  cursor: pointer;
  margin-left: .5rem;
  margin-right: .5rem;
  background-color: white;
  border: none;
  font-size: 1.5rem;
  opacity: .6;
  &:hover:enabled {
    opacity: 1;
  }
`

type PaginationProps = {
    canPreviousPage : boolean;
    canNextPage     : boolean;
    pageIndex       : number;
    pageCount       : number;
    gotoPage        : (data : number) => void;
    previousPage    : () => void;
    nextPage        : () => void;
};
  
const Pagination = (props : PaginationProps) => {
    const {canPreviousPage, canNextPage, pageIndex, pageCount, gotoPage, previousPage, nextPage} = props

    return (
        <PaginationWrapper>
          <FlexRowContainer style={{justifyContent: 'space-between'}}>
            <div>
              <PaginationButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</PaginationButton>
              <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</PaginationButton>
            </div>
            Page {pageCount > 0 ? pageIndex + 1 : 0} of {pageCount}
            <div>
              <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</PaginationButton>
              <PaginationButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</PaginationButton>
            </div>
          </FlexRowContainer>
        </PaginationWrapper>
    );
};

export default Pagination;