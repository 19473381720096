import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

const tableActionItemStyles = css`
  text-decoration: none;
  color: #0060A9;
  cursor: pointer;
  &:visited {
    color: #0060A9;
  }
  &:hover {
    text-decoration: underline;
  }
`

const StyledTableLink = styled(Link)`
  ${tableActionItemStyles}
`

const TableActionItem = styled.span`
  ${tableActionItemStyles}
`

const TableHeader = styled.th<{size?: number}>`
  background: #E6F2FA;
  border: 1.1px solid #CCCCCC;
  text-align: initial;
  padding: 0.5rem;
  min-width: 7em;
  width: calc(100%/${props => props.size ? 900/props.size : 6});
`

const StyledTable = styled.table<{fixedWidth?: number}>`
  width: 100%;
  border: 1px solid #CCCCCC;
  border-collapse: collapse;
  table-layout: ${props => props.fixedWidth ? 'fixed' : 'auto'};
  min-width: ${props => props.fixedWidth ? `${props.fixedWidth}em` : 'auto'};
`

const TableData = styled.td<{wordWrapAll?: boolean}>`
  border: 1px solid #CCCCCC;
  padding: 0.5rem;
  ${props => props.wordWrapAll ? 'word-break: break-all;' : ''}
`

export {StyledTableLink, TableActionItem, TableHeader, StyledTable, TableData}