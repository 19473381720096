export const enum Status {
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    ALREADY_EXISTS = "ALREADY_EXISTS",
    INVALID_EXTENSION = "INVALID_EXTENSION",
    INVALID_SIZE = "INVALID_SIZE",
    INVALID_EXTENSION_AND_SIZE = "INVALID_EXTENSION_AND_SIZE",
    RESERVED_FILENAME = "RESERVED_FILENAME"
}

export enum AttachmentType {
    SELECT_ONE = "Select One",
    ONE_LINE_DIAGRAM = "One-Line Diagram",
    SITE_PLAN = "Site Plan",
    INVERTER_DATA_SHEET = "Inverter Data Sheet",
    NET_METER_RIDER = "Net Meter Rider",
    AC_DISCONNECT_CUT_SHEET = "AC Disconnect Cut Sheet",
    BATTERY_SPEC_SHEET = "Battery Spec Sheet",
    INTERCONNECTION_AGREEMENT = "Interconnection Agreement",
    PANEL_SPEC_SHEET = "Panel Spec Sheet",
    PERMISSION_TO_OPERATE = "Permission to Operate",
    PICTURE = "Picture",
    PROOF_OF_INSPECTION = "Proof of Inspection",
    PPA_AGREEMENT = "PPA Agreement",
    PV_WATT = "PV Watt",
    SCADA_COMMISSIONING = "Scada Commissioning",
    SITE_CONTROL = "Site Control",
    SOLAR_PANEL_CUT_SHEET = "Solar Panel Cut Sheet",
    LOAD_CALCULATOR = "Load Calculator",
    OTHER = "Other",
    TERMS_AND_CONDITIONS = "Signed Terms and Conditions",
	CUSTOMER_SIGNATURE = "Customer Signatures",
}

export const hiddenAttachmentTypes = [
    AttachmentType.TERMS_AND_CONDITIONS,
    AttachmentType.CUSTOMER_SIGNATURE,
]

export interface AttachmentStatus {
    type: AttachmentType,
    fileName: string,
    status: Status,
    createdDate: string,
}

export interface AttachmentResponse {
    attachmentsStatus: AttachmentStatus[]
    errorMessage: string
}