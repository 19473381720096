import {Divider, PageTitle} from "../../styledcomponents/MiscStyledComponents";
import React, {Dispatch, useEffect, useReducer} from "react";
import {GeneratorFacilityInformation, GeneratorFacilityInformationForm} from "./steps/GeneratorFacilityInformation";
import {ApplicationStepIcons} from "./helpers/ApplicationStepIcons";
import {useMessageService} from "../../hooks/useMessageService";
import {applicationFormReducer, FormAction, FormActionType} from "./helpers/applicationFormReducer";
import {useAuth} from "../../auth/AuthProvider";
import {AccountVerification} from "./steps/AccountVerification";
import {AccountLookup} from "./steps/AccountLookup";
import {CustomerInformation} from "./steps/CustomerInformation";
import {ReviewAndSubmit} from "./steps/ReviewAndSubmit";
import {SystemFormData} from "./forms/SystemForm";
import {EquipmentFormData} from "./forms/EquipmentForm";
import {AttachmentFormData} from "./forms/AttachmentForm";
import {useLocation, useNavigate} from "react-router-dom";
import {useAxios} from "../../AxiosProvider";
import {ApplicationFormAccount} from "../../models/ApplicationFormAccount";
import ApplicationFormUtils from "../../utils/applicationFormUtils";
import {AxiosError, AxiosResponse} from "axios";
import {ApplicationFees} from "./steps/ApplicationFees";
import {ApplicationFeesReview} from "./steps/ApplicationFeesReview";
import {ContactInformation, ContactInformationForm, ContactInformationFormData} from "./steps/ContactInformation";
import {AccountInfoBox} from "./helpers/AccountInfoBox";
import ErrorUtils from "../../utils/errorUtils";
import {ApplicationStatus} from "../../enums/ApplicationStatus";
import {FormFooterAction} from "../../styledcomponents/FormStyledComponents";
import {Page, usePath} from "../../PathProvider";
import {useModal} from "../../hooks/useModal";
import ConfirmationModal from "./helpers/ConfirmationModal";
import { ApplicationStep } from "../../enums/ApplicationStep";
import {InterconnectApplicantFormData} from "./forms/interconnectApplicant/types/InterconnectApplicantTypes";


export type ApprovalCodeAndFeesData = {
    approvalCode?: string,
    fees?: Fees
}

export type Fees = {
    fixedFee?: string,
    pricePerKw?: string,
    estimatedApplicationFee?: string,
}

export type AdditionalInformationData = {
    estimatedAnnualGeneration?: string,
    calculatedAnnualGeneration?: string,
    annualConsumption?: string,
    applicationLevel?: string,
    excessGenerationPlans?: string
}

export type PaAgreementExtraData = {
    equipInstallContractor ?: ContactInformationForm;
    electricalContractor ?: ContactInformationForm;
    consultingEngineer ?: ContactInformationForm;
    disconnectLockbox ?: string;
    nearestCrossingStreet ?: string;
    ampRating ?: string;
    inverterType ?: string;
    hasElectricContractor ?: boolean;
    hasEquipContractor ?: boolean;
    hasConsultingEngineer ?: boolean;
    serviceVoltage ?: string ;
    serviceCapacityAmps ?: string ;
    servicePhase ?: string ;
    meterNumber ?: string ;
}

export type BaseApplicationStepProps = {
  applicationFormsDispatch: Dispatch<FormAction>
  application : ApplicationForm | null
  triggerValidation?: Function
}

export type BaseChildFormStepProps = {
    setValid : (a: boolean) => void,
    triggerFlag : boolean;
}

export type BaseFormDataProps = {
}

export type LoadedApplication = {
    id: string,
    status : ApplicationStatus;
    dateSubmitted?: string;
    interconnectNumber: string;
    accountNumber?: string;
    premiseNumber?: string;
    accountName?: string;
    businessPartner?: string;
    customerEmail?: string;
    accountAddress?: string;
    accountCity?: string;
    accountState?: string;
    accountZipCode?: string;
    vendorId?: string;
    lastStepCompleted: ApplicationStep;
    applicationDataObject: any;
    operatingCompanyId?: string;
    addressAsHtml?: string;
    customerType : string;
}

export type ApplicationForm = {
    id?: string,
    status : ApplicationStatus,
    dateSubmitted?: string,
    interconnectNumber?: string,
    generatorFacilityInformation?: GeneratorFacilityInformationForm,
    system?: SystemFormData,
    equipment?: EquipmentFormData,
    attachments?: AttachmentFormData,
    contactInformationContainer?: ContactInformationFormData,
    interconnectApplicant ?: InterconnectApplicantFormData,
    approvalCodeAndFees?: ApprovalCodeAndFeesData,
    additionalInformation?: AdditionalInformationData,
    paAgreementExtra?: PaAgreementExtraData,
    currentStep: ApplicationStep,
    nextDisabled: boolean,
    steps: ApplicationStep[],
    processing: boolean,
    systemComplete?: boolean,
    equipmentComplete?: boolean,
    attachmentsComplete?: boolean,
    additionalComments?: string,
    contactsComplete?: boolean,
    additionalInfoComplete?: boolean,
    paExtraComplete?: boolean,
} & ({
      account: ApplicationFormAccount | undefined,
      accountPendingVerification?: never
} | {
      accountPendingVerification: ApplicationFormAccount | undefined
      account?: never
});
export const stepList: {account:  ReadonlyArray<ApplicationStep>, noAccount: ReadonlyArray<ApplicationStep>} = {
    account: [
        ApplicationStep.AccountLookup,
        ApplicationStep.AccountVerification,
        ApplicationStep.ContactInformation,
        ApplicationStep.GeneratorFacilityInformation,
        ApplicationStep.ApplicationFees,
        ApplicationStep.ApplicationFeesReview,
        ApplicationStep.ReviewAndSubmit
    ],
    noAccount: [
        ApplicationStep.CustomerInformation,
        ApplicationStep.ContactInformation,
        ApplicationStep.GeneratorFacilityInformation,
        ApplicationStep.ApplicationFees,
        ApplicationStep.ApplicationFeesReview,
        ApplicationStep.ReviewAndSubmit
    ]
}

export default function Application() {
    const auth = useAuth();
    const path = usePath();
    const messageService = useMessageService();
    const axios = useAxios();
    const navigate = useNavigate();
    const { state } = useLocation();
    const switchToNoAccountForm: boolean = state?.switchToNoAccountForm;
    const applicationId: string = state?.applicationId;

    let deleteApplicationModal = useModal();

    const [isLoadingApplication, setLoadingApplication] = React.useState<boolean>(false);
    const [isDoingNoAccountFlow, setIsDoingNoAccountFlow] = React.useState<boolean>(!!switchToNoAccountForm);

    // set the steps based on whether the user is a vendor or they're doing the no account flow
    let steps = (!!auth?.user?.isVendor && !isDoingNoAccountFlow
        ? stepList.account
        : stepList.noAccount
    ).slice();

    let initialApplication: ApplicationForm = {
        currentStep: auth.user?.isCustomer || isDoingNoAccountFlow ? ApplicationStep.CustomerInformation : ApplicationStep.AccountLookup,
        status : ApplicationStatus.Draft,
        account: undefined,
        nextDisabled: !!auth.user?.isVendor,
        steps: steps,
        processing: false,
        id: applicationId,
        systemComplete : false,
        equipmentComplete : false,
        attachmentsComplete : false
    }

    // create the application form object. If we have an applicationId then we will set the initial state to null and
    // make a call to get the application from the db, otherwise use initialApplication
    const [applicationForm, applicationFormDispatch] = useReducer(applicationFormReducer, applicationId ? null : initialApplication);

    useEffect(() => {
        if (applicationForm?.status && applicationForm.status != ApplicationStatus.Draft) {
            // this application is already past the draft stage, so go to app summary screen
            // user can end up in this state via the back button after submitting
            navigate(path.get(Page.ApplicationSummary), { state: { applicationId: applicationForm?.id } });
        }
    }, [applicationForm?.status])

    // if there is an applicationId, we're opening an existing application so look it up in the db
    React.useEffect(() => {
        if (applicationId) {
            setLoadingApplication(true);

            axios?.secureApi.get(
                `/api/application/get/${applicationId}`
            ).then(response => {
                let loadedApplication = {...response.data};
                if (loadedApplication?.applicationDataObject && state.setIncomplete) {
                    //allow the Application Summary link to force open (mark incomplete) either equipment or system collapsible
                    const completeProp = state.setIncomplete == 'equipment' ? "equipmentComplete" : "systemComplete";
                    loadedApplication.applicationDataObject[completeProp] = false;
                }
                applicationFormDispatch({ type: FormActionType.LOAD_APPLICATION, loadedApplication, isVendor: auth.user?.isVendor});
            }).catch(() => {
                messageService.error(ErrorUtils.UNKNOWN_ERROR)
            }).finally(() => {
                setLoadingApplication(false);
            })
        }
    }, [applicationId]);

    React.useEffect(() => {
        if (applicationForm?.interconnectApplicant || switchToNoAccountForm) {
            setIsDoingNoAccountFlow(true);
        }
    }, [applicationForm?.interconnectApplicant, switchToNoAccountForm])

    let content;
    switch (applicationForm?.currentStep) {
        case ApplicationStep.AccountLookup: content = <AccountLookup applicationFormsDispatch={applicationFormDispatch} application={applicationForm} />; break;
        case ApplicationStep.AccountVerification: content = <AccountVerification applicationFormsDispatch={applicationFormDispatch} application={applicationForm} />; break;
        case ApplicationStep.CustomerInformation: content = <CustomerInformation applicationFormsDispatch={applicationFormDispatch} application={applicationForm} noAccount={isDoingNoAccountFlow} />; break;
        case ApplicationStep.GeneratorFacilityInformation: content = <GeneratorFacilityInformation applicationFormsDispatch={applicationFormDispatch} application={applicationForm} />; break;
        case ApplicationStep.ApplicationFees: content = <ApplicationFees applicationFormsDispatch={applicationFormDispatch} application={applicationForm} noAccount={isDoingNoAccountFlow} />; break;
        case ApplicationStep.ApplicationFeesReview: content = <ApplicationFeesReview applicationFormsDispatch={applicationFormDispatch} application={applicationForm} />; break;
        case ApplicationStep.ContactInformation: content = <ContactInformation applicationFormsDispatch={applicationFormDispatch} application={applicationForm} />; break;
        case ApplicationStep.ReviewAndSubmit: content = <ReviewAndSubmit applicationFormsDispatch={applicationFormDispatch} application={applicationForm} />; break;
        default: content = <div>Could not load step</div>
    }

    // clear any messages whenever the currentStep changes
    React.useEffect(() => {
        messageService.clearAll();
    }, [applicationForm?.currentStep])

    // whenever the account changes, save the application to the db.
    // the account will only change one time when it is initially set
    React.useEffect(() => {
        if ((applicationForm?.account || applicationForm?.interconnectApplicant) && !applicationId) {
            // save application to db
            axios?.secureApi.put(
                '/api/application/saveEntireApplication',
                { ...ApplicationFormUtils.buildApplicationFormRequestBody(applicationForm, auth.user!) }
            ).then((response: AxiosResponse) => {
                // after the application is saved for the first time, update the id
                applicationFormDispatch({ type: FormActionType.SET_ID, id: response.data });
            }).catch((err: AxiosError) => {
                console.error(err);
                messageService.error('Error saving application.')
            })
        }
    }, [applicationForm?.account, applicationForm?.interconnectApplicant, applicationId])

    React.useEffect(() => {
        if (applicationForm?.id) {
            navigate('', { state: { applicationId: applicationForm?.id } });
        }
    }, [applicationForm?.id])

    // whenever isDoingNoAccountFlow changes (when it goes from undefined to true), reset the form because we are basically starting a new form
    React.useEffect(() => {
        if (isDoingNoAccountFlow && !applicationForm?.interconnectApplicant?.primaryPhone) {
            applicationFormDispatch({ type: FormActionType.RESET_FORM, initialState: initialApplication });
        }
    }, [isDoingNoAccountFlow])

    // if application is loading, show a loading message
    if (isLoadingApplication) {
        return <React.Fragment>Loading...</React.Fragment>;
    }

    const openDeleteModal = () => {
        deleteApplicationModal.setIsModalOpen(true);
    }

    const deleteApplication = async () => {
        // clear any messages once we submit
        messageService.clearAll();

        await axios?.secureApi.delete(
            `/api/application/delete/${applicationId}`,
        ).then((response : AxiosResponse) => {
            // if success then go back to application list
            navigate(path.get(Page.ApplicationList), { state: { successMessage: 'Successfully deleted application.' } });
        }).catch((err : AxiosError) => {
            console.error(err);
            messageService.error(ErrorUtils.UNKNOWN_ERROR);
        })
    };

    return (
        <React.Fragment>
                <PageTitle>
                    Interconnection Application
                    {
                        (applicationForm?.id && applicationForm?.status === ApplicationStatus.Draft) &&
                        <FormFooterAction onClick={openDeleteModal} float={"right"} marginTop={true}>DELETE APPLICATION</FormFooterAction>
                    }
                </PageTitle>
                <ConfirmationModal
                    isOpen={deleteApplicationModal.isModalOpen}
                    setIsOpen={deleteApplicationModal.setIsModalOpen}
                    title='Delete Application'
                    body='Are you sure you want to delete this application? Once you delete it, the application and data will no longer be saved or available.'
                    confirmText='Delete'
                    onConfirm={deleteApplication}
                />
                
            <ApplicationStepIcons applicationForm={applicationForm} noAccount={isDoingNoAccountFlow} applicationFormsDispatch={applicationFormDispatch}/>
            <Divider />
            {
                (applicationForm?.account || applicationForm?.interconnectApplicant) &&
                <AccountInfoBox account={applicationForm?.account} interconnectApplicant={applicationForm?.interconnectApplicant} />
            }
            {content}
        </React.Fragment>
    )
}