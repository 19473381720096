import styled, {css} from "styled-components";
import {FlexRowContainer} from "./MiscStyledComponents";
import {MessageType} from "../hooks/useMessageService";
import {SmallPrimaryButton} from "./FormStyledComponents";

const AlertContainer = styled.div<{noMargin ?: boolean}>`
  margin : ${props => props.noMargin ? '0' : '2rem 0 0 0'};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`

const MessageContainerLeft = styled.div<{type : MessageType}>`
  flex: 5%;
  border-radius: 6px 0 0 6px;
  padding: .6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => {
      switch (props.type) {
        case 'success' : return '#4D7D00';
        case 'warning' : return '#F3AF3D';
        case 'error'   : return '#A52B1D';
        case 'info'    : return '#0060A9';
      }
  }};
`

const MessageContainerRight = styled(FlexRowContainer)<{type : MessageType}>`
  flex: 95%;
  border-radius: 0 6px 6px 0;
  padding: 15px;
  justify-content: space-between;
  background: ${props => {
    switch (props.type) {
      case 'success' : return '#F2FAE6';
      case 'warning' : return '#FEEBCB';
      case 'error'   : return '#F2E0DE';
      case 'info'    : return '#E6F2FA';
    }
  }};
  border: 1px solid ${props => {
    switch (props.type) {
      case 'success' : return '#4D7D00';
      case 'warning' : return '#F3AF3D';
      case 'error'   : return '#A52B1D';
      case 'info'    : return '#0060A9';
    }
  }};
  color: ${props => {
    switch (props.type) {
      case 'success' : return '#4D7D00';
      case 'warning' : return 'black';
      case 'error'   : return '#A52B1D';
      case 'info'    : return '#0060A9';
    }
  }};
`

const MessageList = styled.ul`
  padding: 0;
  margin : 0
`

const MessageListItem = styled.li`
  margin-left: 1rem;
  &:only-child {
    list-style-type: none;
    margin-left: 0;
  }
`

const AlertIcon = styled.img`
  height: 24px;
`

const ErrorAlertButton = styled(SmallPrimaryButton)`
  /*Override the colors*/
  background-color: #A52B1D;
  border: 1px solid #A52B1D;
  color: #fff;

  &:hover:enabled {
    color: #fff !important;
    background-color: #7B2015 !important;
  }
`

const SuccessAlertButton = styled(SmallPrimaryButton)`
  /*Override the colors*/
  background-color: #4D7D00;
  border: 1px solid #4D7D00;
  color: #fff;

  &:hover:enabled {
    color: #fff !important;
    background-color: #335200 !important;
  }
`

const WarningAlertButton = styled(SmallPrimaryButton)`
  /*Override the colors*/
  background-color: #F3AF3D;
  border: 1px solid #F3AF3D;
  color: black;

  &:hover:enabled {
    color:  black !important;
    background-color: #E08700 !important;
  }
`

const InfoAlertButton = styled(SmallPrimaryButton)`
  /*Override the colors*/
  background-color: #0060A9;
  border: 1px solid #0060A9;
  color: #fff;

  &:hover:enabled {
    color: #fff !important;
    background-color: #00477D !important;
  }
`

export {MessageList, MessageContainerLeft, MessageContainerRight, AlertIcon, AlertContainer, MessageListItem,
  ErrorAlertButton, SuccessAlertButton, WarningAlertButton, InfoAlertButton}