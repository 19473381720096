import React from 'react'
import {
    usePagination,
    useTable,
} from 'react-table'
import InnerTable, {TableInstanceWithHooks, TableProps} from "./InnerTable";

export default function Table<T extends Object>(props: TableProps<T>) {
    const table = useTable({
        columns : props.columns,
        data : props.data,
        initialState : {
            hiddenColumns : props.hiddenColumns ?? [],
            pageIndex: 0
        }
    }, usePagination) as TableInstanceWithHooks<T>

    return ( <InnerTable {...table} /> )
}