import React, {createContext, ReactElement, useContext, useMemo} from "react";

import {useNavigate} from "react-router-dom";
import axios from "axios";
import AuthResponse from "../models/AuthResponse";
import {Page, PathMap} from "../PathProvider";
import {GlobalState, useStateMachine} from "little-state-machine";
import AccountInfoUtils from "../utils/accountInfoUtils";
import {VendorInfo} from "../models/VendorInfo";
import { getBackendHost } from "../utils/externalUrl";

const AuthContext = createContext<AuthContextTypes>({
    user: null,
    login : () => null,
    loginVendorAfterRegister : (data: AuthResponse) => null,
    logout : () => null,
    setNewAccessToken: () => null,
    updateCurrentAccount: () => null,
    updateVendorInfo: () => null,
});

interface AuthProviderProps {
    children : ReactElement
}

export type AuthContextTypes = {
    user: AuthResponse | null;
    login : (data: AuthResponse) => void;
    loginVendorAfterRegister : (data: AuthResponse) => void;
    logout : (data?: string) => void;
    setNewAccessToken : (data : string) => void;
    updateCurrentAccount : (data : any) => void;
    updateVendorInfo : (data : VendorInfo, login: string) => void;
};

function updateUser(state : GlobalState, user : AuthResponse) {
    return {
        ...state,
        user : user
    };
}

function clearUser(state : GlobalState) {
    return {
        ...state,
        user : null
    };
}

export function AuthProvider(props : AuthProviderProps) {
    const {actions, state : {user}} = useStateMachine({clearUser, updateUser});
    const navigate = useNavigate();

    const loginVendorAfterRegister = (data: AuthResponse) => {
        data.isVendor = true;
        login(data, true);
    }

    // call this function when you want to authenticate the user
    const login = async (data : AuthResponse, isRegister?: boolean) => {
        if (data.isCustomer) {
            data.currentAccount = AccountInfoUtils.findDefaultAccount(data.accounts!);
        } else {
            // Getting a vendor's information upon login. useAxios hook isn't used here because it is not yet defined.
            await axios.request({
                baseURL: getBackendHost(),
                headers: {'Authorization': 'Bearer ' + data.accessToken},
                method: "GET",
                url: `api/users/user`
            }).then(response => {
                data.vendor = response.data;
            }).catch(err => {
                console.error(err);
            })
        }

        actions.updateUser(data);
        // we use PathMap here instead of usePath because the PathProvider is inside the AuthProvider, thus we don't
        // have access to the usePath hook in here

        if (isRegister) {
            // We should login and navigate to the successful account creation page
            navigate(PathMap.get(Page.FinalizeRegistrationSuccess));
        } else {
            navigate(PathMap.get(Page.ApplicationList));
        }

    };

    // call this function to sign out logged in user
    const logout = (message? : string) => {
        actions.clearUser();

        // we use PathMap here instead of usePath because the PathProvider is inside the AuthProvider, thus we don't
        // have access to the usePath hook in here
        navigate(PathMap.get(Page.Login), { replace: true, state : {warningMessage : message ?? 'You have been successfully logged out'} });
    };

    const setNewAccessToken = (accessToken : string) => {
        if (user) {
            actions.updateUser({...user, accessToken : accessToken});
        } else {
            logout();
        }
    };

    const updateCurrentAccount = (account : any) => {
        if (user) {
            actions.updateUser({...user, currentAccount : account});
        } else {
            logout();
        }
    };

    const updateVendorInfo = (vendor : VendorInfo, login: string) => {
        if (user) {
            actions.updateUser({...user, vendor: vendor, username: login});
        } else {
            logout();
        }
    };


    const value = useMemo<AuthContextTypes>(
        () => ({
            user,
            login,
            loginVendorAfterRegister,
            logout,
            setNewAccessToken,
            updateCurrentAccount,
            updateVendorInfo
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};