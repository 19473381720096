import {
    DetailP, Divider,
    FlexRowContainer,
    GridColumnContainer,
    PageSubTitle
} from "../../../styledcomponents/MiscStyledComponents";
import {BaseApplicationStepProps, BaseFormDataProps} from "../Application";
import React, {useEffect, useState} from "react";
import {ApplicationStepButtons, saveStep} from "../helpers/ApplicationStepButtons";
import {FormProvider, SubmitHandler, useFieldArray, useForm, useWatch} from "react-hook-form";
import {useAxios} from "../../../AxiosProvider";
import {useMessageService} from "../../../hooks/useMessageService";
import {useAuth} from "../../../auth/AuthProvider";
import {Form, FormFooterAction, Label, StyledLinkSecondaryButton} from "../../../styledcomponents/FormStyledComponents";
import {Input, ControlledPhoneInput} from "../../form/FormElements";
import {FormActionType} from "../helpers/applicationFormReducer";
import styled from "styled-components";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";
import {AxiosError, AxiosResponse} from "axios";
import GreyBanner from "../../misc/GreyBanner";
import { ApplicationStep } from "../../../enums/ApplicationStep";
import { Pattern } from "../../../enums/Pattern";

const Header = styled.h4`
    font-size: 1.2rem;
`
const ContactButtonContainer = styled(FlexRowContainer)`
  justify-content: space-between;
`
const ButtonContainer = styled.div`
    display: grid;
    gap: 5px;
    margin: 12px 0;
`

export type ContactInformationForm = {
    name        ?: string,
    title       ?: string,
    company     ?: string,
    phoneNumber ?: string,
    email       ?: string,
    address     ?: string,
    city        ?: string,
    state       ?: string,
    zipCode     ?: string,
}

export type ContactInformationFormData = BaseFormDataProps & {
    contactInformation : ContactInformationForm[]
}

const defaultValues = {
    name        : '',
    title       : '',
    company     : '',
    phoneNumber : '',
    email       : ''
}

type ContactInformationProps = BaseApplicationStepProps & {}

const getAdditionalContactsValue = (formValues: ContactInformationForm[]): ContactInformationForm[] => {
    return formValues?.map((item) => {
        return {
            ...item,
        }
    })
}

export function ContactInformation(props : ContactInformationProps) {
    const auth = useAuth();
    const axios = useAxios();
    const messageService = useMessageService();

    const methods = useForm<ContactInformationFormData>({
        defaultValues: {
            contactInformation:
                props.application?.contactInformationContainer?.contactInformation?.length
                    ? props.application?.contactInformationContainer?.contactInformation
                    : [{}]
        },
        mode: "onChange"
    });

    const { handleSubmit, getValues, setValue, control, watch, reset, formState: { isSubmitting, isDirty, isValid, errors } } = methods;
    let watchContacts = useWatch({control, name: 'contactInformation'});

    const { fields, append, remove } = useFieldArray({
        control,
        name: "contactInformation"
    });


    const onRemoveContact = (index: number) => {remove(index)}
    const onAddContact = () => {append({...defaultValues,})}

    // useEffects
    useEffect(() => { 
        // Run once after form has initialized. If we don't already have a saved contact (from API), pre-populate.
        // The reason this is not in the defaultValues is because we want the form to be dirty (make them save it).
        if (!props.application?.contactInformationContainer?.contactInformation?.[0]?.name) {
            setValue("contactInformation",  [
                {
                    name : props.application?.interconnectApplicant
                        ? props.application?.interconnectApplicant?.locationHome.toString() == true.toString() ? `${props.application.interconnectApplicant.firstName} ${props.application.interconnectApplicant.lastName}` : props.application.interconnectApplicant?.businessName!
                        : props.application?.account?.name ?? '',
                    phoneNumber : props.application?.interconnectApplicant ? props.application.interconnectApplicant.primaryPhone : '',
                    email : props.application?.interconnectApplicant ? props.application.interconnectApplicant.email : '',
                    title : 'Customer',
                    company: ''
                }
            ], {shouldValidate: true});
        }
        
    }, [getValues])

    useEffect(() => {
        let enableNext = !isSubmitting && isValid;
        if (props.application?.nextDisabled == enableNext) {
            props.applicationFormsDispatch({type: enableNext ? FormActionType.ENABLE_NEXT : FormActionType.DISABLE_NEXT});
        }
    }, [isSubmitting, isValid, props.application?.nextDisabled]);

    useEffect(() => {
        if (isDirty) {
            const contactsList = getAdditionalContactsValue(getValues().contactInformation);
            const contactInformation = {
                contactInformation: contactsList,
            }
    
            props.applicationFormsDispatch({
                type: FormActionType.UPDATE_CONTACT_INFORMATION,
                contactInformation,
            })
        }
       
    }, [watchContacts, isDirty]);

    const onSubmit : SubmitHandler<ContactInformationFormData> = async (data : ContactInformationFormData) => {
        // clear any messages once we submit
        messageService.clearAll();

        let wasNextEnabled = !props.application?.nextDisabled;

        // disable next button while processing
        props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        props.applicationFormsDispatch({type : FormActionType.START_PROCESSING});
        // make call to save current state of application
        await axios?.secureApi.put("/api/application/saveAdditionalContacts",
            {...ApplicationFormUtils.buildApplicationFormRequestBody(props.application!, auth.user!),
            })
            .then((response: AxiosResponse) => {
                props.applicationFormsDispatch({type: FormActionType.COMPLETE_CONTACTS});
                messageService.success("Successfully saved Contact Information.");
                // reset the form with the entered values so the isDirty flag behaves as expected
                reset(data)
                //proceed to next page and save progress
                const saveNextStep = (step: ApplicationStep) => saveStep(step, axios, props.application?.id!);
                props.applicationFormsDispatch({ type: FormActionType.FORWARD, saveStep: saveNextStep });
            }).catch((err: AxiosError) => {
                if (wasNextEnabled) {
                    props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
                }
                console.error(err)
                messageService.error("Error saving Contact Information.")
            }).finally(() => {
                props.applicationFormsDispatch({type : FormActionType.END_PROCESSING});
            })
    };

    return (
        <React.Fragment>
            <PageSubTitle>Contact Information</PageSubTitle>
            <p>You may add up to five contacts.</p>
            <FormProvider {...methods}>
                <Form onSubmit={() => false}>
                    {
                        fields.map((item, index) => {
                            const errorFields = errors?.contactInformation?.[index] as any
                            return (
                                <div key={item.id}>
                                    {
                                        index > 0 &&
                                        <Divider />
                                    }
                                        <GreyBanner title={`Contact ${index + 1} ${index == 0 ? '(Required)' : ''}`} button={
                                            (fields.length > 1 && index != 0) ?
                                            <StyledLinkSecondaryButton onClick={() => onRemoveContact(index)}>REMOVE CONTACT</StyledLinkSecondaryButton> : undefined
                                        }/>

                                    <GridColumnContainer columns={1}>
                                        <div>
                                            <Label htmlFor={`contactInformation.${index}.name`} >Name</Label>
                                            <Input name={`contactInformation.${index}.name`} required={true} maxLength={100} error={errorFields?.name} disabled={index == 0}/>
                                        </div>
                                    </GridColumnContainer>
                                    <GridColumnContainer columns={2}>
                                        <div>
                                            <Label htmlFor={`contactInformation.${index}.title`} >Title</Label>
                                            <Input name={`contactInformation.${index}.title`} required={true} maxLength={50} error={errorFields?.title} disabled={index == 0} />
                                            <DetailP>Example contacts include Alternate Contact, Installer/Contractor, Electrical Contractor, Consulting Engineer</DetailP>
                                        </div>
                                        <div>
                                            <Label htmlFor={`contactInformation.${index}.company`} >Company (Optional)</Label>
                                            <Input name={`contactInformation.${index}.company`} maxLength={50} error={errorFields?.company} />
                                        </div>
                                    </GridColumnContainer>
                                    <GridColumnContainer columns={2}>
                                        <div>
                                            <Label htmlFor={`contactInformation.${index}.email`} >Email</Label>
                                            <Input type={'email'} name={`contactInformation.${index}.email`} pattern={Pattern.Email} required={true} maxLength={80} error={errorFields?.email} />
                                        </div>
                                        <div>
                                            <Label htmlFor={`contactInformation.${index}.phoneNumber`} >Phone Number</Label>
                                            <ControlledPhoneInput name={`contactInformation.${index}.phoneNumber`} required={true} error={errorFields?.phoneNumber} />
                                        </div>
                                    </GridColumnContainer>
                                </div>
                            )
                    })}
                    <ButtonContainer>
                        {
                            fields.length < 5 &&
                            <FormFooterAction type="button" float="left" marginTop={true} onClick={onAddContact} >ADD ADDITIONAL CONTACT</FormFooterAction>
                        }
                    </ButtonContainer>
                </Form>
            </FormProvider>
            <ApplicationStepButtons {...props} onNext={handleSubmit(onSubmit)}/>
        </React.Fragment>
    )
}