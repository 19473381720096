import React from 'react';
import {ContactUsLink} from "./ContactUsLink";


type ContactUsErrorProps = {
    errorDetails : string
}

export function ContactUsError(props : ContactUsErrorProps) {
    return(
       <React.Fragment>
            <div>
                Unfortunately, you are unable to submit an application at this time.<br/>
                Please <ContactUsLink /> for assistance.<br/>
                {
                    props.errorDetails && 
                        <React.Fragment>
                            <br/>
                            Error Details: {props.errorDetails}
                        </React.Fragment>
                }
            </div>
       </React.Fragment>
    )
}