import {ApplicationFormAccount} from "../../../models/ApplicationFormAccount";
import React from "react";
import styled from "styled-components";
import {MessageContainerRight} from "../../../styledcomponents/AlertStyledComponents";
import {InterconnectApplicantFormData} from "../forms/interconnectApplicant/types/InterconnectApplicantTypes";

const AccountInfoContainer = styled(MessageContainerRight)`
  display: block;
  color: black;
  margin: 1rem 0;
  border-radius: 6px;
`

type AccountInfoBoxProps = {
    account ?: ApplicationFormAccount
    interconnectApplicant ?: InterconnectApplicantFormData
}

export function AccountInfoBox(props : AccountInfoBoxProps) {
    return (
        <React.Fragment>
            {
                props.interconnectApplicant
                    ? <AccountInfoContainer type={'info'}>
                        {
                            props.interconnectApplicant.businessName
                                ? props.interconnectApplicant.businessName
                                : `${props.interconnectApplicant?.firstName} ${props.interconnectApplicant?.lastName}`
                        }
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: props.interconnectApplicant?.serviceAddress?.displayAddress ?? '' }} />
                    </AccountInfoContainer>
                    : <AccountInfoContainer type={'info'}>
                        <b>{props.account?.contractAccount}</b>
                        <br />
                        {props.account?.name}
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: props.account?.addressAsHtml ?? '' }} />
                    </AccountInfoContainer>
            }
        </React.Fragment>
    )
}