import {DropdownItem, GridColumnContainer, PageSubTitle} from "../../../styledcomponents/MiscStyledComponents";
import {BaseApplicationStepProps} from "../Application";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {ApplicationStepButtons, saveStep} from "../helpers/ApplicationStepButtons";
import {FormProvider, SubmitHandler, useForm, useWatch} from "react-hook-form";
import {useAxios} from "../../../AxiosProvider";
import {useMessageService} from "../../../hooks/useMessageService";
import {useAuth} from "../../../auth/AuthProvider";
import {Form, Label, PrimaryButton, StyledFileInputLabel, StyledInput, StyledOption} from "../../../styledcomponents/FormStyledComponents";
import {Input} from "../../form/FormElements";
import {FormActionType} from "../helpers/applicationFormReducer";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";
import {AxiosError, AxiosResponse} from "axios";
import { TAG_SOLAR_PV } from "../../../utils/constants";
import { State } from "../../../enums/State";
import { Pattern } from "../../../enums/Pattern";
import { UploadSpecificDocumentBox } from "../helpers/UploadSpecificDocumentBox";
import { AttachmentType } from "../../../models/AttachmentResponse";
import ExternalLink from "../../misc/ExternalLink";
import { getAgreementUrl } from "../../../utils/termsAgreementUtil";
import { PaAgreementExtraForm } from "../forms/PaAgreementExtraForm";
import { ApplicationStep } from "../../../enums/ApplicationStep";

export type ApplicationFeesForm = {
    incorrectApplicationLevel ?: boolean,
    estimatedAnnualGeneration : string,
    annualConsumption : string,
    currentAnnualConsumption : string,
}

type ApplicationFeesProps = BaseApplicationStepProps & {
    noAccount : boolean
}

//some states do not have all four application levels
const ApplicationLevels : DropdownItem[] = [
    {identifier : '01', description: 'Level 1'},
    {identifier : '02', description: 'Level 2'},
    {identifier : '03', description: 'Level 3'},
    {identifier : '04', description: 'Level 4'}
]
const ApplicationLevelsByState = new Map<string, DropdownItem[]>([
    [State.NJ, ApplicationLevels.slice(0,3)],
    [State.OH, ApplicationLevels.slice(0,3)],
    [State.WV, ApplicationLevels.slice(0,2)],
    [State.NY, ApplicationLevels.slice(0,3)]
]);

export function ApplicationFees(props : ApplicationFeesProps) {
    const auth = useAuth();
    const axios = useAxios();
    const messageService = useMessageService();
    const [paExtraValid, setPaExtraValid] = useState<boolean>();
    const [triggerFlag, setTriggerFlag] = useState<boolean>(false);

    const methods = useForm<ApplicationFeesForm>({
        defaultValues : {
            estimatedAnnualGeneration : props.application?.additionalInformation?.estimatedAnnualGeneration,
            annualConsumption : props.application?.additionalInformation?.annualConsumption,
        },
        mode : "onChange"
    });

  

    const {handleSubmit, getValues, control, formState : {isValid, errors, isDirty}} = methods;

    let watchFees = useWatch({control : control});
    let isSolar =  props.application?.system?.sourceSystem?.energySource?.tags?.includes(TAG_SOLAR_PV);
    const isPa = props.application!.account!.region! === State.PA;
    
    // whenever the form is valid, make sure next is enabled. If it is invalid then make sure next is disabled
    useEffect(() => {
        if (isValid  && (!isPa || paExtraValid)) {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
        } else {
            props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        }
    }, [isValid, isPa, paExtraValid, props.application?.nextDisabled])

    useEffect(() => {
        if (isDirty) {
            const debounceTimer = setTimeout(() => {
                props.applicationFormsDispatch({type: FormActionType.ADDITIONAL_INFORMATION,
                    additionalInformation : {
                        estimatedAnnualGeneration : getValues("estimatedAnnualGeneration"),
                        calculatedAnnualGeneration : props.application?.additionalInformation?.calculatedAnnualGeneration,
                        annualConsumption: getValues("annualConsumption"),
                        applicationLevel : props.application?.additionalInformation?.applicationLevel!
                    }
                });
            }, 500);
            return () => clearTimeout(debounceTimer);
        }
    }, [watchFees, isDirty]);


    const onSubmit : SubmitHandler<ApplicationFeesForm> = async (data : ApplicationFeesForm) => {
        // clear any messages once we submit
        messageService.clearAll();

        // disable next button while processing
        props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        props.applicationFormsDispatch({type : FormActionType.START_PROCESSING});

        // Get the fees from web methods call
        const approvalPromise = axios?.secureApi.get(
            '/api/application/determineApprovalAndFees',
            { params : {
                    id : props.application?.id,
                    isNoAccount : props.noAccount,
                    state: props.application?.account?.region,
                    inverterSize: ApplicationFormUtils.calculateFeeInverterSize(props.application!),
                    contractAccountNumber: props.application?.account?.contractAccount ?? '',
                    estimatedAnnualGeneration: props.application?.additionalInformation?.estimatedAnnualGeneration,
                    level: props.application?.additionalInformation?.applicationLevel
                }
            }
        ).then((response: AxiosResponse) => {
            return axios?.secureApi.put(
                '/api/application/saveApprovalCodeAndFees',
                {...ApplicationFormUtils.buildApplicationFormRequestBody({...props.application!, approvalCodeAndFees : response.data}, auth.user!)}
            )
        })

        //Save additional info
        const additionalInfoPromise = axios?.secureApi.put(
            '/api/application/saveAdditionalInformation',
            {...ApplicationFormUtils.buildApplicationFormRequestBody(props.application!, auth.user!)}
        )

        //Execute both API call chains in parallel
        Promise.all([additionalInfoPromise, approvalPromise]).then(([additionalInfoResponse, approvalResponse]) => {
            props.applicationFormsDispatch({type: FormActionType.APPROVAL_CODE_AND_FEES, approvalCodeAndFees : approvalResponse!.data });
            const saveNextStep = (step: ApplicationStep) => saveStep(step, axios!, props.application?.id!);
            props.applicationFormsDispatch({type : FormActionType.FORWARD, saveStep: saveNextStep});
        }).catch((err: AxiosError) => {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT });
            messageService.error('Error saving information.')
        }).finally(() => {
            props.applicationFormsDispatch({type : FormActionType.END_PROCESSING});
        })
    };

    const onTriggerValidation = () => {
        methods.trigger();
        setTriggerFlag(true);
        setTimeout(() => setTriggerFlag(false), 10);
    }

    return (
        <React.Fragment>
            <PageSubTitle>Additional Information</PageSubTitle>
            <p>Please complete the following additional information required to submit your application.</p>
            <GridColumnContainer columns={2}>
                <Label htmlFor={"state"}>State</Label>
                <div>{props.application?.account?.region}</div>
                <Label htmlFor={"applicationLevel"}  >Application Level</Label>
                <div>{props.application?.additionalInformation?.applicationLevel}</div>
            </GridColumnContainer>
            <div style={{height: "1em"}}></div>
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {
                        !isSolar &&
                        <React.Fragment>
                            <Label htmlFor={"estimatedAnnualGeneration"} >Estimated Annual Generation (kWh)</Label>
                            <Input name={"estimatedAnnualGeneration"} pattern={Pattern.KwPrecisionMeasure} required={true} error={errors.estimatedAnnualGeneration}/>
                        </React.Fragment>
                    }
                    {
                        isSolar &&
                        <React.Fragment>
                            <GridColumnContainer columns={2}>
                                <Label htmlFor={"calculatedAnnualGeneration"} >Calculated Annual Generation (kWh)</Label>
                                <div>{props.application?.additionalInformation?.calculatedAnnualGeneration}</div>
                            </GridColumnContainer>
                            <div style={{height: '1em'}}/>
                        </React.Fragment>
                    }

                    <React.Fragment>
                        <Label htmlFor={"annualConsumption"} >Current Annual Consumption (kWh)</Label>
                        <Input name={"annualConsumption"} pattern={Pattern.KwPrecisionMeasure} required={true} error={errors.annualConsumption}/>
                    </React.Fragment>
                </Form>
                {isPa && <PaAgreementExtraForm setValid={setPaExtraValid} triggerFlag={triggerFlag} {...props}/>}
                
            </FormProvider>
            
            <ApplicationStepButtons {...props} onNext={handleSubmit(onSubmit)} triggerValidation={onTriggerValidation} />
        </React.Fragment>
    )
}