import React from "react";
import Pagination from "./Pagination";
import {StyledTable, TableData, TableHeader} from "../../styledcomponents/TableStyledComponents";
import {
    Column,
    TableInstance,
    UsePaginationInstanceProps,
    UsePaginationState,
    UseSortByInstanceProps
} from "react-table";
import styled from "styled-components";

const SortableHeaderGroup = styled.div`
    display:inline-flex;
`

const SortableHeaderArrow = styled.div`
    width: 1em;
    margin: auto 0px auto 0.2em;
`

export type TableProps<T extends Object> = {
    columns: Column<T>[],
    data : T[],
    hiddenColumns ?: string[],
    isPaginatedSorted: boolean;
    fixedWidth?: number;
}
export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
    UsePaginationInstanceProps<T> &
    UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
    isSortable: boolean;
    fixedWidth?: number;
};


export default function InnerTable<T extends Object>(props: TableInstanceWithHooks<T>) {
    const {
        getTableProps,
        headerGroups,
        getTableBodyProps,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        previousPage,
        nextPage,
        isSortable,
        state : {pageIndex},
        fixedWidth
    } = props;
    
    // Render the UI for your table
    return (
        <React.Fragment>
            <StyledTable {...getTableProps()} fixedWidth={fixedWidth}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                let size = isNaN(Number(column.width)) ? undefined : Number(column.width);
                                return (
                                <TableHeader size={size} {...column.getHeaderProps(isSortable ? column.getSortByToggleProps() : undefined)}>
                                    <SortableHeaderGroup>
                                        <div>{column.render('Header')}</div>
                                        <SortableHeaderArrow>
                                        {
                                            column.isSorted
                                                ? column.isSortedDesc
                                                    ? "▼"
                                                    : "▲"
                                                : ""
                                        }
                                        </SortableHeaderArrow>
                                    </SortableHeaderGroup>
                                </TableHeader>

                            )})}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <TableData key={cell?.column?.id} wordWrapAll={cell?.column?.wordWrapAll}>
                                            {cell.render('Cell')}
                                        </TableData>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </StyledTable>
            {
                pageCount > 1 &&
                <Pagination
                    canPreviousPage = {canPreviousPage}
                    canNextPage     = {canNextPage}
                    pageIndex       = {pageIndex}
                    pageCount       = {pageCount}
                    gotoPage        = {gotoPage}
                    previousPage    = {previousPage}
                    nextPage        = {nextPage}
                />
            }
        </React.Fragment>
    )
}