import React from "react";
import {BaseApplicationStepProps} from "../Application";
import {NoAccountForm} from "../forms/NoAccountForm";
import {AccountSelectForm} from "../forms/AccountSelectForm";

type CustomerInformationProps = BaseApplicationStepProps & {
    noAccount : boolean
}

export function CustomerInformation(props : CustomerInformationProps) {
    return (
        <React.Fragment>
            {
                props.noAccount
                ? <NoAccountForm {...props} />
                : <AccountSelectForm {...props}/>
            }
        </React.Fragment>
    )
}