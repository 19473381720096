import {useMessageService} from "../../../../hooks/useMessageService";
import React, {useEffect, useState} from "react";
import {InterconnectApplicantStep} from "../../helpers/InterconnectApplicantStepIcons";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {Form, Label, StyledOption} from "../../../../styledcomponents/FormStyledComponents";
import GreyBanner from "../../../misc/GreyBanner";
import {Divider, GridColumnContainer} from "../../../../styledcomponents/MiscStyledComponents";
import {Input, Select} from "../../../form/FormElements";
import {Pattern} from "../../../../enums/Pattern";
import {State} from "../../../../enums/State";
import {
    InterconnectApplicantStepProps
} from "./InterconnectApplicantForm";
import {InterconnectApplicantButtons} from "./InterconnectApplicantButtons";
import {InterconnectApplicantFormData} from "./types/InterconnectApplicantTypes";
import {AccountHolderForm} from "./AccountHolderForm";
import {MessageDisplay} from "../../../misc/MessageContainer";

type AccountInformationProps = InterconnectApplicantStepProps & {
}

export default function AccountInformation(props : AccountInformationProps) {
    const messageService = useMessageService();

    // Form state handlers
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [showInvalidComboError, setShowInvalidComboError] = useState<boolean>(false);

    const methods = useForm<InterconnectApplicantFormData>({
        defaultValues : {...props.interconnectApplicantForm, locationHome : props.interconnectApplicantForm?.locationHome?.toString()},
        mode: "onChange"
    });

    const {getValues, reset, control, formState : {errors, isValid, isSubmitting, isDirty, dirtyFields}} = methods;
    let watchAccountInformation = useWatch({control : control});

    function getUpdatedInterconnectApplicant() {
        return {
            ...props.interconnectApplicantForm!,
            locationHome : getValues('locationHome'),
            firstName : getValues('firstName'),
            lastName : getValues('lastName'),
            socialSecurityNumber : getValues('socialSecurityNumber'),
            confirmSocialSecurityNumber : getValues('confirmSocialSecurityNumber'),
            businessName : getValues('businessName'),
            primaryPhone : getValues('primaryPhone'),
            alternatePhone : getValues('alternatePhone'),
            email : getValues(('email')),
            mailingAddress : getValues('mailingAddress')
        }
    }

    // useEffects
    useEffect(() => {
        setSubmitDisabled(isSubmitting || !isValid || showInvalidComboError);
    }, [isSubmitting, isValid, showInvalidComboError])

    // Whenever the user modifies the form fields, we want to wait 500 milliseconds for them to stop typing and then
    // update the interconnect applicant
    useEffect(() => {
        if (isDirty) {
            const debounceTimer = setTimeout(() => {
                let newInterconnectApplicant = getUpdatedInterconnectApplicant()

                props.setInterconnectApplicantForm(newInterconnectApplicant);
            }, 500);

            return () => clearTimeout(debounceTimer);
        }
    }, [watchAccountInformation, isDirty]);

    function onNext() {
        // clear any messages once we submit
        messageService.clearAll();

        props.setInterconnectApplicantForm(getUpdatedInterconnectApplicant());
    }

    useEffect(() => {
        if (
            getValues("mailingAddress.street") && getValues("mailingAddress.ruralRoute")
            && getValues("mailingAddress.houseNumber") && getValues("mailingAddress.poBox")
        ) {
            setShowInvalidComboError(true)
        } else {
            setShowInvalidComboError(false);
        }
    }, [watchAccountInformation])

    return (
        <FormProvider {...methods}>
            <Form>
                <p>
                    Please provide information regarding the customer (account holder)
                    who will be responsible for paying the electric bill at the service address.
                </p>

                <GreyBanner title={'Account Information'} />
                <AccountHolderForm canEnterSocialSecurityNumber={true}/>

                <br />

                <GreyBanner title={'Current Mailing Address'} />

                {
                    showInvalidComboError &&
                    <React.Fragment>
                        <MessageDisplay type={'error'} cannotClose={true} noMargin={true}>
                            <div>House Number, Street, Rural Route, and PO Box combination is invalid.</div>
                        </MessageDisplay>
                        <br />
                    </React.Fragment>

                }
                <GridColumnContainer columns={2}>
                    <div>
                        <Label htmlFor={"mailingAddress.houseNumber"} >House Number</Label>
                        <Input name={'mailingAddress.houseNumber'} required={true} pattern={Pattern.HouseNumber} error={errors.mailingAddress?.houseNumber}/>
                    </div>
                    <div>
                        {/*If rural route is entered then street is optional*/}
                        <Label htmlFor={"mailingAddress.street"} >Street {watchAccountInformation.mailingAddress?.ruralRoute ? '(Optional)' : ''}</Label>
                        <Input name={'mailingAddress.street'} required={!watchAccountInformation.mailingAddress?.ruralRoute} pattern={Pattern.Street} maxLength={40} error={errors.mailingAddress?.street}/>
                    </div>
                </GridColumnContainer>
                <GridColumnContainer columns={2}>
                    <div>
                        {/*If rural route is entered then street is optional*/}
                        <Label htmlFor={"mailingAddress.ruralRoute"} >Rural Route {watchAccountInformation.mailingAddress?.street ? '(Optional)' : ''}</Label>
                        <Input name={'mailingAddress.ruralRoute'} required={!watchAccountInformation.mailingAddress?.street} pattern={Pattern.RuralRoute} maxLength={40} error={errors.mailingAddress?.ruralRoute}/>
                    </div>
                    <div>
                        <Label htmlFor={"mailingAddress.poBox"} >PO Box (Optional)</Label>
                        <Input name={'mailingAddress.poBox'} pattern={Pattern.PoBox} error={errors.mailingAddress?.poBox}/>
                    </div>
                </GridColumnContainer>
                <GridColumnContainer columns={3}>
                    <div>
                        <Label htmlFor={"mailingAddress.apartment"} >Apartment (Optional)</Label>
                        <Input name={'mailingAddress.apartment'} pattern={Pattern.Apartment} error={errors.mailingAddress?.apartment}/>
                    </div>
                    <div>
                        <Label htmlFor={"mailingAddress.building"} >Building (Optional)</Label>
                        <Input name={'mailingAddress.building'} pattern={Pattern.Building} error={errors.mailingAddress?.building}/>
                    </div>
                    <div>
                        <Label htmlFor={"mailingAddress.floor"} >Floor (Optional)</Label>
                        <Input name={'mailingAddress.floor'} pattern={Pattern.Floor} error={errors.mailingAddress?.floor}/>
                    </div>
                </GridColumnContainer>
                <GridColumnContainer columns={2}>
                    <div>
                        <Label htmlFor={"mailingAddress.city"}>City</Label>
                        <Input name={'mailingAddress.city'} required={true} pattern={Pattern.City} error={errors.mailingAddress?.city}/>
                    </div>
                    <GridColumnContainer columns={2}>
                        <div>
                            <Label htmlFor={"mailingAddress.state"}>State</Label>
                            <Select name={"mailingAddress.state"} required={true}>
                                <StyledOption key={''} value={''}>Select One</StyledOption>
                                {
                                    Object.values(State).map(state => <option key={state}>{state}</option>)
                                }
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor={"mailingAddress.zipCode"}>Zip Code</Label>
                            <Input name={'mailingAddress.zipCode'} required={true} pattern={Pattern.ZipCode} error={errors.mailingAddress?.zipCode} maxLength={5} enforceMaxLength/>
                        </div>
                    </GridColumnContainer>
                </GridColumnContainer>
                <Divider />
                <InterconnectApplicantButtons step={InterconnectApplicantStep.AccountInfo}
                                              {...props}
                                              nextDisabled={submitDisabled}
                                              onNext={onNext}
                />
            </Form>
        </FormProvider>
    )

}