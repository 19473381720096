import React, {createContext, ReactElement, useContext, useMemo} from "react";

import {useAuth} from "./auth/AuthProvider";

export const enum Page {
    Root,
    ApplicationList,
    Login,
    ResetPassword,
    ResetForgotPassword,
    ForgotCredentials,
    ForcedResetPassword,
    AcknowledgementPage,
    AuditLog,
    ErrorFallback,
    Registration,
    ReviewRegistration,
    RegistrationSuccess,
    FinalizeRegistration,
    FinalizeRegistrationSuccess,
    Application,
    UpdateProfile,
    ManageAccount,
    CompleteAccountInfo,
    ReviewAndSignAgreement,
    ApplicationSummary
}

export const PathMap : Map<Page, any> = new Map();

function loadPathMap() {
    // paths for anyone
    PathMap.set(Page.Root,                       '/');
    PathMap.set(Page.ApplicationList,             '/applications');
    PathMap.set(Page.Login,                       '/login');
    PathMap.set(Page.ResetPassword,               '/resetPassword');
    PathMap.set(Page.AcknowledgementPage,         '/acknowledge');
    PathMap.set(Page.AuditLog,                    '/activityLog');
    PathMap.set(Page.Registration,                '/register');
    PathMap.set(Page.Application,                 `${PathMap.get(Page.ApplicationList)}/form`);
    PathMap.set(Page.ErrorFallback,               'error');
    PathMap.set(Page.ManageAccount,               '/manageAccount');
    PathMap.set(Page.ForgotCredentials,           '/forgotCredentials');
    PathMap.set(Page.ForcedResetPassword,         '/forcedResetPassword');
    PathMap.set(Page.ResetForgotPassword,         '/resetForgotPassword');
    PathMap.set(Page.ReviewRegistration,          '/reviewRegister');
    PathMap.set(Page.RegistrationSuccess,         '/registerSuccess');
    PathMap.set(Page.FinalizeRegistrationSuccess, '/finalizeRegistrationSuccess');
    PathMap.set(Page.FinalizeRegistration,        '/finalizeRegistration/:vendorId');
    PathMap.set(Page.CompleteAccountInfo,         '/completeAccountInfo/:applicationId');
    PathMap.set(Page.UpdateProfile,               `${PathMap.get(Page.ManageAccount)}/update`);
    PathMap.set(Page.ApplicationSummary,          '/applicationSummary');
    PathMap.set(Page.ReviewAndSignAgreement,      '/reviewSignAgreement');

    // Super admin only paths
    
}

const PathContext = createContext<PathContextTypes>({ get : () => ''});

interface PathProviderProps {
    children : ReactElement
}

export type PathContextTypes = {
    get : (data : Page) => string;
};

export function PathProvider(props : PathProviderProps) {
    const auth = useAuth();

    const get = (path : Page) : string => {
        if (PathMap.size === 0) { loadPathMap(); }

        let pathValue = PathMap.get(path);

        if (!pathValue) { throw new Error(`No paths defined for ${path}`); }

        if (auth.user?.isVendor && pathValue.vendorPath) { return pathValue.vendorPath; }

        return pathValue;
    };

    const value = useMemo<PathContextTypes>(
        () => ({
            get
        }),
        [auth.user]
    );

    return <PathContext.Provider value={value}>{props.children}</PathContext.Provider>;
}

export const usePath = () => {
    return useContext(PathContext);
};