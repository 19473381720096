import {GridColumnContainer, PageSubTitle, SummaryValue} from "../../../styledcomponents/MiscStyledComponents";
import {ApplicationForm, BaseApplicationStepProps} from "../Application";
import React from "react";
import {ApplicationStepButtons} from "../helpers/ApplicationStepButtons";
import {Label} from "../../../styledcomponents/FormStyledComponents";
import {FormActionType} from "../helpers/applicationFormReducer";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";

type ApplicationFeesDisplayProps = {
    application : ApplicationForm | null
}

export function ApplicationFeesDisplay(props : ApplicationFeesDisplayProps) {
    return (
        <React.Fragment>
            <Label htmlFor={"applicationLevel"}>Application Level</Label>
            <SummaryValue>
                Level {props.application?.additionalInformation?.applicationLevel}
            </SummaryValue>
            <Label htmlFor={"fixedFee"}>Fixed Fee</Label>
            <SummaryValue>
                {
                    props.application?.approvalCodeAndFees?.fees?.fixedFee != null
                        ? `$${props.application?.approvalCodeAndFees?.fees?.fixedFee}`
                        : 'Loading...'
                }
            </SummaryValue>
            <Label htmlFor={"pricePerKw"}>Price Per kW</Label>
            <SummaryValue>
                {
                    props.application?.approvalCodeAndFees?.fees?.pricePerKw != null
                        ? `$${props.application?.approvalCodeAndFees?.fees?.pricePerKw}`
                        : 'Loading...'
                }
            </SummaryValue>
            <Label htmlFor={"estimatedApplicationFee"}>Estimated Application Fee</Label>
            <SummaryValue>
                {
                    props.application?.approvalCodeAndFees?.fees?.estimatedApplicationFee != null
                        ? `$${props.application?.approvalCodeAndFees?.fees?.estimatedApplicationFee}`
                        : 'Loading...'
                }
            </SummaryValue>
        </React.Fragment>
    )
}

type ApplicationFeesReviewProps = BaseApplicationStepProps & {
}

export function ApplicationFeesReview(props : ApplicationFeesReviewProps) {
    /**Should we show the AC energy storage contribution to the fees? */
    const shouldShowEnergyStorage = ApplicationFormUtils.includeAcEnergyStorageForFees(props.application!)
        && !props.application?.system?.sourceSystem.energyStorageOutputIsDc
        && Number(props.application?.system?.sourceSystem.energyStorageOutput) > 0;

    
    React.useEffect(() => {
        //enable next button once fees have loaded
        if (props.application?.nextDisabled && props.application?.approvalCodeAndFees?.fees?.estimatedApplicationFee != undefined) {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
        }
    }, [props.application?.approvalCodeAndFees?.fees?.estimatedApplicationFee, props.application?.nextDisabled])

    return (
        <React.Fragment>
            <PageSubTitle>Application Fees</PageSubTitle>
            <p>The required application fees are listed below. Your application will not be processed until payment for the application fee has been received.
            You can continue to review and submit your application, then you will have the option to pay online or by check.</p>
            <GridColumnContainer columns={2}>
                <Label>Total AC Rated Capacity (kW AC)</Label>
                <SummaryValue>{props.application?.system?.sourceSystem.acRatedCapacity}</SummaryValue>
                { shouldShowEnergyStorage && 
                <>
                    <Label>Total Energy Storage Output (kW AC)</Label>
                    <SummaryValue>{props.application?.system?.sourceSystem.energyStorageOutput}</SummaryValue>
                </>
                }
                <Label>State</Label>
                <SummaryValue>{props.application?.account?.region}</SummaryValue>
                <ApplicationFeesDisplay application={props.application} />
            </GridColumnContainer>
            <ApplicationStepButtons {...props}  />
        </React.Fragment>
    )
}