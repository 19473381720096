import {Divider, InlineLink, PageSubTitle} from "../../styledcomponents/MiscStyledComponents";
import {Page, usePath} from "../../PathProvider";
import {PrimaryButtonLink} from "../misc/ButtonLink";
import React from "react";
import styled from "styled-components";
import checkmark from "../../assets/images/checkmark.svg";

const CheckmarkImg = styled.img`
  height: 1.25rem;
  vertical-align: middle;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  `

export default function FinalizeRegistrationSuccess() {

    const path = usePath();

    return (
        <div>
            <PageSubTitle><CheckmarkImg src={checkmark} alt={"success icon"} />Online Account Registration Completed</PageSubTitle>
            <div>You have successfully completed your online registration.  You will receive a confirmation email with additional information.  You can now submit an online application.</div><br />
            <div>To update your profile information, go to <InlineLink to={path.get(Page.ManageAccount)}>Manage Account</InlineLink>.</div><br />
            <Divider />
            <div>
                <PrimaryButtonLink to={path.get(Page.Application)}>Create New Application</PrimaryButtonLink>
            </div>
        </div>
    )
}