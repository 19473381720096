import {StyledLink} from "../../styledcomponents/MiscStyledComponents";
import React, {CSSProperties, ReactElement} from "react";

type ExternalLinkProps = {
    href : string
    children : string | ReactElement
    style ?: CSSProperties;
    download ?: boolean;
}

export default function ExternalLink(props : Readonly<ExternalLinkProps>) {
    return (
        <StyledLink href={props.href} target={"_blank"} style={props.style} download={props.download}>{props.children}</StyledLink>
    )
}