import {useMessageService} from "../../../../hooks/useMessageService";
import React, {useEffect, useState} from "react";
import {InterconnectApplicantStep} from "../../helpers/InterconnectApplicantStepIcons";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {Form} from "../../../../styledcomponents/FormStyledComponents";
import GreyBanner from "../../../misc/GreyBanner";
import {Divider} from "../../../../styledcomponents/MiscStyledComponents";
import {InterconnectApplicantButtons} from "./InterconnectApplicantButtons";
import {ServiceAddressFormData} from "./types/InterconnectApplicantTypes";
import {InterconnectApplicantStepProps} from "./InterconnectApplicantForm";
import {ServiceAddressForm} from "./ServiceAddressForm";

type ServiceInformationProps = InterconnectApplicantStepProps & {
}

export default function ServiceInformation(props : ServiceInformationProps) {
    const messageService = useMessageService();

    // Form state handlers
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

    const methods = useForm<ServiceAddressFormData>({
        defaultValues : {...props.interconnectApplicantForm?.serviceAddress},
        mode: "onChange"
    });

    const {getValues, reset, control, formState : {isValid, isSubmitting, isDirty, dirtyFields}} = methods;
    let watchServiceInformation = useWatch({control : control});

    function updateServiceAddress() {
        props.setInterconnectApplicantForm({
            ...props.interconnectApplicantForm!,
            serviceAddress : getValues()
        })
    }

    // useEffects
    useEffect(() => {
        setSubmitDisabled(isSubmitting || !isValid);
    }, [isSubmitting, isValid])

    // Whenever the user modifies the form fields, we want to wait 500 milliseconds for them to stop typing and then
    // update the service address
    useEffect(() => {
        if (isDirty) {
            const debounceTimer = setTimeout(() => {
                updateServiceAddress()
            }, 500);

            return () => clearTimeout(debounceTimer);
        }
    }, [watchServiceInformation, isDirty]);

    function onNext() {
        // clear any messages once we submit
        messageService.clearAll();

        updateServiceAddress();
    }

    return (
        <FormProvider {...methods}>
            <Form>
                <p>
                    Provide the information regarding the location for which electric service is or will be established
                </p>
                <GreyBanner title={'Service Address'} />
                <ServiceAddressForm />
                <Divider />
                <InterconnectApplicantButtons step={InterconnectApplicantStep.ServiceInfo}
                                              {...props}
                                              nextDisabled={submitDisabled}
                                              onNext={onNext}
                />
            </Form>
        </FormProvider>
    )

}