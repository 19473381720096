import {Divider, GridColumnContainer, SummaryValue, majorGap, minorGap} from "../../../styledcomponents/MiscStyledComponents";
import {Label} from "../../../styledcomponents/FormStyledComponents";
import GreyBanner from "../../misc/GreyBanner";
import React from "react";
import {ApplicationForm} from "../Application";
import {Page, usePath} from "../../../PathProvider";
import TableLink from "../../table/TableLink";
import {BatteryForm, DcSourceForm, InverterForm} from "../forms/EquipmentForm";
import styled from "styled-components";
import { TAG_SOLAR_PV } from "../../../utils/constants";
import { ExistingSystem, getShouldShowDcSource, labelsAndTooltips } from "../forms/SystemForm";

const SubEquipmentContainer = styled.div`
    padding: 0.1em 0.3em;
    border-top: 4px solid #ddd;
    margin: 0.4em;
    label:nth-of-type(n+2) {
        font-weight:400;
        line-height:1.5em;
        font-size: 0.95em;
    }
    label:nth-of-type(-n+1) {
        font-size: large;
    }
`

/** Used for PDF rendering */
const PageBreak = styled.div`
    break-before: left;
`

type EditButtonProps = {
    applicationId : string
    setIncomplete? : ('equipment' | 'system')
}

function EditButton(props : EditButtonProps) {
    const path = usePath();

    return (
        <TableLink to={path.get(Page.Application)} state={{applicationId : props.applicationId, resubmit : true, setIncomplete : props.setIncomplete}}>Edit</TableLink>
    )
}

type ApplicationSummaryContentProps = {
    application : ApplicationForm
    canEdit ?: boolean
}

export function ApplicationSummaryContent(props : ApplicationSummaryContentProps) {
    let systemSummary = {
        energySource: props.application?.system?.sourceSystem.energySource,
        totalSourceRating: props.application?.system?.sourceSystem.acRatedCapacity ?? '',
        // kwRating: props.application?.system?.generation.kwRating ?? '',
        sourceVoltage: props.application?.system?.sourceSystem.sourceVoltage ?? '',
        generationEquipment: props.application?.system?.sourceSystem.generationEquipment,
        generationIntent: props.application?.system?.sourceSystem.generationIntent,
        // existingGeneration: props.application?.system?.generation.existingGeneration,
        // pjmParticipation: props.application?.system?.generation.pjmParticipation,
        subscriberNumber: props.application?.system?.sourceSystem.subscriberNumber ?? '',
        dcRatedCapacity: props.application?.system?.sourceSystem.dcRatedCapacity ?? '',
        communitySolar: props.application?.system?.sourceSystem.communitySolar?.toString(),
        energyStorageOutput: props.application?.system?.sourceSystem?.energyStorageOutput,
        energyStorageCapacity: props.application?.system?.sourceSystem?.energyStorageCapacity,
        dateSubmitted: props.application?.dateSubmitted ?? '',
        referenceNumber: props.application?.interconnectNumber ?? '',
        applicationLevel: props.application?.additionalInformation?.applicationLevel ?? '',
        fixedFee: props.application?.approvalCodeAndFees?.fees?.fixedFee ?? '',
        pricePerKw: props.application?.approvalCodeAndFees?.fees?.pricePerKw ?? '',
        estimatedApplicationFee: props.application?.approvalCodeAndFees?.fees?.estimatedApplicationFee ?? '',
        
    };

    const dcSourceProps: {id: keyof DcSourceForm, title:string}[] = [
            {id: 'moduleManufacturer', title:'Module Manufacturer'},
            {id: 'modelNumber', title:'Model Number'},
            {id: 'moduleCount', title:'Quantity of Modules'},
            {id: 'voltageRatings', title:'Voltage Ratings (Volts)'},
            {id: 'currentRating', title:'Current Rating (Amps)'},
            {id: 'ratedVoltage', title:'Rated Voltage (Amps)'},
            {id: 'ratedCurrent', title:'Rated Current (Amps)'},
            {id: 'openCircuitVoltage', title:'Open Circuit Voltage (Volts)'},
            {id: 'shortCircuitCurrent', title:'Short Circuit Current (Amps)'},
        ];
    const dcSourceFragment = <>{dcSourceProps.map((p) => {
            return props.application.equipment?.dcSource?.[p.id] && <React.Fragment key={p.id}>
                <Label>{p.title}</Label>
                <div>{ props.application.equipment?.dcSource?.[p.id]}</div>
            </React.Fragment>
            })}
        </>

    const isExistingStorageDc = props.application.system?.existingSystem?.existingEnergyStorageOutputIsDc;
    const isStorageDc = props.application.system?.sourceSystem?.energyStorageOutputIsDc;
    const existingSystemProps: {id: keyof ExistingSystem, title:string}[] = [
        {id: 'existingAcRatedCapacity', title:labelsAndTooltips.existingAcRatedCapacity.label},
        {id: 'existingDcRatedCapacity', title:labelsAndTooltips.existingDcRatedCapacity.label},
        {id: 'existingEnergyStorageOutput', title:labelsAndTooltips.existingEnergyStorageOutput.label.replace('kW', isExistingStorageDc ? 'kW DC': 'kW AC')},
        {id: 'existingEnergyStorageCapacity', title:labelsAndTooltips.existingEnergyStorageCapacity.label},
        {id: 'pjmParticipation', title:labelsAndTooltips.pjmParticipation.label},
    ];
    const existingSystemFragment = <>{existingSystemProps.map((p) => {
        const value = props.application.system?.existingSystem?.[p.id];
        let valueString : string = typeof value === 'object' ? value?.description : String(props.application.system?.existingSystem?.[p.id])
        return value && <React.Fragment key={p.id}>
            <Label>{p.title}</Label>
            <SummaryValue>{valueString}</SummaryValue>
        </React.Fragment>
        })}
    </>

    const equipment = props?.application?.system?.sourceSystem.generationEquipment;
    const generationEquipmentIdentifier = equipment?.identifier;
    const generationEquipmentDescription = equipment?.description;

    let equipmentsDetails: InverterForm[];
    switch (generationEquipmentIdentifier) {
        case "02":
            equipmentsDetails = props.application?.equipment?.synchronousGenerators ?? []
            break
        case "03":
            equipmentsDetails = props.application?.equipment?.inductionGenerators ?? []
            break
        default:
            equipmentsDetails = props.application?.equipment?.inverters ?? []
    }
    return (
        <React.Fragment>
            <GridColumnContainer columns={2} gap={majorGap} >
                {systemSummary.dateSubmitted && <>
                    <Label>Date Submitted</Label>
                    <SummaryValue>{systemSummary.dateSubmitted}</SummaryValue>
                </>}
                {systemSummary.referenceNumber != 'Draft' && <>
                    <Label>Reference Number</Label>
                    <SummaryValue>{systemSummary.referenceNumber}</SummaryValue>
                </>}
                
                <Label>Application Level</Label>
                <SummaryValue>{systemSummary.applicationLevel}</SummaryValue>
                <Label>Fixed Fee</Label>
                <SummaryValue>${systemSummary.fixedFee}</SummaryValue>
                <Label>Price Per kW</Label>
                <SummaryValue>${systemSummary.pricePerKw}</SummaryValue>
                <Label>Estimated Application Fee</Label>
                <SummaryValue>${systemSummary.estimatedApplicationFee}</SummaryValue>
            </GridColumnContainer>
            { Boolean(props.application.system?.existingSystem?.existingAcRatedCapacity?.length) && <><GreyBanner title={"Existing System"} marginTop={true}/>
                <GridColumnContainer columns={2} gap={majorGap}>
                    {existingSystemFragment}
                </GridColumnContainer>
            </>}
            <GreyBanner title={"System"} marginTop={true} button={props.canEdit ? <EditButton applicationId={props.application.id!} setIncomplete="system"/> : <></>} />
            <GridColumnContainer columns={2} gap={majorGap}>
                
                <Label htmlFor={"energySource"}  >Energy Source</Label>
                <SummaryValue>
                    {systemSummary?.energySource?.description}
                </SummaryValue>
                {
                    getShouldShowDcSource(props.application.system) && <>
                        <Label htmlFor={"dcRatedCapacity"} >Total DC Source Rated Capacity (kW DC)</Label>
                        <SummaryValue>
                            {systemSummary?.dcRatedCapacity}
                        </SummaryValue>
                     </>
                }
                {
                    systemSummary?.energySource?.tags?.includes(TAG_SOLAR_PV) &&
                    <React.Fragment>
                        <Label htmlFor={"communitySolar"} >Community Solar</Label>
                        <SummaryValue>
                            {systemSummary?.communitySolar === 'true' ? 'Yes' : 'No'}
                        </SummaryValue>
                    </React.Fragment>
                }
                <Label htmlFor={"totalSourceRating"}  >Total Rated Capacity (kW AC)</Label>
                <SummaryValue>
                    {systemSummary?.totalSourceRating}
                </SummaryValue>
                <Label htmlFor={"sourceVoltage"}>Generator/Inverter Output Voltage (Volts)</Label>
                <SummaryValue>
                    {systemSummary?.sourceVoltage}
                </SummaryValue>
                <Label htmlFor={"generationEquipment"}>Generation Equipment</Label>
                <SummaryValue>
                    {systemSummary?.generationEquipment?.description}
                </SummaryValue>
                <Label htmlFor={"generationIntent"}>Generation Intent</Label>
                <SummaryValue>
                    {systemSummary?.generationIntent?.description}
                </SummaryValue>
                {Boolean(systemSummary?.energyStorageOutput?.length) && <>
                    <Label htmlFor={"energyStorageOutput"}>{labelsAndTooltips.energyStorageOutput.label.replace('kW', isStorageDc ? 'kW DC': 'kW AC')}</Label>
                    <SummaryValue>
                        {systemSummary?.energyStorageOutput}
                    </SummaryValue>
                    <Label htmlFor={"energyStorageCapacity"}>{labelsAndTooltips.energyStorageCapacity.label}</Label>
                    <SummaryValue>
                        {systemSummary?.energyStorageCapacity}
                    </SummaryValue>
                </>}
            </GridColumnContainer>
            <PageBreak/>
            <GreyBanner title={"Equipment"} marginTop={true} button={props.canEdit ? <EditButton applicationId={props.application.id!} setIncomplete="equipment"/> : <></>}/>
            {props.application.equipment?.dcSource?.modelNumber && <SubEquipmentContainer>
                <GridColumnContainer columns={2} gap={minorGap}>
                    <Label htmlFor={"dcSource"}>DC Source</Label><div/>
                    <Label>{labelsAndTooltips.dcRatedCapacity.label}</Label>
                    <div>{ props.application.system?.sourceSystem?.dcRatedCapacity}</div>
                    {dcSourceFragment}
                </GridColumnContainer>
            </SubEquipmentContainer>}
            {
                
                equipmentsDetails.map((equipmentDetails : InverterForm, index : number) =>
                    <React.Fragment key={index}>
                        <SubEquipmentContainer>
                        <GridColumnContainer columns={2}  gap={minorGap}>
                            <Label htmlFor={"inverter"}>{generationEquipmentDescription} #{index + 1}</Label>
                            <div></div>
                            {
                                generationEquipmentDescription == "Inverter" &&
                                <React.Fragment>
                                    <Label htmlFor={"inverterType"}>Inverter Type</Label>
                                    <div>{equipmentDetails.inverterType.description}</div>
                                    <Label htmlFor={"inverterQuantity"}>Inverter Quantity</Label>
                                    <div>{equipmentDetails.inverterQuantity}</div>
                                </React.Fragment>
                            }

                            <Label htmlFor={"modelNumber"}>Model Number</Label>
                            <div>{equipmentDetails.modelNumber}</div>
                            <Label htmlFor={"powerFactor"}>Power Factor</Label>
                            <div>{equipmentDetails.powerFactor}</div>
                            <Label htmlFor={"ratedVoltage"}>Rated Voltage (Volts)</Label>
                            <div>{equipmentDetails.ratedVoltage}</div>
                            <Label htmlFor={"ieeeCertification"}>IEEE 1547 Version</Label>
                            <div>{equipmentDetails.ieeeCertification?.description}</div>
                            <Label htmlFor={"faultContribution"}>Short Circuit Fault Contribution (Amps)</Label>
                            <div>{equipmentDetails.faultContribution}</div>

                            {
                                generationEquipmentDescription == "Inverter" &&
                                <React.Fragment>
                                    <Label htmlFor={"inverterRating"}>Total Inverter Rating (kW AC)</Label>
                                    <div>{equipmentDetails.inverterRating}</div>
                                    <Label htmlFor={"phases"}>Phases</Label>
                                    <div>{equipmentDetails.phases?.description}</div>
                                    <Label htmlFor={"ulCertification"}>Inverter UL1741-SB Certification</Label>
                                    <div>{equipmentDetails.ulCertification === true.toString() ? 'Yes' : 'No'}</div>
                                </React.Fragment>
                            }
                            {
                                generationEquipmentDescription == "Induction Generator" &&
                                <React.Fragment>
                                    <Label htmlFor={"efficiencyPercentage"}>Efficiency Percentage</Label>
                                    <div></div>
                                    <Label htmlFor={"ansiMotorGroup"}>ANSI Motor Group</Label>
                                    <div>{equipmentDetails.ansiMotorGroup}</div>
                                </React.Fragment>
                            }
                            {
                                generationEquipmentDescription == "Synchronous Generator" &&
                                <React.Fragment>
                                    <Label htmlFor={"synchronousType"}>Type</Label>
                                    <div>{equipmentDetails.synchronousType.description}</div>
                                    <Label htmlFor={"numberOfPoles"}>Number of Poles</Label>
                                    <div>{equipmentDetails.numberOfPoles}</div>
                                </React.Fragment>
                            }


                        </GridColumnContainer>
                        </SubEquipmentContainer>
                    </React.Fragment>
            )}
            {
                props.application.equipment?.batteries.map((battery : BatteryForm, index : number) =>
                    <React.Fragment key={index}>
                        <SubEquipmentContainer>
                        <GridColumnContainer columns={2}  gap={minorGap}>
                            {
                                battery.batteryManufacturer &&
                                <React.Fragment>
                                    <Label htmlFor={"battery"}>Battery #{index + 1}</Label>
                                    <div></div>
                                    <Label htmlFor={"quantityOfBattery"}>Quantity of Batteries</Label>
                                    <div>{battery.quantityOfBattery}</div>
                                    <Label htmlFor={"batteryManufacturer"}>Battery Manufacturer</Label>
                                    <div>{battery.batteryManufacturer}</div>
                                    <Label htmlFor={"batteryModel"}>Battery Model</Label>
                                    <div>{battery.batteryModel}</div>
                                    <Label htmlFor={"maximumOutput"}>Maximum Output  {Number(battery.quantityOfBattery) > 1 ? 'For Each Battery ' : ''}(kW)</Label>
                                    <div>{battery.maximumOutput}</div>
                                    <Label htmlFor={"batteryOutputPowerSupply"}>Output Power Supply</Label>
                                    <div>{battery.batteryOutputPowerSupply.toUpperCase()}</div>
                                    <Label htmlFor={"intentOfStorage"}>Intent of Storage</Label>
                                    <div>{battery.intentOfStorage}</div>
                                    <Label htmlFor={"quantityOfBattery"}>Storage Capacity for {Number(battery.quantityOfBattery) > 1 ? 'Each ' : ''}Battery (kWh)</Label>
                                    <div>{battery.storageCapacityBattery}</div>
                                </React.Fragment>
                            }
                        </GridColumnContainer>
                        </SubEquipmentContainer>
                    </React.Fragment>
                )}
        </React.Fragment>

    )
}