import {PageTitle} from "../../styledcomponents/MiscStyledComponents";
import checkmark from "../../../src/assets/images/checkmark.svg"
import React from "react";
import styled from "styled-components";

const CheckmarkImg = styled.img`
  height: 1.25rem;
  vertical-align: middle;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
`

export default function RegistrationSuccess() {
    return (
        <div>
            <PageTitle><CheckmarkImg src={checkmark} alt={"success icon"} />Contractor/Installer Registration Completed</PageTitle>
            <div>You have successfully completed your registration. Once FirstEnergy has reviewed your company information, you will receive an email with a link to complete your online account.</div><br />
        </div>
    )
}