import styled, { createGlobalStyle, css } from 'styled-components';
import DatePicker from "react-date-picker";
import DatePicker2 from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import Input from "react-phone-number-input";

const inputAndSelectStyles = css`
  box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 5px;
  min-width: 200px;
`
const StyledInput = styled.input`
  ${inputAndSelectStyles}
`

const StyledFileInputLabel = styled.label<{dragging: boolean}>`
  ${inputAndSelectStyles}
  input {
    display:none
  }

  max-width: 20em;
  cursor: pointer;
  ${props => props.dragging && `
    border: 2px solid teal;
  `}
`

const StyledDatePicker = styled(DatePicker)`
  ${inputAndSelectStyles}
  .react-date-picker__wrapper {
    border: none;
  }

  width: 100px;
`

const StyledDatePicker2 = styled(DatePicker2)`
  ${inputAndSelectStyles};
  width: 100px;
  
`

//darken arrow color in date picker
//using global style because we aren't able to directly style the popper component
const DatePickerGlobalStyles = createGlobalStyle`
    .react-datepicker__day--keyboard-selected {
      background-color: transparent;
    }
    .react-datepicker__navigation-icon::before {
        border-color: #777777;
    }
    .react-datepicker__navigation:hover *::before {
        border-color: #111111;
    }
    .react-datepicker__day {
      font-weight: bold;
      color: #0A0A0A;
    }
    .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
      background-color: #D1E6F5;
    }
    
    .react-datepicker__day--today {
      background-color:#E2E2E2!important;
      color: #8E8E8E;
      font-weight:normal;
      border-radius: 3px;
    }
    .react-datepicker__day--disabled {
      color: #8E8E8E;
      font-weight: normal;
    }
    .react-datepicker__day--outside-month {
      color: #6E6E6E;
    }
    .react-datepicker__day--selected {
      background-color:#1A6EAD!important;
      color:#FFFFFF;
    }
    .react-datepicker__day--selected:hover {
      background-color:#1A6EAD;
      color:#FFFFFF;
    }
`

const StyledPhoneInput = styled(Input)`
    input {
      ${inputAndSelectStyles} 
    }
    select {
      display: none;
    }
    img {
      display: none;
    }
`

const StyledSelect = styled.select`
  ${inputAndSelectStyles};
  cursor: pointer;
`

const StyledOption = styled.option.attrs<{tags?: string}>(props => {
  return {
    "data-tags": props.tags
  };
}
)<{tags?: string}>`
  cursor: pointer;
  padding: 0 1rem;
`

const StyledTextArea = styled.textarea<{ resizable?: boolean}>`
  ${inputAndSelectStyles};
  height: 15rem;
  ${props => props.resizable ? '' : 'resize: none;'}
`

const StyledCheckboxOrRadioInput = styled.input<{ compact?: boolean}>`
  ${props => props.compact ? 
    ` 
    margin-right: 0.2rem;
    height: 1.2rem;
    width: 1.2rem;
    margin-top: 0.3rem;
    ` : `
    margin-right: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
    `
  }
  cursor: pointer;
`

const Label = styled.label<{ checkbox?: boolean, largeFont?: boolean}>`
  display: ${props => props.checkbox ? 'flex' : 'block'};
  ${props => props.checkbox
    ? css`
            align-items: center;
            margin-bottom: 1rem !important;
            align-items: flex-start !important;
          `
    : ''
  };
  margin: 0;
  font-size: ${props => props.largeFont ? '1.125rem' : 'inherit'};
  font-weight: ${props => props.checkbox ? 400 : 'bold'};
  line-height: 1.8;
  color: #0a0a0a;
`

const primaryButtonStyles = css<{ float?: 'right' | 'left' | 'center' }>`
  background-color: #0060a9;
  border: 1px solid #0060a9;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  max-height: 50px;
  text-transform: uppercase;
  text-decoration: none;
  float: ${props => props.float ?? 'right'};
  align-self: ${props => props.float === 'left' ? 'start' : (props.float === 'center' ? 'center' : 'end')};
  text-align: center;

  &:hover:enabled {
    color: #fff !important;
    background-color: #004376 !important;
  }

  &:disabled {
    cursor: default;
    background-color: #D2D2D2 !important;
    color: #464646 !important;
    border: 1px solid #6E6E6E !important;
  }
`

const PrimaryButton = styled.button<{ float?: 'right' | 'left' | 'center' }>`
  ${primaryButtonStyles};
  min-width: 200px;
  padding: 0.8rem;
`

const SmallPrimaryButton = styled.button<{ float?: 'right' | 'left' | 'center' }>`
  ${primaryButtonStyles};
  max-width: 250px;
  padding: 0.6rem 0.8rem;
`

const secondaryButtonStyles = css<{ float?: 'right' | 'left' }>`
  background-color: white;
  border: 1px solid #0060a9;
  color: #0060a9;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  max-height: 50px;
  text-transform: uppercase;
  text-decoration: none;
  float: ${props => props.float ?? 'left'};
  align-self: ${props => props.float === 'left' ? 'start' : 'end'};
  text-align: center;

  &:hover:enabled {
    color: #0060a9;
    background-color: #d2d2d2 !important;
  }

  &:disabled {
    cursor: default;
    background-color: #D2D2D2 !important;
    color: #464646 !important;
    border: 1px solid #6E6E6E !important;
  }
`
const SecondaryButton = styled.button<{ float?: 'right' | 'left' }>`
  ${secondaryButtonStyles};
  min-width: 180px;
  padding: 0.8rem;
`

const StyledLinkSecondaryButton = styled(SecondaryButton) <{ float?: 'right' | 'left', padding?: boolean }>`
    background: transparent;
    border: none;
    padding: ${props => props.padding ? '2rem 0' : '0'};
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    &:hover:enabled {
      color: #0060a9;
      background-color: transparent !important;
    text-decoration: underline;
    }
`

const StyledLinkButton = styled.button`
  border: none;
  &:hover:enabled {
    color: #0060a9;
    background-color: transparent!important;
    text-decoration: underline;
  }
  background: none!important;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
`

const SmallSecondaryButton = styled.button<{ float?: 'right' | 'left', maxWidth?: number, marginRight?: boolean }>`
  ${secondaryButtonStyles};
  max-width: ${props => (props.maxWidth || 6.25) + 'rem'};
  ${props => (props.marginRight ? 'margin-right: 1rem' : '')};
  padding: 0.6rem 0.8rem;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const FormFieldError = styled.p`
    margin : -1rem 0 1rem 0;
    font-size : .9rem;
    color : #A52B1D;
`

const FormFooterAction = styled.button<{ float?: 'right' | 'left', marginTop?: boolean, marginBottom?: boolean}>`
    color: #0060a9;
    transition: 0.25s ease-in-out;
    cursor: pointer;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: ${props => props.marginBottom ? '1rem' : '2rem'};
    margin-top: ${props => props.marginTop ? '0.5rem' : 0};
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    float: ${props => props.float ?? 'right'};
    align-self: ${props => props.float === 'left' ? 'start' : 'end'};
    &:hover {
      color: #000;
    }
    background: none;
	  border: inherit;
`
const InsideCollapsibleHeader = styled.h3`
    margin-top: 0em;
    font-size: 1.125rem;
`

const InsideCollapsibleSubHeader = styled.h4`
    font-size: 1rem;
`

const WrapperSpan= styled.span`
    width: fit-content;
`

export {
    StyledInput,
    StyledPhoneInput,
    StyledFileInputLabel,
    StyledDatePicker,
    StyledDatePicker2,
    Label,
    PrimaryButton,
    StyledLinkButton,
    StyledCheckboxOrRadioInput,
    StyledSelect,
    StyledOption,
    StyledTextArea,
    Form,
    StyledLinkSecondaryButton,
    SmallPrimaryButton,
    SecondaryButton,
    SmallSecondaryButton,
    FormFieldError,
    FormFooterAction,
    DatePickerGlobalStyles,
    InsideCollapsibleHeader,
    InsideCollapsibleSubHeader,
    WrapperSpan
}