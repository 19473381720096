import React, { useEffect } from "react";
import {BaseApplicationStepProps} from "../Application";
import {ApplicationStepButtons} from "../helpers/ApplicationStepButtons";
import GreyBanner from "../../misc/GreyBanner";
import {AccountSelect} from "../../user/AccountSelect";
import {FormActionType} from "../helpers/applicationFormReducer";
import {useAuth} from "../../../auth/AuthProvider";
import {useAxios} from "../../../AxiosProvider";
import {AxiosError, AxiosResponse} from "axios";
import ErrorUtils from "../../../utils/errorUtils";
import {useMessageService} from "../../../hooks/useMessageService";
import {AccountInfo} from "../../../models/AccountInfo";
import ExternalLink from "../../misc/ExternalLink";
import GetExternalFeUrl, { URLSUFFIX_CONTACT_US } from "../../../utils/externalUrl";

type AccountSelectFormProps = BaseApplicationStepProps & {

}

export function AccountSelectForm(props : AccountSelectFormProps) {
    const auth = useAuth();
    const axios = useAxios();
    const messageService = useMessageService();
    const [selectedAccount, setSelectedAccount] = React.useState<AccountInfo>(auth.user?.currentAccount!)
    const hasAccounts = auth.user?.accounts?.length;

    useEffect(() => { //disable next button if there are no accounts to select
        const nextDisabled = props.application?.nextDisabled;
        const hasAccounts = Boolean(auth.user?.accounts?.length);
        if (nextDisabled && hasAccounts) {
            props.applicationFormsDispatch({type : FormActionType.ENABLE_NEXT});
        } else if (!nextDisabled && !hasAccounts) {
            props.applicationFormsDispatch({type : FormActionType.DISABLE_NEXT});
        }
    }, [auth.user?.accounts?.length, props.application?.nextDisabled])

    function onNext() {
        props.applicationFormsDispatch({type : FormActionType.START_PROCESSING});

        // once the account is chosen, we need to do a lookup on it to get all the data we need
        axios?.secureApi.get(
            `/api/account/lookupAccount/${selectedAccount.accountNumber}`
        ).then((response: AxiosResponse) => {
            if (response.data.findAccountItems?.length > 0) {
                // if success, update the account for the form then go forward
                props.applicationFormsDispatch({type: FormActionType.UPDATE_ACCOUNT, account : response.data.findAccountItems[0] });
                props.applicationFormsDispatch({type : FormActionType.FORWARD});
            } else {
                // FIXME: What do we want to do here? Probably leave application
                messageService.error("Account not active.");
            }
        }).catch((err: AxiosError) => {
            console.error(err);
            messageService.error(ErrorUtils.UNKNOWN_ERROR);
        }).finally(() => {
            props.applicationFormsDispatch({type : FormActionType.END_PROCESSING});
        })
    }

    return (
        <React.Fragment>
            <GreyBanner title={'Customer Information'} />
            {hasAccounts ?
                <>
                    <p>Please select the account for which you want to request interconnection and select Next to continue with your application.</p>
                    <AccountSelect {...props} setSelectedAccount={setSelectedAccount}/>
                </>
            : <p>You do not have any active service addresses registered to this web account. If this is in error, please <ExternalLink href={GetExternalFeUrl(URLSUFFIX_CONTACT_US)}>Contact Us</ExternalLink>.</p>
            }
            
            <ApplicationStepButtons {...props} onNext={onNext}/>
        </React.Fragment>
    )
}