import { CSSProperties } from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

export type DropdownItem = {
  identifier : string,
  description : string,
  tags ?: string[]
}

export const defaultDropdownItem: DropdownItem = {
  identifier: '',
  description: 'Select One',
}

const InfoText = styled.div`
    margin:  1rem 1rem 2rem 0rem;
`

const FlexRowContainer = styled.div<{ gap?: number }>`
    display: flex;
    flex-direction: row;
    gap : ${props => props.gap ?? '1'}rem;
`

const FlexColumnContainer = styled.div<{ gap?: number }>`
    display: flex;
    flex-direction: column;
    gap : ${props => props.gap ?? '1'}rem
`

const FlexWrapContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const majorGap = '0.25rem 1rem';
const minorGap = '0.0rem 1rem';
const GridColumnContainer = styled.div<{ columns: number, gap ?: string }>`
    display: grid;
    break-inside: avoid;
    grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
    gap: ${props => props.gap ?? '1rem'}
`

const GridColumnContainerSignature = styled.div`
    display: grid;
    break-inside: avoid;
    grid-template-columns: 1fr 1fr 0.4fr;
    gap: 0.0rem 1rem;
`

const PageSubTitle = styled.div<{noMargin ?: boolean}>` 
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: ${props => props.noMargin ? '0' : '2rem'};
`
const PageTitle = styled.div<{noMargin ?: boolean}>` 
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: ${props => props.noMargin ? '0' : '2rem'};
`

const Title = styled.div<{noMarginTop?: boolean, noMarginBottom?: boolean}>` 
  font-weight: bold;
  font-size: 20px;
  margin-bottom: ${props => props.noMarginBottom ? '0' : '1rem'};
  margin-top: ${props => props.noMarginTop ? '0' : '1rem'};
`
const Divider = styled.div<{noMarginTop?: boolean, noMarginBottom?: boolean}>`
  border-bottom: 1px solid #D2D2D2;
  width: 100%;
  margin-bottom: ${props => props.noMarginBottom ? '0' : '1rem'};
  margin-top: ${props => props.noMarginTop ? '0' : '1rem'};
`

const NoWrap = styled.div`
  white-space: nowrap;
`

const WrapDate = styled.div`
  word-break: initial;
`

const inlineActionItemStyles = css`
  text-decoration: none;
  color: #0060A9;
  &:visited {
    color: #0060A9;
  }
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`

const InlineLink = styled(Link)`
  ${inlineActionItemStyles};
`

const StyledLink = styled.a`
  text-decoration: none;
  color: #0060A9;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const AddressNewAppButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const AddressContainer = styled.address<{noMarginBottom ?: boolean}>`
font-style: normal;
font-size: 14px;
color: #6E6E6E;
font-weight: bold;
margin-bottom: ${props => props.noMarginBottom ? '0' : '2rem'};
& span
{
  display:block;
  padding-bottom: 5px;
}
& span:last-child{
    padding-bottom: 0px;
}
`
const NewAppButtonContainer = styled.div`
    margin-left: auto;
`

const UserDetailContainer=styled.div`
& div{
  display: flex;
  align-items: center;   
  margin-bottom: 18px;
  span:first-child{
    flex:1;
    font-size: 16px;
    font-weight: 600;
  }
  span:last-child{
    flex:3;
    font-size: 16px;
  }
}
`

const RegistrationButtonContainer=styled.div`

`

const ErrorP = styled.p`
    margin : -1rem 0 1rem 0;
    font-size : .9rem;
    color : #A52B1D;
`

const DetailP = styled.p`
    margin : -0.7rem 0 1rem 0;
    font-size : .9rem;
`

const GreenCheckmarkWrapper = styled.svg`
  color : #679915;
  padding-right: 1rem;
`

const GreenCheckmark = function(props: {size: number, style?: CSSProperties}) {
  return <GreenCheckmarkWrapper xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} fill="currentColor" style={props.style} className="bi bi-check-circle-fill" viewBox="0 0 16 16"> 
    {CheckmarkPath} 
  </GreenCheckmarkWrapper>
}

const CheckmarkPath = <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>


const BoxWrapper = styled.div`
  border: 1px solid #D2D2D2;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
`

const BoxHeader = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
`

const JoinLines = styled.div`
    border: 2px solid #D2D2D2;
    margin: 0px 4.5em;
    position: relative;
    top: 1.3em;
    @media (max-width: 530px) {
        display: none;
    }  
`

/** Make sure values are centered so they are aligned with labels (making it easier to tell which value goes with each label) */
const SummaryValue = styled.div`
    display: flex;
    align-items: center;
`

export {
  AddressNewAppButtonContainer, AddressContainer, NewAppButtonContainer, ErrorP, InfoText, FlexRowContainer, FlexColumnContainer,
  FlexWrapContainer, PageSubTitle, Title, Divider, GridColumnContainer, GridColumnContainerSignature, NoWrap, WrapDate, InlineLink,UserDetailContainer,
  StyledLink,RegistrationButtonContainer, DetailP, GreenCheckmarkWrapper, GreenCheckmark, CheckmarkPath, PageTitle, BoxWrapper, BoxHeader, 
  JoinLines, majorGap, minorGap, SummaryValue
}