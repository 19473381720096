import React, {useEffect} from "react";
import {BaseApplicationStepProps} from "../Application";
import {GridColumnContainer, PageSubTitle, majorGap} from "../../../styledcomponents/MiscStyledComponents";
import {ApplicationStepButtons} from "../helpers/ApplicationStepButtons";
import GreyBanner from "../../misc/GreyBanner";
import {useAxios} from "../../../AxiosProvider";
import {useMessageService} from "../../../hooks/useMessageService";
import {Page, usePath} from "../../../PathProvider";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../auth/AuthProvider";
import {ApplicationSummaryContent} from "../helpers/ApplicationSummaryContent";
import {TextArea} from "../../form/FormElements";
import {FormProvider, SubmitHandler, useForm, useWatch} from "react-hook-form";
import {FormActionType} from "../helpers/applicationFormReducer";
import {Form, Label} from "../../../styledcomponents/FormStyledComponents";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";
import {AxiosError, AxiosResponse} from "axios";
import {ApplicationStatus} from "../../../enums/ApplicationStatus";
import {UploadedDocumentsTable} from "../helpers/UploadedDocumentsTable";
import {ViewContactInformation} from "../helpers/ViewContactInformation";
import {ContactUsError} from "../../misc/ContactUsError";
import {ApplicationFeesDisplay} from "./ApplicationFeesReview";
import {encode} from 'base64-arraybuffer'
import { getAgreementUrl } from "../../../utils/termsAgreementUtil";
import { NoPageBreak, generateSummaryPdf } from "../../../utils/pdfExportUtils";
import { PrintableSummary } from "../../misc/PrintableSummary";

export type ReviewAndSubmitForm = {
    additionalComments ?: string
}

type ReviewAndSubmitProps = BaseApplicationStepProps & {
}


export function ReviewAndSubmit(props : ReviewAndSubmitProps) {
    const axios = useAxios();
    const messageService = useMessageService();
    const navigate = useNavigate();
    const path = usePath();
    const auth = useAuth();

    const agreementUrl = getAgreementUrl(props.application!.account!.region!, props.application!.additionalInformation!.applicationLevel!, props.application!.id!);
    const agreementFilename = agreementUrl?.split('/').at(-1)?.split('?')[0];

    const methods = useForm<ReviewAndSubmitForm>({
        defaultValues : {
            additionalComments : props.application?.additionalComments
        }
    });

    const {handleSubmit, getValues, control, reset, formState : {isValid, isDirty}} = methods;
    let watchComments = useWatch({control : control});

    // whenever the form is valid, make sure next is enabled. If it is invalid then make sure next is disabled
    React.useEffect(() => {
        if (isValid) {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
        } else {
            props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        }
    }, [isValid, props.application?.nextDisabled]);

    
    
    
    useEffect(() => {
        if (isDirty) {
            const debounceTimer = setTimeout(() => {
                props.applicationFormsDispatch({
                    type: FormActionType.UPDATE_ADDITIONAL_COMMENTS,
                    additionalComments : getValues("additionalComments")!
                });
            }, 500);
            return () => clearTimeout(debounceTimer);
        }
    }, [watchComments, isDirty]);

    function saveAdditionalComments() {
        axios?.secureApi.put(
            '/api/application/saveAdditionalComments',
            {...ApplicationFormUtils.buildApplicationFormRequestBody(props.application!, auth.user!)}
        ).catch((err: AxiosError) => {
            console.error(err);
            messageService.error('Error Submitting Application.')
        })
    }

    function callSubmitApplication(summaryPdf: string) {
        axios?.secureApi.put(
            '/api/application/submitApplication',
            { ...ApplicationFormUtils.buildApplicationFormRequestBody(props.application!, auth.user!, summaryPdf) }
        ).then((response: AxiosResponse) => {
            if (getValues("additionalComments")) {
                saveAdditionalComments();
            }
           // if success then go to summary
           navigate(path.get(Page.ApplicationSummary), { state: { applicationId: props.application?.id } });
        }).catch((err: AxiosError) => {
            console.error(err);
            messageService.error(<ContactUsError errorDetails={err.response?.data?.message}/>)
            props.applicationFormsDispatch({type : FormActionType.END_PROCESSING});
            props.applicationFormsDispatch({type : FormActionType.ENABLE_NEXT});
        })
    }


    const onSubmit : SubmitHandler<ReviewAndSubmitForm> = async (data : ReviewAndSubmitForm) => {
        // clear any messages once we submit
        messageService.clearAll();

        props.applicationFormsDispatch({type : FormActionType.START_PROCESSING});
        props.applicationFormsDispatch({type : FormActionType.DISABLE_NEXT});

        const summaryPdf = await generateSummaryPdf();

        if (props.application?.status == ApplicationStatus.PendingCustomer) {
            axios?.secureApi.put(
                '/api/application/resubmit',
                { ...ApplicationFormUtils.buildApplicationFormRequestBody(props.application, auth.user!, summaryPdf) }
            ).then((response: AxiosResponse) => {

                if (getValues("additionalComments")) {
                    saveAdditionalComments();
                }

                // if success then go to summary
                navigate(path.get(Page.ApplicationSummary), { state: { applicationId: props.application?.id } });
            }).catch((err: AxiosError) => {
                console.error(err);
                messageService.error(<ContactUsError errorDetails={err.response?.data?.message}/>)
            })
        } else if (props.application?.interconnectApplicant) {
             axios?.secureApi.put(
                '/api/application/submitNoAccount',
                { ...ApplicationFormUtils.buildApplicationFormRequestBody(props.application, auth.user!, summaryPdf) }
            ).then((response: AxiosResponse) => {
                 // if success then go to summary
                 navigate(path.get(Page.ApplicationSummary), { state: { applicationId: props.application?.id } });
             }).catch((err: AxiosError) => {
                 console.error(err);
                 messageService.error(<ContactUsError errorDetails={err.response?.data?.message}/>)
             })
        } else {
            let applicationFee = props.application?.approvalCodeAndFees?.fees?.estimatedApplicationFee;
            if (applicationFee == null) {
                messageService.error(<ContactUsError errorDetails={"Estimated application fee is missing."}/>)
                props.applicationFormsDispatch({type: FormActionType.END_PROCESSING});
                props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
                return;
            } 
            
            let isApplicationFee : boolean = !(Number(applicationFee) < 0.01);
            if(isApplicationFee) {
                axios?.secureApi.post(
                    '/api/invoices/determineAndSaveInvoice',
                    {
                        applicationId: props.application?.id,
                        businessPartner: props.application?.account?.businessPartner,
                        contractAccount: props.application?.account?.contractAccount,
                        operatingCompanyId: props.application?.account?.operatingCompanyId,
                        amount: props.application?.approvalCodeAndFees?.fees?.estimatedApplicationFee
                    }
                ).then((response: AxiosResponse) => {
                    callSubmitApplication(summaryPdf);
                }).catch((err: AxiosError) => {
                    console.error(err);
                    messageService.error(<ContactUsError errorDetails={err.response?.data?.message}/>)
                    props.applicationFormsDispatch({type: FormActionType.END_PROCESSING});
                    props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
                })
            }
            else{
                callSubmitApplication(summaryPdf);
            }

            
        }
    };


    return (
        <React.Fragment>
            <PageSubTitle>Review and Submit</PageSubTitle>
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ApplicationSummaryContent application={props.application!} />

                    {/* if status is pending customer then this is a resubmit and there is no fee*/}
                    {
                        props.application?.status != ApplicationStatus.PendingCustomer &&
                        <>
                            <GreyBanner title={"Application Level and Fees"} marginTop={true} />
                            <p>
                                Please note this is a preliminary application level based on the information that you provided in your application and subject to change upon reviewing by FirstEnergy.
                            </p>
                            <GridColumnContainer columns={2} gap={majorGap}>
                                <ApplicationFeesDisplay application={props.application} />
                            </GridColumnContainer>
                        </>
                    }
                    <div style={{height:"1em"}}/>
                    <div>
                        <UploadedDocumentsTable applicationId={props.application?.id!} uploadAllowed={false} deleteAllowed={false}/>
                    </div>
                    {
                        props.application?.contactInformationContainer?.contactInformation &&
                        <>
                            <div style={{height:"0.5em"}}/>
                            <GreyBanner title={"Contact Information"} marginTop={true}/>
                            <ViewContactInformation contactInformation={props.application?.contactInformationContainer?.contactInformation} />
                        </>
                    }
                    <GreyBanner title={"Comments (Optional)"} marginTop={true}/>
                    <div>
                        <Label htmlFor={"additionalComments"} >Please Add Any Additional Comments Regarding This Application</Label>
                        <br />
                    </div>
                    <TextArea maxLength={500} name={"additionalComments"} id={"additionalComments"} />
                </Form>
            </FormProvider>
            <PrintableSummary application={props.application}/>
            <ApplicationStepButtons {...props} onNext={handleSubmit(onSubmit)} />
            {props.application?.processing && <div style={{padding: "0.2em", marginTop:"5em", textAlign: "center"}}>Submitting your application. This may take up to 2 minutes, please do not refresh the page.</div>}
        </React.Fragment>
    )
}