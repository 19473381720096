import React, { ReactElement } from "react"
import Modal from 'react-modal';
import {Divider, FlexColumnContainer, FlexRowContainer} from "../../../styledcomponents/MiscStyledComponents";
import {Label, PrimaryButton, SecondaryButton} from "../../../styledcomponents/FormStyledComponents";
import styled from "styled-components";
import Close from "../../../../src/assets/images/Close.svg"
import {TextArea} from "../../form/FormElements";


const CloseButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`
const CloseImg = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
`


const ButtonContainer = styled(FlexRowContainer)`
  justify-content: space-between;
`

export const ModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '35vw',
    },
    overlay : {
        backgroundColor: 'rgb(164 156 156 / 75%)'
    }
};

type ConfirmationProps  = {
    isOpen: boolean
    setIsOpen: (data : boolean) => void
    title : string
    body : string | ReactElement
    onConfirm:()=>void
    addTextArea?: boolean
    textAreaName?: string
    confirmText ?: string
}

export default function ConfirmationModal(props : ConfirmationProps) {

    function onConfirm() {
        props.onConfirm();
        props.setIsOpen(false);
    }

    return (
        <Modal isOpen={props.isOpen} style={ModalStyles}>
            <FlexColumnContainer>
                <Label >{props.title}</Label>
                <CloseButton onClick={() => props.setIsOpen(false)}>
                    <CloseImg src={Close} alt={"close icon"} />
                </CloseButton>
                <div> { typeof props.body === 'string' ? <p>{props.body}</p> : props.body}</div>
                {
                    props.addTextArea &&

                        <TextArea resizable={false} name={props.textAreaName}  />
                }

                <Divider />
                <ButtonContainer>
                    <SecondaryButton type={"button"} onClick={() => props.setIsOpen(false)}>Cancel</SecondaryButton>
                    <PrimaryButton type={"submit"} onClick={onConfirm}>{props.confirmText ?? 'Submit'}</PrimaryButton>
                </ButtonContainer>
            </FlexColumnContainer>
        </Modal>
    )
}