import React from "react";
import {BaseApplicationStepProps} from "../Application";
import {
    FlexColumnContainer,
    GridColumnContainer
} from "../../../styledcomponents/MiscStyledComponents";
import GreyBanner from "../../misc/GreyBanner";
import {Form, Label} from "../../../styledcomponents/FormStyledComponents";
import {FormActionType} from "../helpers/applicationFormReducer";
import {ApplicationStepButtons} from "../helpers/ApplicationStepButtons";
import {useAxios} from "../../../AxiosProvider";
import {useMessageService} from "../../../hooks/useMessageService";
import {RadioInput} from "../../form/FormElements";
import ErrorUtils from "../../../utils/errorUtils";
import {SubmitHandler, useForm, FormProvider} from "react-hook-form";
import {useAuth} from "../../../auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import {Page, usePath} from "../../../PathProvider";

export type AccountVerificationForm = {
    choice : string
}

type AccountVerificationProps = BaseApplicationStepProps & {

}

export function AccountVerification(props : AccountVerificationProps) {
    const axios = useAxios();
    const messageService = useMessageService();
    const navigate = useNavigate();
    const path = usePath();

    const [loadingChoices, setLoadingChoices] = React.useState<boolean>(true);
    const [choices, setChoices] = React.useState<string[]>([]);
    const [correctAnswer, setCorrectAnswer] = React.useState<string>();
    const [attemptsRemaining, setAttemptsRemaining] = React.useState<number>(3);

    const methods = useForm<AccountVerificationForm>({});
    const {handleSubmit, formState : {isValid}} = methods;

    // whenever the form is valid, make sure next is enabled. If it is invalid then make sure next is disabled
    React.useEffect(() => {
        if (isValid) {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
        } else {
            props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        }
    }, [isValid]);

    const onSubmit : SubmitHandler<AccountVerificationForm> = async (data : AccountVerificationForm) => {
        // clear any messages once we submit
        messageService.clearAll();

        // if the user chose the correct answer, set the account for the form and go forward
        if (data.choice === correctAnswer) {
            props.applicationFormsDispatch({type: FormActionType.UPDATE_ACCOUNT, account : props.application?.accountPendingVerification!});
            props.applicationFormsDispatch({type : FormActionType.FORWARD});
        } else {
            // decrease the attempts remaining. If they're at 0 then bring them back to the application list. They will NOT be locked for this.
            let newAttemptsRemaining = attemptsRemaining - 1;
            if (newAttemptsRemaining === 0) {
                // TODO: Change this message to whatever
                navigate(path.get(Page.ApplicationList), { state : { warningMessage : 'You have exceeded the maximum number of attempts. Please try again.' }});
            } else {
                // reload choices, update attempts remaining, and show the user and error
                setLoadingChoices(true);
                setAttemptsRemaining(newAttemptsRemaining);
                messageService.error(`The information provided does not match our records. Please check your entry and try again. You have ${newAttemptsRemaining} attempt${newAttemptsRemaining > 1 ? 's' : ''} remaining.`);
            }
        }

    };

    // load the random address information on page load
    React.useEffect( () => {
        if (choices.length === 0 || loadingChoices) {
            setLoadingChoices(true);

            // call the function
            axios?.secureApi.get(
                `/api/account/getRandomAddressInformation/${props.application?.accountPendingVerification?.contractAccount}/${props.application?.accountPendingVerification?.zipCode}`
            ).then((response) => {
                setChoices(response.data.choices);
                setCorrectAnswer(response.data.correctAnswer);
            }).catch(() => {
                messageService.error(ErrorUtils.UNKNOWN_ERROR);
            }).finally(() => {
                setLoadingChoices(false);
            })
        }
    }, [loadingChoices])

    return (
        <FlexColumnContainer>
            <GreyBanner title={'Customer Account Verification'} />
            <div>Select the correct option below that best matches the beginning of the address for this account (number, street, or route).</div>
            {
                loadingChoices
                    ? <div>Loading...</div>
                    : <FormProvider {...methods}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <GridColumnContainer columns={3}>
                                {
                                    choices.map((choice : string) => {
                                        return  (
                                            <Label htmlFor={"choice"} checkbox={true} key={choice}>
                                                <RadioInput name={'choice'} required={true} value={choice}/>
                                                {choice}
                                            </Label>
                                        )
                                    })
                                }
                            </GridColumnContainer>
                        </Form>
                    </FormProvider>
            }
            <ApplicationStepButtons {...props} onNext={handleSubmit(onSubmit)}/>
        </FlexColumnContainer>

    )
}