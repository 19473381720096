import React from "react";
import {Divider, GridColumnContainer, SummaryValue, majorGap} from "../../../styledcomponents/MiscStyledComponents";
import {Label} from "../../../styledcomponents/FormStyledComponents";
import {ContactInformationForm} from "../steps/ContactInformation";


type ViewAdditionalContactsProps = {
    contactInformation : ContactInformationForm[]
}

export function ViewContactInformation(props : ViewAdditionalContactsProps) {
    return (
        <React.Fragment>
            {props.contactInformation.map((contact,index)=>
                <React.Fragment key={contact.title}>
                    <GridColumnContainer columns={2} gap={majorGap}>
                        <Label htmlFor={"contact"}>Contact</Label>
                        <SummaryValue>{index +1}</SummaryValue>
                        <Label htmlFor={"title"}>Title</Label>
                        <SummaryValue>{contact.title}</SummaryValue>
                        <Label htmlFor={"title"}>Full Name</Label>
                        <SummaryValue>{contact.name}</SummaryValue>
                        {
                            contact.company&&
                            <React.Fragment>
                                <Label htmlFor={"company"}>Company</Label>
                                <SummaryValue>{contact.company}</SummaryValue>
                            </React.Fragment>
                        }
                        <Label htmlFor={"email"}>Email</Label>
                        <SummaryValue>{contact.email}</SummaryValue>
                        <Label htmlFor={"ieeeCertification"}>Phone Number</Label>
                        <SummaryValue>{contact.phoneNumber}</SummaryValue>
                    </GridColumnContainer>
                    {
                        (props.contactInformation?.length! > 1 || (index + 1)  !== props.contactInformation.length!) &&
                        <Divider />
                    }
                </React.Fragment>
            )}
        </React.Fragment>
    )
}