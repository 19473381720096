import {StepIcon, StepIconsWrapper} from "../../misc/StepIcon";
import React from "react";
import {JoinLines} from "../../../styledcomponents/MiscStyledComponents";

export enum InterconnectApplicantStep {
    AccountInfo,
    ServiceInfo,
    ConnectionInfo,
    ReviewAndSubmit
}

const StepIconMap = new Map<InterconnectApplicantStep, string>([
    [InterconnectApplicantStep.AccountInfo, "M 17.9722 6.2778 V 18.7222 H 5.5278 V 6.2778 H 17.9722 Z M 17.9722 4.5 H 5.5278 C 4.5411 4.5 3.75 5.3 3.75 6.2778 V 18.7222 C 3.75 19.7 4.5411 20.5 5.5278 20.5 H 17.9722 C 18.95 20.5 19.75 19.7 19.75 18.7222 V 6.2778 C 19.75 5.3 18.95 4.5 17.9722 4.5 Z M 11.75 12.5 C 10.2833 12.5 9.0833 11.3 9.0833 9.8333 C 9.0833 8.3667 10.2833 7.1667 11.75 7.1667 C 13.2167 7.1667 14.4167 8.3667 14.4167 9.8333 C 14.4167 11.3 13.2167 12.5 11.75 12.5 Z M 11.75 8.9444 C 11.2611 8.9444 10.8611 9.3444 10.8611 9.8333 C 10.8611 10.3222 11.2611 10.7222 11.75 10.7222 C 12.2389 10.7222 12.6389 10.3222 12.6389 9.8333 C 12.6389 9.3444 12.2389 8.9444 11.75 8.9444 Z M 17.0833 17.8333 H 6.4167 V 16.4733 C 6.4167 14.2511 9.9456 13.2911 11.75 13.2911 C 13.5544 13.2911 17.0833 14.2511 17.0833 16.4733 V 17.8333 Z M 8.47 16.0556 H 15.03 C 14.4167 15.5578 12.9144 15.06 11.75 15.06 C 10.5856 15.06 9.0744 15.5578 8.47 16.0556 Z"],
    [InterconnectApplicantStep.ServiceInfo, "M10.0002 20.7951H6.0002C5.71686 20.7951 5.4792 20.6991 5.2872 20.5071C5.09586 20.3158 5.0002 20.0785 5.0002 19.7951V12.7951H3.3002C3.06686 12.7951 2.90853 12.6868 2.8252 12.4701C2.74186 12.2535 2.79186 12.0701 2.9752 11.9201L11.3252 4.39512C11.5085 4.21178 11.7335 4.12012 12.0002 4.12012C12.2669 4.12012 12.4919 4.21178 12.6752 4.39512L16.0002 7.39512V5.79512C16.0002 5.51178 16.0962 5.27412 16.2882 5.08212C16.4795 4.89078 16.7169 4.79512 17.0002 4.79512H18.0002C18.2835 4.79512 18.5209 4.89078 18.7122 5.08212C18.9042 5.27412 19.0002 5.51178 19.0002 5.79512V10.0951L21.0252 11.9201C21.2085 12.0701 21.2585 12.2535 21.1752 12.4701C21.0919 12.6868 20.9335 12.7951 20.7002 12.7951H19.0002V19.7951C19.0002 20.0785 18.9042 20.3158 18.7122 20.5071C18.5209 20.6991 18.2835 20.7951 18.0002 20.7951H14.0002V15.7951C14.0002 15.5118 13.9045 15.2741 13.7132 15.0821C13.5212 14.8908 13.2835 14.7951 13.0002 14.7951H11.0002C10.7169 14.7951 10.4795 14.8908 10.2882 15.0821C10.0962 15.2741 10.0002 15.5118 10.0002 15.7951V20.7951ZM10.0002 10.8201H14.0002C14.0002 10.2868 13.8002 9.84912 13.4002 9.50712C13.0002 9.16578 12.5335 8.99512 12.0002 8.99512C11.4669 8.99512 11.0002 9.16578 10.6002 9.50712C10.2002 9.84912 10.0002 10.2868 10.0002 10.8201Z"],
    [InterconnectApplicantStep.ConnectionInfo, "M10.6751 21.7949C10.4918 21.7949 10.3418 21.7283 10.2251 21.5949C10.1084 21.4616 10.0668 21.3033 10.1001 21.1199L11.0001 14.7949H7.50011C7.25011 14.7949 7.09578 14.7283 7.03712 14.5949C6.97912 14.4616 7.01678 14.2783 7.15011 14.0449L12.8501 4.06992C12.8834 3.98659 12.9501 3.91992 13.0501 3.86992C13.1501 3.81992 13.2418 3.79492 13.3251 3.79492C13.5084 3.79492 13.6584 3.86159 13.7751 3.99492C13.8918 4.12826 13.9334 4.28659 13.9001 4.46992L13.0001 10.7949H16.5001C16.7501 10.7949 16.9041 10.8616 16.9621 10.9949C17.0208 11.1283 16.9834 11.3116 16.8501 11.5449L11.1501 21.5199C11.1168 21.6033 11.0501 21.6699 10.9501 21.7199C10.8501 21.7699 10.7584 21.7949 10.6751 21.7949Z"],
    [InterconnectApplicantStep.ReviewAndSubmit, "M20.0839 14.0763L18.326 12.3521L19.044 11.6478C19.1761 11.5183 19.3494 11.4535 19.564 11.4535C19.7785 11.4535 19.9519 11.5183 20.0839 11.6478L20.8019 12.3521C20.934 12.4816 21 12.6516 21 12.8621C21 13.0725 20.934 13.2425 20.8019 13.3721L20.0839 14.0763ZM11.9133 20.3664V18.6421L17.2613 13.3964L19.0193 15.1206L13.6713 20.3664H11.9133ZM3 15.2663V13.8092H10.4278V15.2663H3ZM3 11.2592V9.80206H14.6369V11.2592H3ZM3 7.25206V5.79492H14.6369V7.25206H3Z"]
]);

type CompleteAccountStepIconProps = CompleteAccountStepIconsProps & {
    step : InterconnectApplicantStep;
    text : string;
}

function CompleteAccountStepIcon(props :Readonly<CompleteAccountStepIconProps>) {
    let currentStepIndex = props.stepOrder.indexOf(props.currentStep!);
    let stepIndex = props.stepOrder.indexOf(props.step);

    const canJumpBackTo = currentStepIndex > stepIndex;
    const jumpBackTo = () => {
        props.setCurrentStep(props.step)
    }

    return (
        <StepIcon text={props.text}
                  currentStep={props.currentStep}
                  step={props.step}
                  stepOrder={props.stepOrder}
                  stepIconMap={StepIconMap}
                  onClick={canJumpBackTo ? jumpBackTo : undefined}
        />
    )
}

type CompleteAccountStepIconsProps = {
    currentStep : InterconnectApplicantStep
    stepOrder : InterconnectApplicantStep[]
    setCurrentStep : (data : InterconnectApplicantStep) => void
}

export function InterconnectApplicantStepIcons(props : Readonly<CompleteAccountStepIconsProps>) {
    return (
        <>
            <JoinLines/>
            <StepIconsWrapper>
                <CompleteAccountStepIcon step={InterconnectApplicantStep.AccountInfo} {...props} text={`Account\nInformation`}/>
                <CompleteAccountStepIcon step={InterconnectApplicantStep.ServiceInfo} {...props} text={'Service\nInformation'}/>
                <CompleteAccountStepIcon step={InterconnectApplicantStep.ConnectionInfo} {...props} text={`Connection\nInformation`}/>
                <CompleteAccountStepIcon step={InterconnectApplicantStep.ReviewAndSubmit} {...props} text={'Review\nand Submit'}/>
            </StepIconsWrapper>
        </>

    )
}