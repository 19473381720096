import React, { Dispatch } from "react";
import {ApplicationForm} from "../Application";
import styled from "styled-components";
import {JoinLines} from "../../../styledcomponents/MiscStyledComponents";
import {useAuth} from "../../../auth/AuthProvider";
import { FormAction, FormActionType } from "./applicationFormReducer";
import { ApplicationStep } from "../../../enums/ApplicationStep";
import {StepIcon, StepIconsWrapper} from "../../misc/StepIcon";

const LinkWrapper = styled.div`
    cursor: pointer;
`

const StepIconMap = new Map<ApplicationStep, string>([
    [ApplicationStep.GeneratorFacilityInformation, "M2 22.7969L4 12.7969H20L22 22.7969H2ZM3 4.29688V2.79688H6V4.29688H3ZM3.85 21.2969H11.25V18.5469H4.4L3.85 21.2969ZM5.85 9.79688L4.775 8.74688L6.9 6.62188L7.975 7.67188L5.85 9.79688ZM4.7 17.0469H11.25V14.2969H5.25L4.7 17.0469ZM12 7.29688C10.7667 7.29688 9.725 6.85938 8.875 5.98438C8.025 5.10938 7.56667 4.04688 7.5 2.79688H9C9.05 3.63021 9.35833 4.33854 9.925 4.92188C10.4917 5.50521 11.1833 5.79688 12 5.79688C12.8167 5.79688 13.5083 5.50521 14.075 4.92188C14.6417 4.33854 14.95 3.63021 15 2.79688H16.5C16.4333 4.04688 15.975 5.10938 15.125 5.98438C14.275 6.85938 13.2333 7.29688 12 7.29688ZM11.25 11.7969V8.79688H12.75V11.7969H11.25ZM12.75 21.2969H20.15L19.6 18.5469H12.75V21.2969ZM12.75 17.0469H19.3L18.75 14.2969H12.75V17.0469ZM18.15 9.77187L16.025 7.64687L17.075 6.59687L19.225 8.69688L18.15 9.77187ZM18 4.29688V2.79688H21V4.29688H18Z"],
    [ApplicationStep.ApplicationFees, "M 7.5 15.4635 H 9.2778 C 9.2778 16.4235 10.4956 17.2413 11.9444 17.2413 C 13.3933 17.2413 14.6111 16.4235 14.6111 15.4635 C 14.6111 14.4858 13.6867 14.1302 11.7311 13.6591 C 9.8467 13.188 7.5 12.6013 7.5 10.1302 C 7.5 8.5391 8.8067 7.188 10.6111 6.7347 V 4.7969 H 13.2778 V 6.7347 C 15.0822 7.188 16.3889 8.5391 16.3889 10.1302 H 14.6111 C 14.6111 9.1702 13.3933 8.3524 11.9444 8.3524 C 10.4956 8.3524 9.2778 9.1702 9.2778 10.1302 C 9.2778 11.108 10.2022 11.4635 12.1578 11.9347 C 14.0422 12.4058 16.3889 12.9924 16.3889 15.4635 C 16.3889 17.0547 15.0822 18.4058 13.2778 18.8591 V 20.7969 H 10.6111 V 18.8591 C 8.8067 18.4058 7.5 17.0547 7.5 15.4635 Z"],
    [ApplicationStep.ContactInformation, "M16.7334 10.5943C17.9334 11.7943 17.9334 13.6609 16.7334 14.9276L17.4 15.5943C19.0667 14.0609 19.0667 11.5276 17.4 9.9276L16.7334 10.5943ZM16 11.3276L15.3334 11.9943C15.6667 12.4609 15.6667 13.0609 15.3334 13.5276L16 14.1943C16.8 13.3943 16.8 12.1943 16 11.3276ZM13.3334 5.46094H6.66671C6.31309 5.46094 5.97395 5.60141 5.7239 5.85146C5.47385 6.10151 5.33337 6.44065 5.33337 6.79427V18.7943C5.33337 19.1479 5.47385 19.487 5.7239 19.7371C5.97395 19.9871 6.31309 20.1276 6.66671 20.1276H13.3334C13.687 20.1276 14.0261 19.9871 14.2762 19.7371C14.5262 19.487 14.6667 19.1479 14.6667 18.7943V6.79427C14.6667 6.44065 14.5262 6.10151 14.2762 5.85146C14.0261 5.60141 13.687 5.46094 13.3334 5.46094ZM13.3334 18.1276H6.66671V7.46094H13.3334V18.1276Z"],
    [ApplicationStep.AccountVerification, "M 17.9722 6.2778 V 18.7222 H 5.5278 V 6.2778 H 17.9722 Z M 17.9722 4.5 H 5.5278 C 4.5411 4.5 3.75 5.3 3.75 6.2778 V 18.7222 C 3.75 19.7 4.5411 20.5 5.5278 20.5 H 17.9722 C 18.95 20.5 19.75 19.7 19.75 18.7222 V 6.2778 C 19.75 5.3 18.95 4.5 17.9722 4.5 Z M 11.75 12.5 C 10.2833 12.5 9.0833 11.3 9.0833 9.8333 C 9.0833 8.3667 10.2833 7.1667 11.75 7.1667 C 13.2167 7.1667 14.4167 8.3667 14.4167 9.8333 C 14.4167 11.3 13.2167 12.5 11.75 12.5 Z M 11.75 8.9444 C 11.2611 8.9444 10.8611 9.3444 10.8611 9.8333 C 10.8611 10.3222 11.2611 10.7222 11.75 10.7222 C 12.2389 10.7222 12.6389 10.3222 12.6389 9.8333 C 12.6389 9.3444 12.2389 8.9444 11.75 8.9444 Z M 17.0833 17.8333 H 6.4167 V 16.4733 C 6.4167 14.2511 9.9456 13.2911 11.75 13.2911 C 13.5544 13.2911 17.0833 14.2511 17.0833 16.4733 V 17.8333 Z M 8.47 16.0556 H 15.03 C 14.4167 15.5578 12.9144 15.06 11.75 15.06 C 10.5856 15.06 9.0744 15.5578 8.47 16.0556 Z"],
    [ApplicationStep.AccountLookup, "M 17.9722 6.2778 V 18.7222 H 5.5278 V 6.2778 H 17.9722 Z M 17.9722 4.5 H 5.5278 C 4.5411 4.5 3.75 5.3 3.75 6.2778 V 18.7222 C 3.75 19.7 4.5411 20.5 5.5278 20.5 H 17.9722 C 18.95 20.5 19.75 19.7 19.75 18.7222 V 6.2778 C 19.75 5.3 18.95 4.5 17.9722 4.5 Z M 11.75 12.5 C 10.2833 12.5 9.0833 11.3 9.0833 9.8333 C 9.0833 8.3667 10.2833 7.1667 11.75 7.1667 C 13.2167 7.1667 14.4167 8.3667 14.4167 9.8333 C 14.4167 11.3 13.2167 12.5 11.75 12.5 Z M 11.75 8.9444 C 11.2611 8.9444 10.8611 9.3444 10.8611 9.8333 C 10.8611 10.3222 11.2611 10.7222 11.75 10.7222 C 12.2389 10.7222 12.6389 10.3222 12.6389 9.8333 C 12.6389 9.3444 12.2389 8.9444 11.75 8.9444 Z M 17.0833 17.8333 H 6.4167 V 16.4733 C 6.4167 14.2511 9.9456 13.2911 11.75 13.2911 C 13.5544 13.2911 17.0833 14.2511 17.0833 16.4733 V 17.8333 Z M 8.47 16.0556 H 15.03 C 14.4167 15.5578 12.9144 15.06 11.75 15.06 C 10.5856 15.06 9.0744 15.5578 8.47 16.0556 Z"],
    [ApplicationStep.CustomerInformation, "M 17.9722 6.2778 V 18.7222 H 5.5278 V 6.2778 H 17.9722 Z M 17.9722 4.5 H 5.5278 C 4.5411 4.5 3.75 5.3 3.75 6.2778 V 18.7222 C 3.75 19.7 4.5411 20.5 5.5278 20.5 H 17.9722 C 18.95 20.5 19.75 19.7 19.75 18.7222 V 6.2778 C 19.75 5.3 18.95 4.5 17.9722 4.5 Z M 11.75 12.5 C 10.2833 12.5 9.0833 11.3 9.0833 9.8333 C 9.0833 8.3667 10.2833 7.1667 11.75 7.1667 C 13.2167 7.1667 14.4167 8.3667 14.4167 9.8333 C 14.4167 11.3 13.2167 12.5 11.75 12.5 Z M 11.75 8.9444 C 11.2611 8.9444 10.8611 9.3444 10.8611 9.8333 C 10.8611 10.3222 11.2611 10.7222 11.75 10.7222 C 12.2389 10.7222 12.6389 10.3222 12.6389 9.8333 C 12.6389 9.3444 12.2389 8.9444 11.75 8.9444 Z M 17.0833 17.8333 H 6.4167 V 16.4733 C 6.4167 14.2511 9.9456 13.2911 11.75 13.2911 C 13.5544 13.2911 17.0833 14.2511 17.0833 16.4733 V 17.8333 Z M 8.47 16.0556 H 15.03 C 14.4167 15.5578 12.9144 15.06 11.75 15.06 C 10.5856 15.06 9.0744 15.5578 8.47 16.0556 Z"],
    [ApplicationStep.ReviewAndSubmit, "M20.0839 14.0783L18.326 12.354L19.044 11.6497C19.1761 11.5202 19.3494 11.4554 19.564 11.4554C19.7785 11.4554 19.9519 11.5202 20.0839 11.6497L20.8019 12.354C20.934 12.4835 21 12.6535 21 12.864C21 13.0745 20.934 13.2445 20.8019 13.374L20.0839 14.0783ZM11.9133 20.3683V18.644L17.2613 13.3983L19.0193 15.1226L13.6713 20.3683H11.9133ZM3 15.2683V13.8112H10.4278V15.2683H3ZM3 11.2612V9.80402H14.6369V11.2612H3ZM3 7.25402V5.79688H14.6369V7.25402H3Z"],
]);

type ApplicationStepIconProps = {
    step : ApplicationStep;
    text : string;
    applicationForm : ApplicationForm | null;
    applicationFormsDispatch?: Dispatch<FormAction>;
}

function ApplicationStepIcon(props :Readonly<ApplicationStepIconProps>) {
    if (props.applicationForm === null) {
        return <></>
    }

    let stepOrder = props.applicationForm?.steps!;
    let currentStepIndex = stepOrder.indexOf(props.applicationForm?.currentStep!);
    let stepIndex = stepOrder.indexOf(props.step);

    const canJumpBackTo = props.applicationFormsDispatch && (currentStepIndex > stepIndex);
    const jumpBackTo = () => {
        props.applicationFormsDispatch && props.applicationFormsDispatch({ type: FormActionType.JUMP_BACK, step: props.step});
    }

    return (
        <StepIcon text={props.text}
                  currentStep={props.applicationForm?.currentStep}
                  step={props.step}
                  stepOrder={props.applicationForm?.steps}
                  stepIconMap={StepIconMap}
                  onClick={canJumpBackTo ? jumpBackTo : undefined}
        />
    )
}

type ApplicationStepIconsProps = {
    applicationForm : ApplicationForm | null;
    noAccount : boolean;
    applicationFormsDispatch: Dispatch<FormAction>;
}

export function ApplicationStepIcons(props : Readonly<ApplicationStepIconsProps>) {
    const auth = useAuth();

    return (
        <>
            <JoinLines/>
            <StepIconsWrapper>
                {
                    auth.user?.isVendor && !props.noAccount
                        ? <ApplicationStepIcon step={ApplicationStep.AccountLookup} text={'Account\nLookup'} applicationForm={props.applicationForm}/>
                        : <ApplicationStepIcon step={ApplicationStep.CustomerInformation} text={'Customer\nInformation'} applicationForm={props.applicationForm}/>
                }
                <ApplicationStepIcon applicationFormsDispatch={props.applicationFormsDispatch} step={ApplicationStep.ContactInformation} text={`Contact\nInformation`} applicationForm={props.applicationForm}/>
                <ApplicationStepIcon applicationFormsDispatch={props.applicationFormsDispatch} step={ApplicationStep.GeneratorFacilityInformation} text={'Generator Facility\nInformation'} applicationForm={props.applicationForm}/>
                <ApplicationStepIcon applicationFormsDispatch={props.applicationFormsDispatch} step={ApplicationStep.ApplicationFees} text={`Additional\nInformation`} applicationForm={props.applicationForm}/>
                <ApplicationStepIcon step={ApplicationStep.ReviewAndSubmit} text={'Review\nand Submit'} applicationForm={props.applicationForm}/>
            </StepIconsWrapper>
        </>
    )
}