// @ts-ignore
import * as html2pdf from "html2pdf.js";
import {encode} from 'base64-arraybuffer'
import styled from "styled-components";

const pdfSettings = {
    margin:       0.4,
    filename:     'myfile.pdf',
    image:        { type: 'jpeg', quality: 0.6 },
    html2canvas:  { scale: 2 },
    jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
}
/** Used for PDF rendering, avoids page breaks inside this element */
export const NoPageBreak = styled.div`
    break-inside: avoid;
`

/** Generate PDF file and encode in Base64 to send to server. There must be an element with the id "print" currently displayed, containing the content to export.*/
export const generateSummaryPdf = async function() {
    let pdfArrayBuffer : ArrayBuffer = await html2pdf().set(pdfSettings)
        .from(document.getElementById('print')).outputPdf('arraybuffer', {});
    const base64 = encode(pdfArrayBuffer);
    return base64;
}

/**Use this function to test changes rendering the email summary PDF */
const testPdf = async function(save ?: boolean) {
    html2pdf().set(pdfSettings).from(document.getElementById('print')).save();
}


//Uncomment the "window.testPdf..." line and run window.testPdf() in the browser to test PDF rendering.
//@ts-ignore
// window.testPdf = testPdf;