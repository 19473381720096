import {ApplicationForm, LoadedApplication, stepList} from "../components/application/Application";
import AuthResponse from "../models/AuthResponse";
import {ApplicationStatus} from "../enums/ApplicationStatus";
import {ApplicationStep} from "../enums/ApplicationStep";
import { State } from "../enums/State";
import { AttachmentFormData } from "../components/application/forms/AttachmentForm";
import { Status } from "../models/AttachmentResponse";

export const energyStorageFeeStates : string[] = [//list of states where we need to add the energy storage output to the inverter size for level/fees
    State.NJ,
    State.PA
]
export default class ApplicationFormUtils {
    
    static includeAcEnergyStorageForFees(form: ApplicationForm) {
        return energyStorageFeeStates.includes(form?.account?.region ?? '');
    }
    /**
     * Calculate the "inverter size" value used for fee and level calculation, based on the state, AC gen/inverter output, and AC energy storage ouput.
     * Some states (NJ and PA) include the AC energy storage output in their fee/level calculations, others do not.
     */
    static calculateFeeInverterSize(form: ApplicationForm) {
        
        //this lambda gets the number from a string, or 0 if the string is not convertable to a number
        const numberOrZero = (a?: string) => (isNaN(Number(a)) ? 0 : Number(a));
        let acSystemSize = numberOrZero(form.system?.sourceSystem.acRatedCapacity);
        if (ApplicationFormUtils.includeAcEnergyStorageForFees(form)
            && !form.system?.sourceSystem.energyStorageOutputIsDc) {
                //add the AC energy storage output to our fees calculation
                acSystemSize += numberOrZero(form.system?.sourceSystem.energyStorageOutput);
        }
        return acSystemSize;
    }

    static buildApplicationFormRequestBody(applicationForm: ApplicationForm, loggedInUser: AuthResponse, summaryPdf?: string): any {
        let account = applicationForm.account!;
        applicationForm.equipment?.inverters.forEach((inverter, index) => {
            if (!!inverter && typeof inverter.inverterType === "string") {
                applicationForm!.equipment!.inverters[index].inverterType = JSON.parse(inverter.inverterType)
            }
        })
        applicationForm.equipment?.synchronousGenerators.forEach((synchronousGenerator, index) => {
            if (!!synchronousGenerator && typeof synchronousGenerator.synchronousType === "string") {
                applicationForm!.equipment!.synchronousGenerators[index].synchronousType = JSON.parse(synchronousGenerator.synchronousType)
            }
        })

        return {
            id: applicationForm.id,
            interconnectNumber: applicationForm.interconnectNumber,
            accountNumber: account?.contractAccount,
            premiseNumber: account?.premise,
            accountName: account?.name,
            businessPartner: account?.businessPartner,
            customerEmail: loggedInUser.email,
            accountAddress: account?.serviceAddressLine4,
            accountCity: account?.city,
            accountState: account?.region,
            accountZipCode: account?.zipCode,
            operatingCompanyAbbreviation: applicationForm?.interconnectApplicant?.operatingCompanyAbbreviation ?? account?.operatingCompany,
            operatingCompanyId: account?.operatingCompanyId,
            vendorId: loggedInUser.vendor?.id,
            lastStepCompleted: applicationForm.currentStep,
            reviewPdfBase64: summaryPdf,
            applicationData: {
                accountNumber: account?.contractAccount,
                customerType: account?.customerType,
                interconnectNumber: applicationForm.interconnectNumber,
                createdBy: loggedInUser.username,
                batteries: applicationForm.equipment?.batteries,
                inverters: applicationForm?.equipment?.inverters,
                synchronousGenerators: applicationForm?.equipment?.synchronousGenerators,
                inductionGenerators: applicationForm?.equipment?.inductionGenerators,
                existingSystem: applicationForm.system?.existingSystem,
                sourceSystem: applicationForm.system?.sourceSystem,
                dcSource: applicationForm.equipment?.dcSource,
                additionalContacts: applicationForm.contactInformationContainer?.contactInformation,
                additionalComments: applicationForm.additionalComments,
                attachmentsComplete: applicationForm?.attachmentsComplete,
                interconnectApplicant: applicationForm.interconnectApplicant,
                additionalInformation: applicationForm.additionalInformation,
                paAgreementExtra: applicationForm.paAgreementExtra,
                approvalCode: applicationForm.approvalCodeAndFees?.approvalCode,
                fees: applicationForm.approvalCodeAndFees?.fees,
                systemComplete: applicationForm?.systemComplete,
                equipmentComplete: applicationForm?.equipmentComplete,
                contactsComplete : applicationForm?.contactsComplete
            }
        }
    }

    static buildApplicationFormFromLoadedApplication(loadedApplication: LoadedApplication, isVendor? : boolean): ApplicationForm {
        let system = loadedApplication.applicationDataObject.sourceSystem
        let equipmentDetail = [];

        let resubmit = loadedApplication.status == ApplicationStatus.PendingCustomer;

        if (system.generationEquipment) {
            if (system.generationEquipment.identifier === "02") {
                equipmentDetail =
                    loadedApplication.applicationDataObject.synchronousGenerators || []
            } else if (system.generationEquipment.identifier === "03") {
                equipmentDetail =
                    loadedApplication.applicationDataObject.inductionGenerators || []
            } else {
                equipmentDetail =
                    loadedApplication.applicationDataObject.inverters || []
            }
        }
        let interconnectApplicant = loadedApplication.applicationDataObject?.interconnectApplicant;
        // If isVendor is not provided, we are in applicationSummary and don't need steps.
        // Otherwise, use noAccount steps if the user is on no-account flow or is not a vendor.
        let steps = (isVendor === undefined) 
            ? undefined 
            : ((interconnectApplicant || !isVendor) ? stepList.noAccount : stepList.account).slice();

        // if we are resubmitting then we don't want to show the fees, so we go ahead and remove them from the steps array
        if (resubmit && steps) {
            let indexOfFees = steps.indexOf(ApplicationStep.ApplicationFees);
            if (indexOfFees != -1) { steps?.splice(indexOfFees, 1) }

            let indexOfFeesReview = steps.indexOf(ApplicationStep.ApplicationFeesReview);
            if (indexOfFeesReview != -1) { steps?.splice(indexOfFeesReview, 1) }
        }

        return {
            id: loadedApplication.id,
            status: loadedApplication.status,
            interconnectNumber: loadedApplication.interconnectNumber,
            dateSubmitted: loadedApplication.dateSubmitted ? new Date(loadedApplication.dateSubmitted).toLocaleString(undefined, {year:'numeric', month: 'numeric', day: 'numeric'}) : '',
            account: {
                contractAccount: loadedApplication.accountNumber!,
                customerType : loadedApplication.applicationDataObject.customerType!,
                premise: loadedApplication.premiseNumber!,
                name: loadedApplication.accountName!,
                houseNumber: loadedApplication.accountAddress!, // FIXME: Get house num
                street: loadedApplication.accountAddress!, // FIXME: Get house num
                city: loadedApplication.accountCity!,
                region: loadedApplication.accountState!,
                zipCode: loadedApplication.accountZipCode!,
                businessPartner: loadedApplication.businessPartner,
                operatingCompanyId: loadedApplication.operatingCompanyId,
                addressAsHtml: loadedApplication.addressAsHtml
            },
            interconnectApplicant: interconnectApplicant ? {
                ...interconnectApplicant,
                operatingCompanyAbbreviation : loadedApplication.applicationDataObject?.operatingCompanyAbbreviation
            } : null,
            equipment: {
                batteries: (loadedApplication.applicationDataObject.batteries || []).map((i: any) => sanitizeIdentifierCapitalization(i)),
                equipmentDetail: (equipmentDetail || []).map((i: any) => sanitizeIdentifierCapitalization(i)),
                synchronousGenerators:
                    (loadedApplication.applicationDataObject.synchronousGenerators || []).map((i: any) => sanitizeIdentifierCapitalization(i)),
                inductionGenerators:
                    loadedApplication.applicationDataObject.inductionGenerators || [],
                inverters: (loadedApplication.applicationDataObject.inverters || []).map((i: any) => sanitizeIdentifierCapitalization(i)),
                dcSource: loadedApplication.applicationDataObject?.dcSource,
            },
            contactInformationContainer: {
                contactInformation: loadedApplication.applicationDataObject.additionalContacts
            },
            additionalComments : loadedApplication.applicationDataObject.additionalComments,
            additionalInformation: loadedApplication.applicationDataObject.additionalInformation,
            paAgreementExtra: loadedApplication.applicationDataObject.paAgreementExtra,
            approvalCodeAndFees: {
                approvalCode: loadedApplication.applicationDataObject.approvalCode,
                fees: loadedApplication.applicationDataObject.fees
            },
            // if we are resubmitting then we want to show the user the form
            currentStep: resubmit ? ApplicationStep.GeneratorFacilityInformation : loadedApplication.lastStepCompleted,
            // if the step is contact information, next should only be disabled if contacts aren't complete
            // if it is not then we will only disable next if any of the form accordions are incomplete
            nextDisabled: loadedApplication.lastStepCompleted == ApplicationStep.ContactInformation
                ? !loadedApplication.applicationDataObject.contactsComplete
                : !loadedApplication.applicationDataObject.equipmentComplete ||
                !loadedApplication.applicationDataObject.attachmentsComplete ||
                !loadedApplication.applicationDataObject.systemComplete,
            steps: steps ?? [],
            processing: false,
            system: {
                sourceSystem: sanitizeIdentifierCapitalization(loadedApplication.applicationDataObject?.sourceSystem),
                existingSystem: sanitizeIdentifierCapitalization(loadedApplication.applicationDataObject?.existingSystem),
            },
            systemComplete: loadedApplication.applicationDataObject.systemComplete,
            equipmentComplete: loadedApplication.applicationDataObject.equipmentComplete,
            attachmentsComplete: loadedApplication.applicationDataObject.attachmentsComplete,
            contactsComplete : loadedApplication.applicationDataObject.contactsComplete
        }

        //Convert "Identifier" from api to "identifier" on dropdown fields
        function sanitizeIdentifierCapitalization(apiObject: any): any {
            for (const prop in apiObject) {
                if (apiObject[prop]?.Identifier !== undefined) {
                    apiObject[prop].identifier = apiObject[prop].Identifier;
                    delete apiObject[prop].Identifier;
                }
            }
            return apiObject;
        }
    }

    static getStatusDisplay(status : ApplicationStatus, hasUnpaidInvoices ?: boolean) : string {
        switch (status) {
            case ApplicationStatus.PendingAccountInfo     : return "Pending - No Account Provided";
            case ApplicationStatus.PendingAccountCreation : return "Pending - Customer Information Received";
            case ApplicationStatus.UpgradeRequired        : return "Conditional Approval: Upgrade Required";
            case ApplicationStatus.Rejected               : return "Rejected";
            case ApplicationStatus.PendingCustomer        : return "Updates Required";
            case ApplicationStatus.AwaitingFinalDocuments : return "Preliminary Approval: Awaiting Final Documents";
            case ApplicationStatus.Metering               : return "Meter Exchange Initiated";
            case ApplicationStatus.PermissionToOperate    : return "Permission to Operate";
            case ApplicationStatus.ReviewFinalDocuments   : return "Project Under Final Review";
            case ApplicationStatus.WithdrawnByCustomer    : return "Withdrawn";
            case ApplicationStatus.Draft                  : return "Draft";
            case ApplicationStatus.Submitted:
            case ApplicationStatus.AwaitingAdditionalFee:
            case ApplicationStatus.EngineerReview:
            case ApplicationStatus.InitialReview:
            case ApplicationStatus.PendingPucSubmission:
            case ApplicationStatus.SubmittedToPuc:
                // when the status is submitted we want to show different things based on if there are
                // unpaid invoices or not; however, there are times when we don't know if there are unpaid invoices
                // and it is challenging to check. In those instances (when hasUnpaidInvoices == null), we
                // will default to showing "Submitted"
                if (status == ApplicationStatus.Submitted) {
                    if (hasUnpaidInvoices) {
                        return "Submitted - Fee Required"
                    } else if (hasUnpaidInvoices == null) {
                        return "Submitted"
                    }
                }

                // when the status is awaiting additional fee we want to show different things based on if there are
                // unpaid invoices or not; however, there are times when we don't know if there are unpaid invoices
                // and it is challenging to check. In those instances (when hasUnpaidInvoices == null), we
                // will default to showing "Awaiting Additional Fee"
                if (status == ApplicationStatus.AwaitingAdditionalFee) {
                    if (hasUnpaidInvoices) {
                        return "Additional Fees Required"
                    } else if (hasUnpaidInvoices == null) {
                        return "Awaiting Additional Fee"
                    }
                }

                return "Submitted - Under Review";
            default: return "N/A"
        }
    }

    /** Produce form data with files to supply to attachments upload API */
    static buildUploadFileFormData(files : AttachmentFormData['files'], applicationId : string) {
        const formData = new FormData();
        const fileManifest: {[index: string]: string} = {};
        files.forEach((field) => {
            formData.append("files", field.file);
            fileManifest[field.file.name] = field.type; //store the doc type of each file in the manifest
        })
        formData.append("applicationId", applicationId);
        formData.append("fileManifest", JSON.stringify(fileManifest));
        return formData;
    }

    static getUploadFailedMessage(status: Status, fileName: string, allowedExtensionsString: string) {
        switch (status) {
            case Status.SUCCESS: return null;
            case Status.FAILED: return `Failed to upload file ${fileName}.`;
            case Status.ALREADY_EXISTS: return `File ${fileName} has already been uploaded. If you need to attach another document, please change the name of the file or append a version number.`;
            case Status.INVALID_EXTENSION: return `File ${fileName} has an invalid extension.  Supported extensions are: ${allowedExtensionsString}.  Upload failed.`;
            case Status.INVALID_SIZE: return `File ${fileName} is too large.  Maximum size is 100MB.  Upload failed.`;
            case Status.INVALID_EXTENSION_AND_SIZE: return `File ${fileName} has an invalid extension and is too large.  Supported extensions are: ${allowedExtensionsString}.  Maximum size is 100MB.  Upload failed.`;
            case Status.RESERVED_FILENAME: return `File ${fileName} has an invalid filename. Please remove the \'!!!\' prefix.`;
            default: return `File ${fileName} failed to upload.`;
        }
    }
}
