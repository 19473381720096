import React, { ReactElement } from 'react';
import styled from 'styled-components';

const GreyBannerWrapper = styled.div<{noMargin ?: boolean, marginTop ?: boolean}>`
  background-color: #F5F5F5;
  break-inside: avoid;
  break-after: avoid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem; 
  font-weight: bold;
  font-size: 1.125rem;
  border: 1px solid #D2D2D2;
  border-bottom: 2px solid #0060A9;
  border-radius: 6px 6px 0 0;
  margin-bottom: ${props => props.noMargin ? '0' : '1rem'};
  margin-top: ${props => props.marginTop ? '1.5rem' : '0'};
  
`

type GreyBannerProps = {
    title : string,
    button ?: ReactElement,
    noMargin ?: boolean,
    marginTop ?: boolean
}
export default function GreyBanner(props : GreyBannerProps) {
    return(
        <GreyBannerWrapper noMargin = {props.noMargin} marginTop={props.marginTop}>{props.title}{props.button}</GreyBannerWrapper>
    )
}