import React, {ReactElement, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FlexRowContainer} from "../../styledcomponents/MiscStyledComponents";

export const CollapsibleContainer = styled.div`
    border: 1px solid #D2D2D2;
    border-radius: 6px;
    & div.wrapper:first-child {
      border-top: none;
    }
`

const GreyBackground = styled.div`
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
`

const PanelCard = styled.div`
    border: 1px solid #D2D2D2;
    border-radius: 6px;
    background-color: white;
    margin: 1rem 1rem;
    width: 100%;
    padding: 1rem;
`

const Header = styled.button<{disabled ?: boolean}>`
    font-weight: 700;
    color: ${props => props.disabled ? '#D2D2D2' : '#00477D'};
    padding: .75rem 1rem;
    cursor: ${props => props.disabled ? 'initial' : 'pointer'};
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
	border: inherit;
    width:100%;
`

const CollapsibleWrapper = styled.div`
    border-top: 1px solid #d2d2d2;
`

const ChildrenDivWrapper = styled.div`
    border-top: 1px solid #d2d2d2;
`

const OpenCloseIcon = styled.div`
    font-size: 1.5rem;
`

type CollapsibleProps = {
    title : ReactElement | string,
    children : ReactElement,
    button ?: ReactElement
    disabled ?: boolean
    forceClose ?: boolean
    forceOpen ?: boolean
    preventAutoscroll ?: boolean
    //if true, always scroll when ForceOpen is newly triggered, even if pane is already open
    alwaysAutoscrollOnForceOpen ?: boolean
}

export default function Collapsible(props : CollapsibleProps) {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const childrenDivRef = useRef<HTMLDivElement>(null);
    
    React.useEffect(() => {
        if (props.forceClose) { setIsOpen(false); }

        if (props.forceOpen) { 
            if (props.alwaysAutoscrollOnForceOpen && childrenDivRef.current) {
                //ensure that the autoscroll triggers even if already open
                childrenDivRef.current.scrollIntoView({behavior: 'smooth'})
            }
            setIsOpen(true); 
            
        }
    }, [props.forceClose, props.forceOpen])


    useEffect(() => {
        if (childrenDivRef.current && isOpen && !props.preventAutoscroll) {
            childrenDivRef.current.scrollIntoView({behavior: 'smooth'})
        }
    }, [isOpen])

    return (
        <CollapsibleWrapper className={'wrapper'}>
            <Header onClick={() => !props.disabled ? setIsOpen(!isOpen) : null} disabled={props.disabled}>
                {props.title}
                <FlexRowContainer>
                    <div onClick={event => event.stopPropagation()}>
                        {props.button}
                    </div>
                    <OpenCloseIcon>{isOpen ? '-' : '+'}</OpenCloseIcon>
                </FlexRowContainer>
            </Header>
            {
                isOpen &&
                <ChildrenDivWrapper ref={childrenDivRef}>
                    <GreyBackground>
                        <PanelCard>
                            {props.children}
                        </PanelCard>
                    </GreyBackground>
                </ChildrenDivWrapper>
            }
        </CollapsibleWrapper>
    )
}