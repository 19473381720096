import {AxiosResponse} from "axios";

export default class ErrorUtils {
    static UNKNOWN_ERROR = "Something went wrong";

    static UNAUTHORIZED_ERROR = "Invalid username or password. Please try again.";

    static PASSWORD_RESET_ERROR = "Password reset failed.";

    static getValidationErrors(errorResponse : AxiosResponse | undefined) : string[] {
        const errors : string[] = [];
        const errorMap : Map<string, string> = new Map<string, string>(Object.entries(errorResponse?.data));

        errorMap.forEach((value : string) => {
            errors.push(value);
        });

        return errors
    }
}