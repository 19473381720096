import { ChangeEvent, DragEvent, useEffect, useRef, useState } from "react";
import { StyledFileInputLabel, StyledLinkButton } from "../../../styledcomponents/FormStyledComponents";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";
import { AttachmentResponse, AttachmentType } from "../../../models/AttachmentResponse";
import { AxiosContextTypes } from "../../../AxiosProvider";
import { useMessageService } from "../../../hooks/useMessageService";
import { GreenCheckmark } from "../../../styledcomponents/MiscStyledComponents";
import styled from "styled-components";
import staticAxios from "axios" ;
import { getBackendHost } from "../../../utils/externalUrl";

type UploadSpecificDocumentBoxProps =  {
    docType : AttachmentType
    extension : string
    applicationId ?: string
    urlJwt ?: string // if the user is validated through a URL token, use this instead of applicationId
    alreadyUploadedMessage?: string
    allowReplace?: boolean //true if the user can replace an already uploaded file with a new one
    setValid: (isValid: boolean) => void
    axios ?: AxiosContextTypes // if the user needs to be logged in, supply the axios from useAxios. If using urlJwt, this can be null.
}

/** This component allows the user to upload one specific document - if they want to re-upload, it will replace the existing document. */
export function UploadSpecificDocumentBox(props : Readonly<UploadSpecificDocumentBoxProps>) {
    const [uploadedFileName, setUploadedFileName] = useState<string>('');
    const [alreadyHasFile, setAlreadyHasFile] = useState<boolean | undefined>(undefined);
    const [loadingMessage, setLoadingMessage] = useState<string|null>(null);
    const [draggingStyle, setDraggingStyle] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const messageService = useMessageService();

    useEffect(() => {
        if (alreadyHasFile === undefined && props.axios) { //on page load, check if the user has already uploaded this doc type
            setLoadingMessage('Loading...');
            props.axios?.secureApi.get('api/attachments/' + props.applicationId).then(response => {
                let attachmentResponse: AttachmentResponse = response.data;
                let hasFile = attachmentResponse.attachmentsStatus.map(s => s.type).includes(props.docType);
                setAlreadyHasFile(hasFile);
                props.setValid(hasFile);
            }).finally(() => {
                setLoadingMessage(null);
            });
        }
    }, [props.docType])

    function onFileDrop(event: DragEvent<HTMLLabelElement>): void {
        const file = event.dataTransfer?.items?.[0]?.getAsFile();
        if (file) {
            onFileUpload(file);
        }
        event.preventDefault();
    }

    function onDragOver(event: DragEvent<HTMLLabelElement>): void {
        event.preventDefault();
        event.stopPropagation();
    }
    async function onFileUpload(file: File) {
        messageService.clearAll();
        if (file) {
            let uploadPromise = null;
            setLoadingMessage('Uploading file...');
            if (props.applicationId) {
                const formData = ApplicationFormUtils.buildUploadFileFormData([{
                    name: file.name,
                    type: props.docType,
                    file: file
                }], props.applicationId);
                
                uploadPromise = props.axios?.secureApi.post('/api/attachments/upload', formData);
            } else if (props.urlJwt) {
                const formData = new FormData();
                formData.append("files", file);
                uploadPromise = staticAxios.request({
                    baseURL: getBackendHost(),
                    headers: {'Authorization': 'Bearer ' + props.urlJwt},
                    method: "POST",
                    data: formData,
                    url: `/api/agreement/uploadSignature`
                });
            } else {
                throw "UploadSpecificDocumentBox must have either urlJwt or applicationId.";
            }
            await uploadPromise!.then(response => {
                let attachmentResponse: AttachmentResponse = response.data;
                if (attachmentResponse.errorMessage) {
                    messageService.error(`Failed to upload file.`);
                    return;
                }
                const message = ApplicationFormUtils
                    .getUploadFailedMessage(attachmentResponse.attachmentsStatus[0].status, file.name, props.extension)
                if (message) {
                    messageService.error(message);
                    return;
                }
                setUploadedFileName(file.name);
                props.setValid(true);
            }).catch(e => {
                messageService.error(`File failed to upload.`);
            })

            setLoadingMessage(null);
        }
    }

    async function onFileInputChange(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target?.files?.[0];
        if (file) {
            onFileUpload(file);
        }
    }

    /** Allows user to upload a different file and overwrite the existing one.  */
    function resetFile() {
        setAlreadyHasFile(false);
        setUploadedFileName('');
        if (fileInputRef.current) {
            //Clear input value
            fileInputRef.current.value = '';
        }
        setTimeout(() => fileInputRef.current?.click(), 50)
    }


    if (loadingMessage) {
        return <UploadStatusDisplay>
            <span><b>{loadingMessage}</b></span>
        </UploadStatusDisplay>
    } else if(uploadedFileName || alreadyHasFile) {
        return <UploadStatusDisplay >
            <GreenCheckmark size={18} style={{}}/>
            { uploadedFileName ? <span>Successfully uploaded <b>{uploadedFileName}</b>. </span> 
                : <span>{`You have already provided ${props.alreadyUploadedMessage ?? 'this file'}.`} </span>}
            {props.allowReplace && <StyledLinkButton onClick={() => resetFile()}>Replace File</StyledLinkButton>}
        </UploadStatusDisplay>
    } else {
        return <StyledFileInputLabel onDrop={onFileDrop} onDragOver={onDragOver} onDragEnter={() => setDraggingStyle(true)} onDragLeave={() => setDraggingStyle(false)}
                dragging={draggingStyle}>
            <input type="file" ref={fileInputRef} onChange={onFileInputChange} accept={'.' + props.extension}></input>
            {draggingStyle ? 'Drop File To Upload' : 'Choose File To Upload'}
        </StyledFileInputLabel>
    }
}

const UploadStatusDisplay = styled.div`
    svg {
        padding-top: 0.2em;
        vertical-align: middle;
    }
    span {
        vertical-align: middle;
        margin-right: 0.5em;
    }
    button {
        font-weight: bold;
        text-decoration: none;
        font-size: medium;
        vertical-align: middle;
    }
`
