import {useAxios} from "../../../../AxiosProvider";
import {useMessageService} from "../../../../hooks/useMessageService";
import React, {useEffect, useState} from "react";
import {InterconnectApplicantStep} from "../../helpers/InterconnectApplicantStepIcons";
import {AxiosError, AxiosResponse} from "axios";
import ErrorUtils from "../../../../utils/errorUtils";
import {Label} from "../../../../styledcomponents/FormStyledComponents";
import GreyBanner from "../../../misc/GreyBanner";
import {
    Divider,
    GridColumnContainer
} from "../../../../styledcomponents/MiscStyledComponents";
import {InterconnectApplicantButtons} from "./InterconnectApplicantButtons";
import {InterconnectApplicantStepProps} from "./InterconnectApplicantForm";

type ReviewAndSubmitProps = InterconnectApplicantStepProps & {
    applicationId : string
}

export default function ReviewAndSubmit(props : ReviewAndSubmitProps) {
    const messageService = useMessageService();
    const axios = useAxios();

    // Form state handlers
    const [processing, setProcessing] = useState<boolean>(false);
    const [serviceAddressDisplay, setServiceAddressDisplay] = useState<string>("");
    const [mailingAddressDisplay, setMailingAddressDisplay] = useState<string>("");

    useEffect(() => {
        axios?.openApi.put(
            `/api/noAccount/application/getServiceAddressDisplay`,
            {...props.interconnectApplicantForm?.serviceAddress}
        ).then(response => {
            setServiceAddressDisplay(response.data)
        }).catch(e => {
           console.error("unable to get service address display")
        })

        axios?.openApi.put(
            `/api/noAccount/application/getMailingAddressDisplay`,
            {...props.interconnectApplicantForm?.mailingAddress}
        ).then(response => {
            setMailingAddressDisplay(response.data)
        }).catch(e => {
            console.error("unable to get mailing address display")
        })
    }, [])

    function onNext() {
        // clear any messages once we submit
        messageService.clearAll();

        setProcessing(true)

        axios?.openApi.post(
            '/api/noAccount/application/completeInterconnectApplicant',
            {
                ...props.interconnectApplicantForm,
                applicationId : props.applicationId
            }
        ).then((response : AxiosResponse) => {
            window.location.reload();
        }).catch((err : AxiosError) => {
            let errorResponse : AxiosResponse | undefined = err.response;

            if (errorResponse?.status === 400) {
                messageService.error(ErrorUtils.getValidationErrors(errorResponse))
            } else {
                messageService.error(ErrorUtils.UNKNOWN_ERROR);
            }
        }).finally(() => {
            setProcessing(false)
        })
    }
    const firstName = props.interconnectApplicantForm?.firstName;

    return (
        <React.Fragment>
            <p>Please verify the information you've entered is correct</p>

            <GreyBanner title={"New Applicant Information"} />
            <GridColumnContainer columns={2}>
                <Label htmlFor={"fullName"}>{firstName ? 'Full Name' : 'Business Name' }</Label>
                <div>{`${firstName || props.interconnectApplicantForm?.businessName} ${props.interconnectApplicantForm?.lastName}`}</div>

                <Label htmlFor={"primaryPhone"}>Primary Phone Number</Label>
                <div>{props.interconnectApplicantForm?.primaryPhone}</div>

                <Label htmlFor={"alternatePhone"}>Alternate Phone Number</Label>
                <div>{props.interconnectApplicantForm?.alternatePhone}</div>

                <Label htmlFor={"mailingAddress"}>Mailing Address</Label>
                <div dangerouslySetInnerHTML={{ __html: mailingAddressDisplay ?? '' }} />

                <Label htmlFor={"serviceAddress"}>Service Address</Label>
                <div dangerouslySetInnerHTML={{ __html: serviceAddressDisplay ?? '' }} />
            </GridColumnContainer>

            <br />

            <GreyBanner title={"Connection Information"} />
            <GridColumnContainer columns={2}>
                <Label htmlFor={"propertyType"}>Property Type</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.propertyType?.description}</div>

                <Label htmlFor={"squareFootage"}>Square Footage</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.squareFootage}</div>

                <Label htmlFor={"crossStreet"}>Cross Street</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.crossStreet}</div>

                <Label htmlFor={"connectionType"}>Connection Type</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.connectionType?.description}</div>

                <Label htmlFor={"setback"}>Setback (in Feet)</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.setback}</div>

                <Label htmlFor={"voltage"}>Voltage</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.voltage?.description}</div>

                <Label htmlFor={"amps"}>Amps</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.amps?.description}</div>

                <Label htmlFor={"phase"}>Phase</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.phase?.description}</div>

                <Label htmlFor={"remarks"}>Remarks</Label>
                <div>{props.interconnectApplicantForm?.connectionInformation?.remarks}</div>
            </GridColumnContainer>
            <Divider />
            <InterconnectApplicantButtons step={InterconnectApplicantStep.ReviewAndSubmit}
                                          {...props}
                                          onNext={onNext}
                                          processing={processing}
            />
        </React.Fragment>
    )

}