import { DependencyList, EffectCallback, useEffect, useState } from "react";

const deepCompare = function(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * Similar to useEffect, but only fires if the dependency list is a different value (compared via JSON.stringify instead of Object.is)
 * Does not run on initial mount by default.
 */
export function useEffectDeepCompare(effect: EffectCallback, deps: DependencyList, runOnMount?: boolean) {
    const [previousState, setPreviousState] = useState<DependencyList|null>(null);

    useEffect(() => {
        if (!deepCompare(previousState, deps)) { //check that the current deps don't match the previous value
            setPreviousState(deps);
            if (previousState || runOnMount) { //check that either the previous value was not empty or we have runOnMount enabled
                return effect();
            }
            
        }
    }, deps)
}