import { ApplicationStatus } from "../../enums/ApplicationStatus";
import { GridColumnContainer, PageTitle, majorGap } from "../../styledcomponents/MiscStyledComponents";
import { NoPageBreak } from "../../utils/pdfExportUtils";
import { ApplicationForm } from "../application/Application";
import { AccountInfoBox } from "../application/helpers/AccountInfoBox";
import { ApplicationSummaryContent } from "../application/helpers/ApplicationSummaryContent";
import { UploadedDocumentsTable } from "../application/helpers/UploadedDocumentsTable";
import { ViewContactInformation } from "../application/helpers/ViewContactInformation";
import { ApplicationFeesDisplay } from "../application/steps/ApplicationFeesReview";
import GreyBanner from "./GreyBanner";

/** Renders a hidden application summary to be used by pdfExportUtils.ts to create a PDF summary */
export function PrintableSummary(props: {application: ApplicationForm | null}) {

    return <><div style={{display: "none"}}><div id = 'print' >
        <PageTitle>Interconnection Application</PageTitle>
        <AccountInfoBox account={props.application?.account} interconnectApplicant={props.application?.interconnectApplicant} />
        <ApplicationSummaryContent application={props.application!} canEdit={false} />
        {
                        props.application?.status != ApplicationStatus.PendingCustomer &&
                        <NoPageBreak>
                            <GreyBanner title={"Application Level and Fees"} marginTop={true} />
                            <p>
                                Please note this is a preliminary application level based on the information that you provided in your application and subject to change upon reviewing by FirstEnergy.
                            </p>
                            <GridColumnContainer columns={2} gap={majorGap}>
                                <ApplicationFeesDisplay application={props.application} />
                            </GridColumnContainer>
                        </NoPageBreak>
                    }
                    <NoPageBreak>
                        <div style={{height:"1em"}}/>
                        <div>
                            <UploadedDocumentsTable applicationId={props.application?.id!} uploadAllowed={false} deleteAllowed={false}/>
                        </div>
                    </NoPageBreak>
                    {
                        props.application?.contactInformationContainer?.contactInformation &&
                        <NoPageBreak>
                            <div style={{height:"0.5em"}}/>
                            <GreyBanner title={"Contact Information"} marginTop={true}/>
                            <ViewContactInformation contactInformation={props.application?.contactInformationContainer?.contactInformation} />
                        </NoPageBreak>
                    }
        </div>
    </div>
    </>
}