import React, {useEffect, useState} from 'react';
import {useAxios} from "../AxiosProvider";
import {AxiosError, AxiosResponse} from "axios";
import { useForm, SubmitHandler, FormProvider  } from "react-hook-form";
import {ConfirmPasswordInput, Input, PasswordInput} from "./form/FormElements";
import {useMessageService} from "../hooks/useMessageService";
import {Form, Label, PrimaryButton} from "../styledcomponents/FormStyledComponents";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";
import {Page, usePath} from "../PathProvider";
import ErrorUtils from "../utils/errorUtils";

type ResetPasswordFormData = {
    username: string,
    password: string,
    newPassword : string,
    confirmNewPassword : string
};

function ForcedResetPassword() {
    // Hooks
    const navigate = useNavigate();
    const axios = useAxios();
    const messageService = useMessageService();
    const auth = useAuth();
    const path = usePath();

    // useForm hooks
    const methods = useForm<ResetPasswordFormData>({
        defaultValues: {
            username : auth.user?.username,
            password: '',
            newPassword : '',
            confirmNewPassword : ''
        }
    });
    const {handleSubmit, getValues, formState : {isSubmitting, isDirty, errors}} = methods;

    // Form state handlers
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

    // useEffects
    useEffect(() => {
        setSubmitDisabled(isSubmitting || !isDirty);
    }, [isSubmitting, isDirty]);

    // Functions
    const onSubmit : SubmitHandler<ResetPasswordFormData> = async (data : ResetPasswordFormData) => {
        // clear any messages once we submit
        messageService.clearAll();

        await axios?.openApi.put(
            '/api/forcedResetPassword',
            {
                username: data.username,
                password: data.password,
                newPassword : data.newPassword,
                confirmNewPassword : data.confirmNewPassword
            }
        ).then((response : AxiosResponse) => {
            
            if(auth.user){
                auth.logout('Password reset successfully. Please log in with your new password.');
            } else {
                navigate(path.get(Page.Login), { state : { successMessage : 'Password reset successfully. Please log in with your new password.' }});
            }
        }).catch((err : AxiosError) => {
            let errorResponse : AxiosResponse | undefined = err.response;

            if (errorResponse?.status === 400) {
                messageService.error(ErrorUtils.getValidationErrors(errorResponse))
            } else if (errorResponse?.status === 409 ) {
                messageService.error(ErrorUtils.PASSWORD_RESET_ERROR)
            } else {
                messageService.error(ErrorUtils.UNKNOWN_ERROR);
            }
        });
    };

    return(
        <FormProvider {...methods} >
            <Form onSubmit={handleSubmit(onSubmit)}>
                { (!auth.user) &&
                <React.Fragment>
                    <Label htmlFor={"username"}>Username</Label>
                    <Input name={"username"} />
                </React.Fragment>
                }
                <Label htmlFor={"password"}>Password</Label>
                <Input type="password" name={"password"}/>

                <Label htmlFor={"newPassword"}>New Password</Label>
                <PasswordInput name={"newPassword"} required={true} error={errors.newPassword} showDetail/>

                <Label htmlFor={"confirmNewPassword"}>Confirm New Password</Label>
                <ConfirmPasswordInput name={"confirmNewPassword"} password={getValues("newPassword")} required={true} error={errors.confirmNewPassword} />

                <PrimaryButton type={"submit"} disabled={submitDisabled}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </PrimaryButton>
            </Form>
        </FormProvider>
    )
}

export default ForcedResetPassword;