import React, {useEffect, useState} from "react";
import {BaseApplicationStepProps, BaseChildFormStepProps, BaseFormDataProps, PaAgreementExtraData} from "../Application";
import {Controller, FieldError, FieldErrorsImpl, FormProvider, Merge, SubmitHandler, useForm, useWatch} from "react-hook-form";
import {Form, InsideCollapsibleHeader, Label, SmallPrimaryButton, StyledDatePicker2, StyledOption} from "../../../styledcomponents/FormStyledComponents";
import {useMessageService} from "../../../hooks/useMessageService";
import {FormActionType} from "../helpers/applicationFormReducer";
import {useAxios} from "../../../AxiosProvider";
import {ErrorP, FlexRowContainer, GridColumnContainer, PageSubTitle, minorGap} from "../../../styledcomponents/MiscStyledComponents";
import {CheckboxInput, ControlledPhoneInput, HoverSpan, Input, LabelWithTooltip, RadioInput, Select, ServerLoadedSelect, SimpleRadio, TooltipValueInput} from "../../form/FormElements";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";
import {AxiosError, AxiosResponse} from "axios";
import {useAuth} from "../../../auth/AuthProvider";
import {State} from "../../../enums/State";
import {Pattern} from "../../../enums/Pattern";
import GreyBanner from "../../misc/GreyBanner";
import { TAG_BATTERY_ONLY, TAG_SOLAR_PV } from "../../../utils/constants";
import { useEffectDeepCompare } from "../../../hooks/useEffectDeepCompare";
import { ContactInformationForm } from "../steps/ContactInformation";



export const labelsAndTooltips = {
    ampRating: {label: "Inverter Amp Rating (Amps)"},
    nearestCrossingStreet: {label: "Nearest Crossing Street/GPS", tooltip: "Enter either the name of the nearest crossing street, or GPS coordinates."},
    serviceVoltage: {label: "Existing Service Voltage"},
    serviceCapacityAmps: {label: "Existing Service Capacity (Amps)"},
    servicePhase: {label: "Existing Service Phase"},

}

const naString = 'N/A';
const installByOwnerString = 'BY OWNER';


export function PaAgreementExtraForm(props : BaseApplicationStepProps & BaseChildFormStepProps) {
    const messageService = useMessageService();
    const axios = useAxios();
    const auth = useAuth();
    const electricalContractorName = props.application?.paAgreementExtra?.electricalContractor?.name;
    const equipContractorName = props.application?.paAgreementExtra?.equipInstallContractor?.name;
    const consultingEngineerName = props.application?.paAgreementExtra?.consultingEngineer?.name;
    const level234 = Boolean(props.application?.additionalInformation?.applicationLevel?.match(/[2-4]/));
    const [meterNumbers, setMeterNumbers] = useState<string[]>([]);
    const hasInverter = props.application?.system?.sourceSystem.generationEquipment.identifier === '01';
    

    const methods = useForm<PaAgreementExtraData>({
        defaultValues : {
                disconnectLockbox : props.application?.paAgreementExtra?.disconnectLockbox ?? '',
                nearestCrossingStreet : props.application?.paAgreementExtra?.nearestCrossingStreet ?? '',
                ampRating : props.application?.paAgreementExtra?.ampRating ?? '',
                inverterType: props.application?.paAgreementExtra?.inverterType ?? '',
                equipInstallContractor: props.application?.paAgreementExtra?.equipInstallContractor || {},
                electricalContractor : props.application?.paAgreementExtra?.electricalContractor || {},
                consultingEngineer : props.application?.paAgreementExtra?.consultingEngineer || {},
                serviceVoltage : props.application?.paAgreementExtra?.serviceVoltage ?? '',
                serviceCapacityAmps : props.application?.paAgreementExtra?.serviceCapacityAmps ?? '',
                servicePhase : props.application?.paAgreementExtra?.servicePhase ?? '',
                meterNumber : props.application?.paAgreementExtra?.meterNumber ?? '',
                
                hasEquipContractor : auth.user?.isVendor || (equipContractorName ? equipContractorName != installByOwnerString : undefined),
                hasElectricContractor :  electricalContractorName ? electricalContractorName != naString : undefined,
                hasConsultingEngineer :  consultingEngineerName ? consultingEngineerName != naString : undefined,
        },
        mode: "onChange"
    });
    const {handleSubmit, getValues, resetField, reset, setValue, control, formState : {isSubmitting, isValid, errors, isDirty}} = methods;
    const watch = useWatch({control: control});
    
    let watchInfo = useWatch({control : control});
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    // useEffects
    useEffect(() => {
        if (props.triggerFlag) {
            methods.trigger();
        }
    }, [props.triggerFlag]);

    useEffect(() => {
        props.setValid(isValid);
    }, [isValid]);

    useEffect(() => {
        if (auth.user?.isVendor) {
            prepopulateVendor();
        }
    }, [auth.user?.vendor])

    
    useEffect(() => {loadMeterNumbers()}, [props.application?.id]);

    /** Load meter numbers for account #, if there is exactly one, go ahead and set it to that */
    async function loadMeterNumbers() {
        // const meterNumbers : string[]= ['545456'];//(await axios?.secureApi(`api/application/meterNumbers/${props.application?.id}`))?.data;
        const meterNumbers = (await axios?.secureApi(`api/application/meterNumbers/${props.application?.id}`))?.data;
        setMeterNumbers(meterNumbers);
        if (meterNumbers?.length === 1) {
            setValue("meterNumber", meterNumbers[0]);
        }
    }
    
    //common logic to reset contact form based on Yes/No radio button
    function resetContactLogic(fieldName : ("electricalContractor" | "equipInstallContractor" | "consultingEngineer"), radioValue : (boolean|undefined), nameIfNa : string) {
        if (radioValue) {
            if (getValues(`${fieldName}.name`) == nameIfNa) {
                resetField(fieldName, {defaultValue: {name: ''}});
            }
        } else if (radioValue === false) {
            resetField(fieldName, {defaultValue: {name: ''}});
            setValue(`${fieldName}.name`, nameIfNa); //resetvalue doesn't set this correctly for some reason...
        }
    }

    useEffect(() => {
        resetContactLogic("electricalContractor", watch.hasElectricContractor, naString);
    }, [watch.hasElectricContractor])
    useEffect(() => {
        resetContactLogic("equipInstallContractor", watch.hasEquipContractor, installByOwnerString);
    }, [watch.hasEquipContractor])
    useEffect(() => {
        resetContactLogic("consultingEngineer", watch.hasConsultingEngineer, naString);
    }, [watch.hasConsultingEngineer])

    useEffect(() => {
        if (!hasInverter) {
            setValue('ampRating', '');
            setValue('inverterType',  '');
        }
    }, [hasInverter])

    useEffectDeepCompare(() => {
        const debounceTimer = setTimeout(() => {
            props.applicationFormsDispatch(
                {
                    type: FormActionType.UPDATE_PA_EXTRA,
                    paAgreementExtra : {
                        equipInstallContractor: props.application?.paAgreementExtra?.equipInstallContractor,
                        electricalContractor: props.application?.paAgreementExtra?.electricalContractor,
                        ...getValues(),
                    }
                }
            );
        }, 250);
        return () => clearTimeout(debounceTimer);
    }, [watchInfo]);

    
   

    const prepopulateVendor = function() {
        if (!props.application?.paAgreementExtra?.electricalContractor?.name) {
            setValue("equipInstallContractor", {
                name: auth.user?.vendor.name,
                phoneNumber: auth.user?.vendor.phoneNumber,
                email: auth.user?.vendor.email,
                address: auth.user?.vendor.streetAddress,
                city: auth.user?.vendor.city,
                state: auth.user?.vendor.state,
                zipCode: auth.user?.vendor.zipCode,
            })
        }
    }
    

    const level234fields = <>
        <TooltipValueInput name={"serviceVoltage"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.serviceVoltage} errorParent={errors} required/>
        <TooltipValueInput name={"serviceCapacityAmps"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.serviceCapacityAmps} errorParent={errors} required/>
        <div>
            <Label htmlFor={`servicePhase`}>Existing Service Phase</Label>
            <Select name={`servicePhase`} required error={errors.servicePhase}>
                    <StyledOption key={''} value={''}>Select One</StyledOption>
                    {
                        [
                            'Single Phase',
                            'Three Phase',
                        ].map(state => <StyledOption key={state}>{state}</StyledOption>)
                    }
            </Select>
        </div>
    </>

    return (
        <FormProvider {...methods}>
            <Form>
                <p>To comply with Pennsylvania state application requirements, we need to collect some additional information.</p>
                <GridColumnContainer columns={2} gap={minorGap}>
                {hasInverter && <TooltipValueInput name={"ampRating"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.ampRating} errorParent={errors} required/>}
                <TooltipValueInput name={"nearestCrossingStreet"} maxLength={50} pattern={Pattern.Any} {...labelsAndTooltips.nearestCrossingStreet} errorParent={errors} required/>
                {hasInverter && <div>
                <Label htmlFor={`inverterType`}>Inverter Type</Label>
                <Select name={`inverterType`} required error={errors.inverterType}>
                        <StyledOption key={''} value={''}>Select One</StyledOption>
                        {
                            [
                                'Grid Interactive',
                                'Grid Interactive with Battery Back-up',
                                'Grid Interactive with Standalone Capability',
                            ].map(state => <StyledOption key={state}>{state}</StyledOption>)
                        }
                </Select>
                </div>}
                <div>
                <Label htmlFor={`disconnectLockbox`}>Disconnect/Lockbox Type</Label>
                <Select name={`disconnectLockbox`} required error={errors.disconnectLockbox}>
                        <StyledOption key={''} value={''}>Select One</StyledOption>
                        {
                            [
                                'Disconnect - Next to Meter',
                                'Disconnect - Utility Approved Alternate Location',
                                'Lock Box - Next to Meter',
                                'Not Required per EDC',
                            ].map(state => <StyledOption key={state}>{state}</StyledOption>)
                        }
                </Select>
                </div>
                {level234 && level234fields}
                {meterNumbers?.length > 1 && <div>
                    <Label htmlFor={`meterNumber`}>Meter Number</Label>
                    <Select name={`meterNumber`} required error={errors.meterNumber}>
                            <StyledOption key={''} value={''}>Select One</StyledOption>
                            {meterNumbers.map(state => <StyledOption key={state}>{state}</StyledOption>)}
                    </Select>
                </div>}
                </GridColumnContainer>
                <GreyBanner title={"Equipment Installation Contractor"}/>
                {auth.user?.isVendor || <SimpleRadio question={"Is there an installation contractor for this project?"} fieldName={"hasEquipContractor"} choices={[{display: 'Yes', value: true}, {display: 'No (Owner Install)', value: false}]}/>}
                { getValues("hasEquipContractor") && <>
                    <ContactCardForm name={"equipInstallContractor"} errors={errors.equipInstallContractor} ></ContactCardForm>
                </>}
                
                <GreyBanner title={"Electrical Contractor"}/>
                <SimpleRadio question={"Is there a separate electrical contractor for this project?"} fieldName={"hasElectricContractor"}/>
                { getValues("hasElectricContractor") && <>
                    <ContactCardForm name={"electricalContractor"} errors={errors.electricalContractor} ></ContactCardForm>
                </>}

                {level234 && <>
                <GreyBanner title={"Consulting Engineer"}/>
                <SimpleRadio question={"Is there a consulting engineer for this project?"} fieldName={"hasConsultingEngineer"}/>
                { getValues("hasConsultingEngineer") && <>
                    <ContactCardForm name={"consultingEngineer"} errors={errors.consultingEngineer} ></ContactCardForm>
                </>}
                </>}
                
            </Form>
        </FormProvider>
    )
}

const ContactCardForm = function(props: {name: string, errors?: Merge<FieldError, FieldErrorsImpl<ContactInformationForm>>}) {
    return <>
    
    <GridColumnContainer columns={1}>
            <div>
                <Label htmlFor={`${props.name}.name`} >Name</Label>
                <Input name={`${props.name}.name`} required={true} maxLength={100} error={props.errors?.name}/>
            </div>
        </GridColumnContainer>
        <GridColumnContainer columns={2} gap={minorGap}>
            <div>
                <Label htmlFor={`${props.name}.email`} >Email</Label>
                <Input type={'email'} name={`${props.name}.email`} pattern={Pattern.Email} required={true} maxLength={80} error={props.errors?.email} />
            </div>
            <div>
                <Label htmlFor={`${props.name}.phoneNumber`} >Phone Number</Label>
                <ControlledPhoneInput name={`${props.name}.phoneNumber`} required={true} error={props.errors?.phoneNumber} />
            </div>
            <div>
                <Label htmlFor={`${props.name}.address`} >Address</Label>
                <Input name={`${props.name}.address`} maxLength={80} error={props.errors?.address} />
            </div>
            <div>
                <Label htmlFor={`${props.name}.city`} >City</Label>
                <Input name={`${props.name}.city`} maxLength={50} error={props.errors?.city} />
            </div>
            <div>
                <Label htmlFor={`${props.name}.state`} >State</Label>
                <Select name={`${props.name}.state`} error={props.errors?.state}>
                    <StyledOption key={''} value={''}>Select One</StyledOption>
                    { Object.values(State).map(state => <StyledOption key={state}>{state}</StyledOption>) }
                </Select>
            </div>
            <div>
                <Label htmlFor={`${props.name}.zipCode`}>Zip Code</Label>
                <Input name={`${props.name}.zipCode`} pattern={Pattern.ZipCode} error={props.errors?.state} maxLength={5} enforceMaxLength/>
            </div>
        </GridColumnContainer>
    </>
}
