import {ApplicationStep} from "../../enums/ApplicationStep";
import React from "react";
import styled from "styled-components";
import {FlexColumnContainer, FlexRowContainer} from "../../styledcomponents/MiscStyledComponents";
import {InterconnectApplicantStep} from "../application/helpers/InterconnectApplicantStepIcons";

export const StepIconsWrapper = styled(FlexRowContainer)`
    justify-content: space-between;
`

const StepIconWrapper = styled(FlexColumnContainer)<{hasPointer : boolean}>`
    align-items: center;
    white-space: pre-wrap;
    text-align: center;
    width: 9em;
    cursor: ${props => props.hasPointer ? 'pointer' : 'initial'};
`

const CheckmarkWrapper = styled.svg<{isActive : boolean, isDone : boolean}>`
  color : ${props => props.isActive ? '#49A2E4' : (props.isDone ? '#1A6EAD' : '#D2D2D2')};
  z-index: 0;
`

type StepIconProps<T> = {
    text : string;
    currentStep : T
    step : T
    stepOrder :T[]
    stepIconMap : Map<T, string>
    onClick ?: () => void
}

let DefaultStepIcon = "M20.0839 14.0783L18.326 12.354L19.044 11.6497C19.1761 11.5202 19.3494 11.4554 19.564 11.4554C19.7785 11.4554 19.9519 11.5202 20.0839 11.6497L20.8019 12.354C20.934 12.4835 21 12.6535 21 12.864C21 13.0745 20.934 13.2445 20.8019 13.374L20.0839 14.0783ZM11.9133 20.3683V18.644L17.2613 13.3983L19.0193 15.1226L13.6713 20.3683H11.9133ZM3 15.2683V13.8112H10.4278V15.2683H3ZM3 11.2612V9.80402H14.6369V11.2612H3ZM3 7.25402V5.79688H14.6369V7.25402H3Z"

export function StepIcon(props : StepIconProps<ApplicationStep | InterconnectApplicantStep>) {
    let stepOrder = props.stepOrder;
    let currentStepIndex = stepOrder.indexOf(props.currentStep!);
    let stepIndex = stepOrder.indexOf(props.step);

    if (stepIndex == -1) {
        return <></>
    }

    const diameter = 38;

    return (
        <StepIconWrapper onClick={props.onClick} hasPointer={!!props.onClick}>
            <svg viewBox="0 0 16 16" width="22" height="22" style={{position: "absolute"}} fill="white">
                {/* Backing white circle so that line does not show through check*/}
                <path d="M16 8A8 8 0 1 1 0 8"/>
            </svg>
            <CheckmarkWrapper xmlns="http://www.w3.org/2000/svg" width={diameter} height={diameter} fill="currentColor"
                              isActive={currentStepIndex === stepIndex}
                              isDone={stepIndex < currentStepIndex}
                              className="bi bi-check-circle-fill" viewBox={`0 0 ${diameter} ${diameter}`}>
                <circle cx={diameter / 2} cy={diameter / 2} r={diameter / 2}/>
                <path fill="white" transform="scale(1.2) translate(3.9,3.4)"
                      d={props.stepIconMap.get(props.step) ?? DefaultStepIcon}/>
            </CheckmarkWrapper>
            <b>{props.text}</b>
        </StepIconWrapper>
    )
}