import React from "react";
import Modal from "react-modal";
import {BaseApplicationStepProps, BaseFormDataProps} from "../Application";
import {AttachmentType} from "../../../models/AttachmentResponse";
import {UploadedDocumentsTable} from "../helpers/UploadedDocumentsTable";

export type AttachmentFormData = BaseFormDataProps & {
    files: {
        name: string,
        type: AttachmentType,
        file: File
    }[]
}

type AttachmentFormProps = BaseApplicationStepProps & {
    setLastKnownUploads: (a : AttachmentType[]) => void
    requiredFileTypes: AttachmentType[]
}

Modal.setAppElement('body');

export function AttachmentForm(props : AttachmentFormProps) {
    return (
        <div>
            <UploadedDocumentsTable applicationId={props.application!.id as string} uploadAllowed={true} deleteAllowed={true} requiredFileTypes={props.requiredFileTypes} setLastKnownUploads={props.setLastKnownUploads}/>
        </div>
    )
}