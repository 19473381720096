import React, {useEffect, useState} from "react"
import {BaseApplicationStepProps, BaseFormDataProps,} from "../Application"
import {FormProvider, SubmitHandler, useFieldArray, useForm, useWatch} from "react-hook-form"
import {
    Form,
    FormFooterAction,
    InsideCollapsibleHeader,
    Label,
    SmallPrimaryButton, StyledInput, StyledLinkSecondaryButton,
} from "../../../styledcomponents/FormStyledComponents"
import {useMessageService} from "../../../hooks/useMessageService"
import {FormActionType} from "../helpers/applicationFormReducer"
import {DropdownItem, ErrorP, FlexRowContainer, GridColumnContainer, defaultDropdownItem} from "../../../styledcomponents/MiscStyledComponents"
import {CheckboxInput, HoverSpan, Input, LabelWithTooltip, RadioInput, Select, ServerLoadedSelect,} from "../../form/FormElements"
import styled, {css} from "styled-components"
import {AxiosError, AxiosResponse} from "axios"
import {useAuth} from "../../../auth/AuthProvider"
import {useAxios} from "../../../AxiosProvider"
import ApplicationFormUtils from "../../../utils/applicationFormUtils"
import {Pattern} from "../../../enums/Pattern";
import { getShouldShowDcSource } from "./SystemForm"
import {MessageDisplay} from "../../misc/MessageContainer";
import GreyBanner from "../../misc/GreyBanner"
import { TAG_BATTERY_ONLY, TAG_SOLAR_PV } from "../../../utils/constants"

export interface InverterForm {
  manufacturer: string
  modelNumber: string
  faultContribution: string
  powerFactor: string
  ratedVoltage: string
  ieeeCertification: DropdownItem
  inverterRating: string
  phases: DropdownItem
  incorporatesBatteryStorage: string
  ulCertification: string
  numberOfPoles: string
  synchronousType: DropdownItem
  ansiMotorGroup: string
  inverterQuantity: string
  inverterType: DropdownItem
  isSameAsPrevious:boolean
}

export interface BatteryForm {
    batteryManufacturer: string
    batteryModel: string
    quantityOfBattery: string
    maximumOutput: string
    intentOfStorage: string
    batteryOutputPowerSupply: string
    storageCapacityBattery: string
}

export type EquipmentFormData = BaseFormDataProps & {
    batteries: BatteryForm[]
    inverters: InverterForm[]
    equipmentDetail: InverterForm[]
    synchronousGenerators: InverterForm[]
    inductionGenerators: InverterForm[]
    dcSource?: DcSourceForm
}

export type DcSourceForm = {
    moduleManufacturer: string,
    modelNumber: string,
    moduleCount: string,
    voltageRatings?: string,
    currentRating?: string,
    ratedVoltage?: string,
    ratedCurrent?: string,
    openCircuitVoltage?: string,
    shortCircuitCurrent?: string,
}


const inverterTypes = [
    {
        "identifier": "01",
        "description": "Inverter"
    },
    {
        "identifier": "02",
        "description": "Micro Inverter"
    },
    {
        "identifier": "03",
        "description": "String Inverter"
    }
]

const defaultValues = {
    manufacturer: "",
    modelNumber: "",
    powerFactor: "",
    ratedVoltage: "",
    ieeeCertification: defaultDropdownItem,
    faultContribution: "",
    inverterRating: "",
    phases: defaultDropdownItem,
    incorporatesBatteryStorage: false.toString(),
    ulCertification: "",
    numberOfPoles: "",
    synchronousType: defaultDropdownItem,
    ansiMotorGroup: "",
    batteryManufacturer: "",
    batteryModel: "",
    quantityOfBattery: "",
    maximumOutput: "",
    batteryOutputPowerSupply: "",
    intentOfStorage: "",
    storageCapacityBattery: "",
    inverterQuantity: "",
    inverterType: defaultDropdownItem,
    isSameAsPrevious:false
}

const defaultDcSource: DcSourceForm = {
    moduleManufacturer: '',
    modelNumber: '',
    moduleCount: '',
    voltageRatings: '',
    currentRating: '',
    ratedVoltage: '',
    ratedCurrent: '',
    openCircuitVoltage: '',
    shortCircuitCurrent: '',
}

type EquipmentFormProps = BaseApplicationStepProps & {}

const ButtonContainer = styled.div`
    display: grid;
    gap: 5px;
    margin: 12px 0;
`

const RadioContainer = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 6px;
`

const EquipmentContainer = styled.div`
    max-height: 2500px;
    overflow: auto;
    .form {
        padding: 8px;
    }

    .numbered-form {
        padding: 0.25em;
    }

    .numbered-form-content {
        padding: 0.5em;
    }
    
    .form-header-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
`

const getEquipmentTitle = (generationEquipment = "") => {
    switch (generationEquipment) {
      case "02":
        return "Synchronous Generator"
      case "03":
        return "Induction Generator"
      default:
        return "Inverter"
    }
}

const getFormValues = (props: EquipmentFormProps, generationEquipment = "01"): Array<InverterForm> => {
      let equipmentDetail;
      switch (generationEquipment) {
          case "02":
              equipmentDetail = props.application?.equipment?.synchronousGenerators || []
              break
          case "03":
              equipmentDetail = props.application?.equipment?.inductionGenerators || []
              break
          default:
              equipmentDetail = props.application?.equipment?.inverters || []
      }

      return equipmentDetail.map((item) => ({
          ...item,
          incorporatesBatteryStorage: item.incorporatesBatteryStorage?.toString(),
          ulCertification: item.ulCertification?.toString(),
          inverterType: item.inverterType,
          synchronousType: item.synchronousType
      }))
}

const getEquipmentValue = (formValues: InverterForm[]): InverterForm[] => {
    return formValues;
}

const getBatteryValue = (formValues: BatteryForm[]) : BatteryForm[] => {
    return formValues;
}

export function EquipmentForm(props: EquipmentFormProps) {
    const auth = useAuth();
    const axios = useAxios();
    const messageService = useMessageService();

    const generationEquipment = props?.application?.system?.sourceSystem?.generationEquipment?.identifier || "01"
    const canHaveBatteries = generationEquipment === '01';
    const typeText = getEquipmentTitle(generationEquipment)
    const isBatteryOnly = props.application?.system?.sourceSystem.energySource.tags?.includes(TAG_BATTERY_ONLY);
    const shouldShowDcSource = getShouldShowDcSource(props.application?.system);
    const isArrayDcVoltageRequired = props.application?.account?.region === 'PA';


    let equipmentDetail = [{...defaultValues}]

    const methods = useForm<EquipmentFormData>({
        defaultValues: {
          equipmentDetail
        },
        mode : "onChange"
    })

    const { handleSubmit, control, watch, getValues, setValue, reset, formState: { isSubmitting, isDirty, isValid, errors } } = methods;
    let currentItem = watch("equipmentDetail")
    let watchEquipment = useWatch({control : control});

    const { fields : inverterFields, append : inverterAppend, remove : inverterRemove } = useFieldArray({
        control,
        name: "equipmentDetail",
    })

    const { fields : batteryFields, append : batteryAppend, remove : batteryRemove } = useFieldArray({
        control,
        name: "batteries",
    })

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

    // useEffects
    useEffect(() => {
        setSubmitDisabled(isSubmitting || !isValid);
    }, [isSubmitting, isDirty, isValid])

    useEffect(() => { //dispatch UPDATE_EQUIPMENT (updates model and sets form complete to false) if fields are dirty
        if (isDirty) {
            const debounceTimer = setTimeout(() => {
                const detailForm = getEquipmentValue(getValues().equipmentDetail);
                const batteryForm = getBatteryValue(getValues().batteries);

                const equipment = {
                    equipmentDetail: detailForm,
                    batteries : batteryForm,
                    dcSource: getValues().dcSource,
                    synchronousGenerators: generationEquipment === "02" ? detailForm : [],
                    inductionGenerators: generationEquipment === "03" ? detailForm : [],
                    inverters: generationEquipment === "01" ? detailForm : [],
                }

                props.applicationFormsDispatch({
                    type: FormActionType.UPDATE_EQUIPMENT,
                    equipment,
                })
            }, 500)

            return () => clearTimeout(debounceTimer)
        }
    }, [watchEquipment, isDirty, batteryFields])

    useEffect(() => { //adjust which forms are presented to the user based on generationEquipment and energySource (from System form)
        //choose which type of inverter form to show based on generationEquipment
        const equipmentArray = getFormValues(props, generationEquipment)
        let equipmentFormList = equipmentArray?.length > 0 ? equipmentArray : [{...defaultValues}]

        //if there is a battery saved in the application already, display it
        const batteryArray = props.application?.equipment?.batteries;
        let batteryFormList;
        if (batteryArray) {//if the energysource is battery array, use the inverter form defaults. Otherwise there should not be batteries by default
            if(isBatteryOnly) {
                batteryFormList = batteryArray?.length > 0 ? batteryArray : [{...defaultValues}];
            } else {
                batteryFormList =  batteryArray[0]?.batteryManufacturer ? batteryArray : [];
            }
        }
        //if the energysource is solar, show the DcSource form, prefilled if already in the application data
        let dcSourceForm;
        if (shouldShowDcSource) {
            dcSourceForm = props.application?.equipment?.dcSource ?? defaultDcSource;
        }
        reset({equipmentDetail: equipmentFormList, batteries: batteryFormList, dcSource: dcSourceForm})
    }, [shouldShowDcSource, isBatteryOnly])

    useEffect(() => {
        if (!canHaveBatteries) {
            batteryRemove();
            
        }
    }, [canHaveBatteries])

    const onSubmit: SubmitHandler<EquipmentFormData> = async (data: EquipmentFormData) => {
        messageService.clearAll()
        props.applicationFormsDispatch({type: FormActionType.INCOMPLETE_EQUIPMENT});
        // make call to save current state of application
        await axios?.secureApi.put("/api/application/saveEquipment",
            {...ApplicationFormUtils.buildApplicationFormRequestBody(props.application!, auth.user!),
        })
        .then(() => {
            // if success then exit
            props.applicationFormsDispatch({
                type: FormActionType.COMPLETE_EQUIPMENT,
            })
        })
        .catch((err: AxiosError) => {
            console.error(err)
            messageService.error("Error saving Equipment.")
        })
    }

    const onAddInverter = () => {
        inverterAppend({...defaultValues})
    }

    const onRemoveInverter = (evt: React.MouseEvent<HTMLButtonElement> | undefined, index: number) => {
        evt?.preventDefault()
        inverterRemove(index)
    }

    const onAddBattery = () => {
        batteryAppend({...defaultValues})
    }

    const onRemoveBattery = (evt: React.MouseEvent<HTMLButtonElement> | undefined, index: number) => {
        evt?.preventDefault()
        batteryRemove(index)
    }

    const OnCopyPreviousInverter = (event: any, index = 0) => {
        if (event.target.checked) {
            const equipmentArray = getFormValues(props, generationEquipment)
            const updatedObject = equipmentArray[index - 1] || []
            updatedObject.isSameAsPrevious=event.target.checked
            setValue(`equipmentDetail.${index}`, updatedObject)
            setValue(`equipmentDetail.${index}.ulCertification`, updatedObject.ulCertification)
        }
        setValue(`equipmentDetail.${index}.isSameAsPrevious`,event.target.checked,{shouldValidate: true,})
    }

    return (
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <EquipmentContainer>
                    {
                        getValues().dcSource &&
                        <>
                            <InsideCollapsibleHeader>DC Source</InsideCollapsibleHeader>
                            <p>
                                Please enter the following information for the DC energy source.
                            </p>
                            <GridColumnContainer columns={2}>
                                <div>
                                    <Label htmlFor={"dcSource.totalSourceRating"}>Total DC Source Rated Capacity (kW DC)</Label>
                                    <StyledInput disabled value={props.application?.system?.sourceSystem.dcRatedCapacity}/>
                                </div>

                                <div>
                                    <Label htmlFor={"dcSource.moduleManufacturer"}>Module Manufacturer</Label>
                                    <Input name={"dcSource.moduleManufacturer"} required maxLength={50} error={errors?.dcSource?.moduleManufacturer}/>
                                </div>

                                <div>
                                    <Label htmlFor={"dcSource.modelNumber"}>Model Number</Label>
                                    <Input name={"dcSource.modelNumber"} required maxLength={50} error={errors?.dcSource?.modelNumber}/>
                                </div>

                                <div>
                                    <Label htmlFor={"dcSource.moduleCount"}>Quantity of Modules</Label>
                                    <Input name={"dcSource.moduleCount"} required pattern={Pattern.NumberTrillion} error={errors?.dcSource?.moduleCount}/>
                                </div>

                                <div>
                                    <LabelWithTooltip
                                                        for={`dcSource.voltageRatings`}
                                                        label={'Total Array Voltage Rating'}
                                                        tooltip={"Nominal DC Voltage rating for the system."}
                                                    />
                                    <Input name={"dcSource.voltageRatings"} required={isArrayDcVoltageRequired} placeholder={isArrayDcVoltageRequired ? "" : "Optional"} pattern={Pattern.LimitedPrecisionMeasure} error={errors?.dcSource?.voltageRatings}/>
                                </div>

                                <div>
                                    <Label htmlFor={"dcSource.currentRating"}>Total Array Current Rating (Amps)</Label>
                                    <Input name={"dcSource.currentRating"} placeholder="Optional" pattern={Pattern.LimitedPrecisionMeasure} error={errors?.dcSource?.currentRating}/>
                                </div>

                                <div>
                                    <Label htmlFor={"dcSource.ratedVoltage"}>Module Rated Voltage (Volts)</Label>
                                    <Input name={"dcSource.ratedVoltage"} placeholder="Optional" pattern={Pattern.LimitedPrecisionMeasure} error={errors?.dcSource?.ratedVoltage}/>
                                </div>

                                <div>
                                    <Label htmlFor={"dcSource.ratedCurrent"}>Module Rated Current (Amps)</Label>
                                    <Input name={"dcSource.ratedCurrent"} placeholder="Optional" pattern={Pattern.LimitedPrecisionMeasure} error={errors?.dcSource?.ratedCurrent}/>
                                </div>

                                <div>
                                    <Label htmlFor={"dcSource.openCircuitVoltage"}>Open Circuit Voltage (Volts)</Label>
                                    <Input name={"dcSource.openCircuitVoltage"} placeholder="Optional" pattern={Pattern.LimitedPrecisionMeasure} error={errors?.dcSource?.openCircuitVoltage}/>
                                </div>

                                <div>
                                    <Label htmlFor={"dcSource.shortCircuitCurrent"}>Short Circuit Current (Amps)</Label>
                                    <Input name={"dcSource.shortCircuitCurrent"} placeholder="Optional" pattern={Pattern.LimitedPrecisionMeasure} error={errors?.dcSource?.shortCircuitCurrent}/>
                                </div>
                            </GridColumnContainer>
                        </>
                    }
                    <InsideCollapsibleHeader>{typeText} Information</InsideCollapsibleHeader>
                    <p>
                        Enter information about the {typeText} that will be installed. You can
                        enter information for multiple {typeText}s by clicking the Add {typeText} button.
                        Up to 10 {typeText}s can be added to an application.
                    </p>
                        {
                            inverterFields.map((item, index) => {
                                const errorFields = errors?.equipmentDetail?.[index] as any
                                return (
                                    <div key={item.id} className="numbered-form">
                                        <GreyBanner title={`${typeText} ${index + 1}`} button={
                                                inverterFields.length > 1 ?
                                                <div>
                                                    <StyledLinkSecondaryButton onClick={(evt) => {onRemoveInverter(evt, index)}} padding={false}>
                                                        REMOVE {typeText.toUpperCase()}
                                                    </StyledLinkSecondaryButton>
                                                </div> : undefined
                                        }/>
                                        <div className="numbered-form-content">
                                        {
                                            generationEquipment === "01" &&
                                            <>
                                                <GridColumnContainer columns={2}>
                                                    <div>
                                                        <Label htmlFor={`equipmentDetail.${index}.inverterType.identifier`}>
                                                            Inverter Type
                                                        </Label>
                                                        <RadioContainer>
                                                            {inverterTypes.map(inverterType =>
                                                                <Label
                                                                    htmlFor={`equipmentDetail.${index}.inverterType.identifier`}
                                                                    checkbox={true}
                                                                    key={inverterType.identifier}
                                                                >
                                                                    <RadioInput
                                                                        name={`equipmentDetail.${index}.inverterType.identifier`}
                                                                        required={true}
                                                                        onChange={e => {
                                                                            //since this radio is bound to the identifier, we should also update the description when it is changed
                                                                            setValue(`equipmentDetail.${index}.inverterType.description`, inverterType.description);
                                                                        }}
                                                                        value={inverterType.identifier}
                                                                    />
                                                                    {inverterType.description}
                                                                </Label>
                                                            )}
                                                        </RadioContainer>
                                                        {errorFields?.inverterType?.identifier?.type ===
                                                            "required" && <ErrorP>Inverter Type is required.</ErrorP>}

                                                    </div>
                                                    <div>
                                                        <Label htmlFor={`equipmentDetail.${index}.inverterQuantity`}>
                                                            Inverter Quantity
                                                        </Label>
                                                        <Input
                                                            name={`equipmentDetail.${index}.inverterQuantity`}
                                                            required={true}
                                                            maxLength={50}
                                                            pattern={Pattern.Number1to999}
                                                            error={errorFields?.inverterQuantity}
                                                        />
                                                    </div>
                                                </GridColumnContainer>
                                                {
                                                    index > 0 &&
                                                    <div>
                                                        <RadioContainer>
                                                            <Label htmlFor={`equipmentDetail.${index}.isSameAsPrevious`} checkbox>
                                                                <CheckboxInput
                                                                    name={`equipmentDetail.${index}.isSameAsPrevious`}
                                                                    value={true.toString()}
                                                                    onChange={(event) => {
                                                                        OnCopyPreviousInverter(event, index)
                                                                    }}
                                                                />
                                                                <span>Same as previous inverter</span>
                                                            </Label>
                                                        </RadioContainer>
                                                    </div>
                                                }
                                            </>
                                        }
                                        <GridColumnContainer columns={2}>
                                            <div>
                                                <Label htmlFor={`equipmentDetail.${index}.manufacturer`}>Manufacturer</Label>
                                                <Input
                                                    name={`equipmentDetail.${index}.manufacturer`}
                                                    required={true}
                                                    maxLength={50}
                                                    error={errorFields?.manufacturer}
                                                />
                                            </div>
                                            <div>
                                                <Label htmlFor={`equipmentDetail.${index}.modelNumber`}>Model Number</Label>
                                                <Input
                                                    name={`equipmentDetail.${index}.modelNumber`}
                                                    required={true}
                                                    maxLength={50}
                                                    error={errorFields?.modelNumber}
                                                />
                                            </div>
                                        </GridColumnContainer>
                                        <GridColumnContainer columns={2}>
                                            <div>
                                                <LabelWithTooltip
                                                    for={`equipmentDetail.${index}.powerFactor`}
                                                    label={'Power Factor'}
                                                    tooltip={"Do not use the % sign in your entry. Enter 1 for unity power factor. For constant Power Factor Mode - Underexcited, Inductive, Absorbing PF = -0.85 to -0.99 or for Overexicited, Capacitive, Injecting PF = 0.85 to 0.99."}
                                                />
                                                <Input
                                                    name={`equipmentDetail.${index}.powerFactor`}
                                                    required={true}
                                                    maxLength={50}
                                                    pattern={Pattern.PowerFactor}
                                                    error={errorFields?.powerFactor}
                                                />
                                            </div>
                                            <div>
                                                <Label htmlFor={`equipmentDetail.${index}.ratedVoltage`}>
                                                    Rated Voltage (Volts)
                                                </Label>
                                                <Input
                                                    name={`equipmentDetail.${index}.ratedVoltage`}
                                                    required={true}
                                                    maxLength={50}
                                                    pattern={Pattern.NumbersOnly}
                                                    error={errorFields?.ratedVoltage}
                                                />
                                            </div>
                                        </GridColumnContainer>
                                        <GridColumnContainer columns={2}>
                                            {
                                                generationEquipment !== "03" &&
                                                <div>
                                                    <LabelWithTooltip
                                                        for={`equipmentDetail.${index}.ieeeCertification.identifier`}
                                                        label={'IEEE 1547 Version'}
                                                        tooltip={"The Customer DER shall be Ul 1741 / UL 1741SA certified as a Grid Support Interactive, or a Grid Support Utility Intractive Inverter utilizing IEEE 1547-2003, or IEEE 1547a-2014 compliant settings with all grid support functions disbled."}
                                                    />
                                                    <ServerLoadedSelect name={`equipmentDetail.${index}.ieeeCertification.identifier`}
                                                                        url={`/api/dropdown/restricted/ieeeCertification`}
                                                                        required={true}
                                                                        error={errorFields?.ieeeCertification?.identifier}
                                                    />
                                                </div>
                                            }
                                            {
                                                generationEquipment === "03" &&
                                                <div>
                                                    <Label htmlFor={`equipmentDetail.${index}.inverterRating`}>
                                                        Total Inverter Rating (kW AC)
                                                    </Label>
                                                    <Input
                                                        name={`equipmentDetail.${index}.inverterRating`}
                                                        required={true}
                                                        pattern={Pattern.KwPrecisionMeasure}
                                                        maxLength={50}
                                                        error={errorFields?.inverterRating}
                                                    />
                                                </div>
                                            }
                                            <div>
                                                <LabelWithTooltip
                                                    for={`equipmentDetail.${index}.faultContribution`}
                                                    label={'Short Circuit Fault Contribution (Amps)'}
                                                    tooltip={"If unknown, enter 0"}
                                                />
                                                <Input
                                                    name={`equipmentDetail.${index}.faultContribution`}
                                                    required={true}
                                                    pattern={Pattern.NumbersOnly}
                                                    maxLength={50}
                                                    error={errorFields?.faultContribution}
                                                />
                                            </div>
                                        </GridColumnContainer>
                                        {
                                            generationEquipment === "01" &&
                                            <React.Fragment>
                                                <GridColumnContainer columns={2}>
                                                    <div>
                                                        <Label htmlFor={`equipmentDetail.${index}.inverterRating`} >
                                                            Total Inverter Rating (kW AC)
                                                        </Label>
                                                        <Input
                                                            name={`equipmentDetail.${index}.inverterRating`}
                                                            required={true}
                                                            pattern={Pattern.KwPrecisionMeasure}
                                                            maxLength={50}
                                                            error={errorFields?.inverterRating}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Label htmlFor={`equipmentDetail.${index}.phases.identifier`} >
                                                            Phases
                                                        </Label>
                                                        <ServerLoadedSelect name={`equipmentDetail.${index}.phases.identifier`}
                                                                            url={`/api/dropdown/unrestricted/phases`}
                                                                            required={true}
                                                                            error={errorFields?.phases?.identifier}
                                                        />
                                                    </div>
                                                </GridColumnContainer>
                                                <GridColumnContainer columns={2}>
                                                    <div>
                                                        <Label htmlFor={`equipmentDetail.${index}.ulCertification`}>
                                                            Is the Inverter UL1741-SB Certified?
                                                        </Label>
                                                        <RadioContainer>
                                                            <Label htmlFor={`equipmentDetail.${index}.ulCertification`} checkbox>
                                                                <RadioInput
                                                                    name={`equipmentDetail.${index}.ulCertification`}
                                                                    required={true}
                                                                    value={true.toString()}
                                                                />
                                                                <span>Yes</span>
                                                            </Label>
                                                            <Label htmlFor={`equipmentDetail.${index}.ulCertification`} checkbox>
                                                                <RadioInput
                                                                    name={`equipmentDetail.${index}.ulCertification`}
                                                                    required={true}
                                                                    value={false.toString()}
                                                                />
                                                                <span>No</span>
                                                            </Label>
                                                        </RadioContainer>
                                                        {errorFields?.ulCertification?.type ===
                                                            "required" && <ErrorP>Please indicate yes or no.</ErrorP>}
                                                    </div>
                                                </GridColumnContainer>
                                                {
                                                    currentItem?.[index]?.ulCertification === true.toString() &&
                                                    <MessageDisplay type={'warning'} cannotClose={true}>
                                                        <div>
                                                            After completing the Equipment section, upload the Inverter Data Sheet under the Documents section of the application, showing listing and label information from the appropriate listing authority, e.g. UL1741 listing.
                                                            <br/><br/>Default specified settings file must be installed on all IEEE-1547-2018/UL1741-SB certified inverters. <a href="https://www.firstenergycorp.com/content/dam/feconnect/files/retail/IEEE-1547-2018-Specified-Inverter-Settings.csv"> Click here for settings file (CSV)</a>
                                                        </div>
                                                    </MessageDisplay>
                                                }
                                                {
                                                    currentItem?.[index]?.ulCertification === false.toString() &&
                                                    <MessageDisplay type={'warning'} cannotClose={true}>
                                                        <React.Fragment>
                                                            After completing the Equipment section, upload the Inverter Data Sheet under the Documents section of the application, showing listing and label information from the appropriate listing authority, e.g. UL1741 listing.
                                                        </React.Fragment>
                                                    </MessageDisplay>
                                                }
                                            </React.Fragment>
                                        }

                                        {
                                            generationEquipment === "02" &&
                                            <GridColumnContainer columns={2}>
                                                <div>
                                                    <Label htmlFor={`equipmentDetail.${index}.synchronousType.identifier`}>
                                                        Type
                                                    </Label>
                                                    <ServerLoadedSelect name={`equipmentDetail.${index}.synchronousType.identifier`}
                                                                        url={`/api/dropdown/restricted/synchronousType`}
                                                                        required={true}
                                                                        error={errorFields?.synchronousType?.identifier}
                                                    />
                                                </div>
                                                <div>
                                                    <Label htmlFor={`equipmentDetail.${index}.numberOfPoles`}>
                                                        Number of poles
                                                    </Label>
                                                    <Input
                                                        name={`equipmentDetail.${index}.numberOfPoles`}
                                                        required={true}
                                                        maxLength={50}
                                                        pattern={Pattern.Number1to999}
                                                        error={errorFields?.numberOfPoles}
                                                    />
                                                </div>
                                            </GridColumnContainer>
                                        }

                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div>
                            {
                                ((generationEquipment === "01" || isBatteryOnly) && (batteryFields.length > 0)) && <>
                                <div className="form-header-section" style={{marginTop:"1em"}}>
                                    <InsideCollapsibleHeader>Battery Information</InsideCollapsibleHeader>
                                </div>
                                {
                                    batteryFields.map((item, index) => {
                                        const errorFields = errors?.batteries?.[index]
                                        return (
                                            <div className="numbered-form" key={item.id}>
                                                <GreyBanner title={`Battery ${index + 1}`} button={
                                                    ((batteryFields.length >= 1 && !isBatteryOnly) ||
                                                    (isBatteryOnly && batteryFields.length > 1)) ?
                                                    <div>
                                                        <StyledLinkSecondaryButton onClick={(evt) => {onRemoveBattery(evt, index)}} padding={false}>
                                                            REMOVE BATTERY
                                                        </StyledLinkSecondaryButton>
                                                    </div> : undefined
                                                }/>
                                                <div className="numbered-form-content">
                                                    <GridColumnContainer columns={2}>
                                                        <div>
                                                            <Label htmlFor={`batteries.${index}.quantityOfBattery`}>Battery Quantity</Label>
                                                            <Input
                                                                name={`batteries.${index}.quantityOfBattery`}
                                                                required={true}
                                                                maxLength={50}
                                                                pattern={Pattern.Number1to999}
                                                                error={errorFields?.quantityOfBattery}
                                                            />
                                                        </div>
                                                    </GridColumnContainer>
                                                    <GridColumnContainer columns={2}>
                                                        <div>
                                                            <Label htmlFor={`batteries.${index}.batteryManufacturer`}>Manufacturer</Label>
                                                            <Input
                                                                name={`batteries.${index}.batteryManufacturer`}
                                                                required={true}
                                                                maxLength={50}
                                                                error={errorFields?.batteryManufacturer}
                                                            />
                                                        </div>
                                                        <div>
                                                            <Label htmlFor={`batteries.${index}.batteryModel`}>Battery Model</Label>
                                                            <Input
                                                                name={`batteries.${index}.batteryModel`}
                                                                required={true}
                                                                maxLength={50}
                                                                error={errorFields?.batteryModel}
                                                            />
                                                        </div>
                                                    </GridColumnContainer>
                                                    <GridColumnContainer columns={2}>
                                                        <div>
                                                            <Label htmlFor={`batteries.${index}.maximumOutput`}>
                                                                Maximum Output  {Number(getValues(`batteries.${index}.quantityOfBattery`)) > 1 ? 'For Each Battery ' : ''}(kW)
                                                            </Label>
                                                            <Input
                                                                name={`batteries.${index}.maximumOutput`}
                                                                required={true}
                                                                maxLength={50}
                                                                pattern={Pattern.KwPrecisionMeasure}
                                                                error={errorFields?.maximumOutput}
                                                            />
                                                        </div>
                                                    </GridColumnContainer>
                                                    <div>
                                                        <b>Output Power Supply</b>
                                                        <FlexRowContainer>
                                                            <Label htmlFor={`batteries.${index}.batteryOutputPowerSupply`} checkbox={true} >
                                                                <RadioInput name={`batteries.${index}.batteryOutputPowerSupply`} value='ac' required={true}/>
                                                                AC
                                                            </Label>
                                                            <Label htmlFor={`batteries.${index}.batteryOutputPowerSupply`} checkbox={true} >
                                                                <RadioInput name={`batteries.${index}.batteryOutputPowerSupply`} value='dc' required={true}/>
                                                                DC
                                                            </Label>
                                                        </FlexRowContainer>
                                                    </div>
                                                    <GridColumnContainer columns={2}>
                                                    <div>
                                                        <Label htmlFor={`batteries.${index}.intentOfStorage`}>
                                                            Intent of Storage
                                                        </Label>
                                                        <Select
                                                            name={`batteries.${index}.intentOfStorage`}
                                                            required={true}
                                                            error={errorFields?.intentOfStorage}
                                                        >
                                                            <option value="" disabled>
                                                                Select One
                                                            </option>
                                                            <option value="homebackup">Home Backup</option>
                                                            <option value="Solarself-consumption">Solar Self-Consumption</option>
                                                            <option value="exportloadshift"> Export (Load Shift)</option>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <Label htmlFor={`batteries.${index}.storageCapacityBattery`}>
                                                            Storage Capacity for {Number(getValues(`batteries.${index}.quantityOfBattery`)) > 1 ? 'Each ' : ''}Battery (kWh)
                                                        </Label>
                                                        <Input
                                                            name={`batteries.${index}.storageCapacityBattery`}
                                                            required={true}
                                                            maxLength={50}
                                                            pattern={Pattern.KwPrecisionMeasure}
                                                            error={errorFields?.storageCapacityBattery}
                                                        />
                                                    </div>
                                                </GridColumnContainer>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </>
                            }
                        </div>
                    </EquipmentContainer>

                    <ButtonContainer>
                        {
                            inverterFields.length < 10 &&
                            <FormFooterAction onClick={onAddInverter} marginBottom={true}>
                                ADD {typeText}
                            </FormFooterAction>
                        }
                        {
                            (batteryFields.length < 10 && (generationEquipment === "01" || (generationEquipment !== "01" && isBatteryOnly))) &&
                            <FormFooterAction onClick={onAddBattery} float={'left'} marginBottom={true}>
                                ADD BATTERY
                            </FormFooterAction>
                        }
                        <HoverSpan onHover={() => submitDisabled && methods.trigger()}>
                            <SmallPrimaryButton
                                type={"submit"}
                                float="left"
                                disabled={submitDisabled}
                            >
                                {isSubmitting ? "Saving..." : "Save and continue"}
                            </SmallPrimaryButton>
                        </HoverSpan>
                    </ButtonContainer>
                </Form>
            </FormProvider>
    )
}

