import { FlexRowContainer, GreenCheckmark, PageSubTitle} from "../../../styledcomponents/MiscStyledComponents";
import {BaseApplicationStepProps} from "../Application";
import React, { useEffect, useState } from "react";
import {ApplicationStepButtons} from "../helpers/ApplicationStepButtons";
import Collapsible, {CollapsibleContainer} from "../../misc/Collapsible";
import {SystemForm, getShouldShowDcSource} from "../forms/SystemForm";
import {EquipmentForm, InverterForm} from "../forms/EquipmentForm";
import {AttachmentForm} from "../forms/AttachmentForm";
import {FormActionType} from "../helpers/applicationFormReducer";
import { State } from "../../../enums/State";
import { AttachmentResponse, AttachmentType } from "../../../models/AttachmentResponse";
import { TAG_BATTERY_ONLY, TAG_SOLAR_PV } from "../../../utils/constants";
import { useAxios } from "../../../AxiosProvider";
import { useEffectDeepCompare } from "../../../hooks/useEffectDeepCompare";


export type GeneratorFacilityInformationForm = {

}

type GeneratorDetailProps = BaseApplicationStepProps & {

}

type FormCollapsibleTitleProps = {
    text : string;
    showGreenCheck : boolean
}

export function FormCollapsibleTitle(props : FormCollapsibleTitleProps) {
    return (
        <FlexRowContainer>
            {
                props.showGreenCheck &&
                <GreenCheckmark size={24}/>
            }
            {props.text}
        </FlexRowContainer>
    )
}

const RequiredFilesDefault = [AttachmentType.SITE_PLAN, AttachmentType.ONE_LINE_DIAGRAM];
const RequiredFilesByState = new Map<string, AttachmentType[]>([
    [State.PA, [...RequiredFilesDefault, AttachmentType.NET_METER_RIDER]]
]);


export function GeneratorFacilityInformation(props : GeneratorDetailProps) {
    let isSystemComplete     : boolean = props.application?.systemComplete!;
    let isEquipmentComplete  : boolean = props.application?.equipmentComplete!;
    let isAttachmentComplete : boolean = props.application?.attachmentsComplete!;
    let shouldShowDcSource = getShouldShowDcSource(props.application?.system);

    const axios = useAxios();
    const [lastKnownUploads, setLastKnownUploads] = useState<AttachmentType[] | undefined>();

    //get copy of required files by state. Then push INVERTER_DATA_SHEET if there is an inverter
    const requiredFileTypes = (RequiredFilesByState.get(props.application?.account?.region ?? State.OH) ?? RequiredFilesDefault).slice();
    if (props.application?.equipment?.inverters?.length) {
        requiredFileTypes?.push(AttachmentType.INVERTER_DATA_SHEET);
    }

    /* on first load, we don't know the user's uploaded files until they open the attachments pane.
        This pulls the user's existing uploads when they leave and return to the GenFacilityInfo screen, 
        so we can mark attachments incomplete if necessary */
    useEffect(() => {
        if (lastKnownUploads == undefined) {
            axios?.secureApi.get(
                'api/attachments/' + props.application?.id
            ).then(response => {
                let attachmentResponse: AttachmentResponse = response.data;
                setLastKnownUploads(attachmentResponse.attachmentsStatus.map(s => s.type));
            })
        }
    }, [lastKnownUploads])
    
    useEffectDeepCompare(() => {
        //when the list of uploaded files changes, or inverters change, recalculate if attachment section is complete
        if (lastKnownUploads) {
            if (requiredFileTypes?.every(requiredType => lastKnownUploads.includes(requiredType))) {
                props.applicationFormsDispatch({type: FormActionType.COMPLETE_ATTACHMENTS});
            } else {
                props.applicationFormsDispatch({type: FormActionType.INCOMPLETE_ATTACHMENTS});
            }
        }
    }, [requiredFileTypes, lastKnownUploads], true)

    useEffect(() => {
        // When a user completes Equipment, then goes back to System and changes the generation equipment, 
        // we should invalidate the equipment section if it is now incomplete
        if (isEquipmentComplete) {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
            let equipment: InverterForm[] | undefined = [];
            switch (props.application?.system?.sourceSystem?.generationEquipment.identifier) {
                case "02":
                    equipment = props.application?.equipment?.synchronousGenerators;
                    break
                case "03":
                    equipment = props.application?.equipment?.inductionGenerators;
                    break
                default:
                    equipment = props.application?.equipment?.inverters;
            }
            
            if (!equipment?.[0]?.manufacturer?.length) {
                props.applicationFormsDispatch({type: FormActionType.INCOMPLETE_EQUIPMENT});
            }
        }
    }, [props.application?.system?.sourceSystem?.generationEquipment?.identifier])

    useEffect(() => {
        // When a user completes Equipment, then goes back to System and changes the energy source, 
        // we should invalidate the equipment section if it is now incomplete
        if (isEquipmentComplete) {
            // const energyId = props.application?.system?.sourceSystem?.energySource.identifier;
            const energyTags = props.application?.system?.sourceSystem?.energySource.tags ?? [];
            const incomplete = 
                (energyTags.includes(TAG_BATTERY_ONLY) && !props.application?.equipment?.batteries?.length) //Battery array generation but no batteries info
                || (shouldShowDcSource && !props.application?.equipment?.dcSource?.modelNumber); //  DC Source info required but not provided

            if(incomplete) {
                props.applicationFormsDispatch({type: FormActionType.INCOMPLETE_EQUIPMENT});
            }
        }
    }, [props.application?.system?.sourceSystem?.energySource?.identifier, shouldShowDcSource])


    useEffect(() => {
        if (isSystemComplete && isEquipmentComplete && isAttachmentComplete) {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
        } else {
            props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        }
    }, [isSystemComplete, isEquipmentComplete, isAttachmentComplete])

    return (
        <React.Fragment>
            <PageSubTitle>Generator Facility Information</PageSubTitle>
            <CollapsibleContainer>
                <Collapsible title={<FormCollapsibleTitle text={'System'} showGreenCheck={isSystemComplete}/>} forceClose={isSystemComplete}  
                        forceOpen={!isSystemComplete} preventAutoscroll>
                    <SystemForm {...props}/>
                </Collapsible>
                <Collapsible title={<FormCollapsibleTitle text={'Equipment'} showGreenCheck={isEquipmentComplete}/>}
                             disabled={!isSystemComplete}
                             forceOpen={isSystemComplete && !isEquipmentComplete}
                             forceClose={!isSystemComplete || isEquipmentComplete}
                >
                    <EquipmentForm {...props} />
                </Collapsible>
                <Collapsible title={<FormCollapsibleTitle text={'Documents'} showGreenCheck={isAttachmentComplete}/>}
                             disabled={!isEquipmentComplete}
                             forceOpen={isEquipmentComplete && !isAttachmentComplete}
                             forceClose={!isEquipmentComplete}
                >
                    <AttachmentForm {...props} setLastKnownUploads={setLastKnownUploads} requiredFileTypes={requiredFileTypes} />
                </Collapsible>
            </CollapsibleContainer>
            <ApplicationStepButtons {...props} />
        </React.Fragment>
    )
}