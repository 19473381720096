import React from 'react';
import {LinkProps} from "react-router-dom";
import {StyledTableLink} from "../../styledcomponents/TableStyledComponents";

type TableLinkProps = LinkProps & {
    disabled ?: boolean
}

export default function TableLink(props : TableLinkProps) {
    return(
        <StyledTableLink to={props.disabled ? '#' : props.to} state={props.state}>{props.children}</StyledTableLink>
    )
}