import React from "react";
import {BaseApplicationStepProps} from "../Application";
import {FlexColumnContainer, InlineLink} from "../../../styledcomponents/MiscStyledComponents";
import GreyBanner from "../../misc/GreyBanner";
import {ApplicationStepButtons} from "../helpers/ApplicationStepButtons";
import {Form, Label} from "../../../styledcomponents/FormStyledComponents";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {FormActionType} from "../helpers/applicationFormReducer";
import {useMessageService} from "../../../hooks/useMessageService";
import {Input} from "../../form/FormElements";
import {Page, usePath} from "../../../PathProvider";
import {AxiosError, AxiosResponse} from "axios";
import ErrorUtils from "../../../utils/errorUtils";
import {useAxios} from "../../../AxiosProvider";
import {Pattern} from "../../../enums/Pattern";

export type AccountLookupForm = {
    accountNumber : string,
    zipCode : string
}

type AccountLookupProps = BaseApplicationStepProps & {
}

export function AccountLookup(props : AccountLookupProps) {
    const messageService = useMessageService();
    const path = usePath();
    const axios = useAxios();

    const methods = useForm<AccountLookupForm>({
        defaultValues: {
            accountNumber: "",
            zipCode: ""
        },
        mode: "onChange"
    });
    const {handleSubmit, formState : {isValid, errors}} = methods;

    // whenever the form is valid, make sure next is enabled. If it is invalid then make sure next is disabled
    React.useEffect(() => {
        if (isValid) {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
        } else {
            props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        }
    }, [isValid])

    const onSubmit : SubmitHandler<AccountLookupForm> = async (data : AccountLookupForm) => {
        // clear any messages once we submit
        messageService.clearAll();

        // disable next button while processing
        props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        props.applicationFormsDispatch({type : FormActionType.START_PROCESSING});

        // use data to do post to lookup in db
        await axios?.secureApi.get(
            `/api/account/lookupAccount/${data.accountNumber}/${data.zipCode}`
        ).then((response: AxiosResponse) => {
            if (response.data.findAccountItems?.length > 0) {
                // if success, set the data for the verification page to use then go forward
                props.applicationFormsDispatch({type: FormActionType.UPDATE_ACCOUNT_PENDING_VERIFICATION, accountPendingVerification : response.data.findAccountItems[0] });
                props.applicationFormsDispatch({type : FormActionType.FORWARD});
            } else {
                messageService.error("No account found.");
                // enable next button after error is shown
                props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
            }
        }).catch((err: AxiosError) => {
            console.error(err);
            let errorResponse : AxiosResponse | undefined = err.response;

            // The endpoint returns a 400 if there were validation errors, so show them here,
            // otherwise just show a generic error
            if (errorResponse?.status === 400) {
                messageService.error(ErrorUtils.getValidationErrors(errorResponse))
            } else if (errorResponse?.status === 429) {
                messageService.error("Account is locked.  Please wait 1 hour.");
            } else {
                messageService.error(ErrorUtils.UNKNOWN_ERROR);
            }

            // enable next button after error is shown
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
        }).finally(() => {
            props.applicationFormsDispatch({type : FormActionType.END_PROCESSING});
        })
    };

    return (
        <FlexColumnContainer>
            <GreyBanner title={'Customer Account Lookup'} />
            <div>
                Please enter the account information associated with the service address for interconnection so we can search for the account.
                <br />
                <br />
                If you do not have an Account Number, click <InlineLink to={path.get(Page.Application)} state={{switchToNoAccountForm : true}}>Create an Application Without an Account</InlineLink> to continue.
            </div>
            <FormProvider {...methods}>
                <Form>
                    <div style={{width: "328px"}}>
                        <Label htmlFor={"accountNumber"}>12-Digit Customer Account Number</Label>
                        <Input name={"accountNumber"} required={true} pattern={Pattern.AccountNumber} maxLength={12} minLength={12} error={errors?.accountNumber} enforceMaxLength={true} />
                        <Label htmlFor={"zipCode"}>5-Digit Zip Code</Label>
                        <Input name={"zipCode"} required={true} pattern={Pattern.ZipCode} error={errors?.zipCode} maxLength={5} minLength={5} enforceMaxLength={true}/>
                    </div>
                </Form>
            </FormProvider>
            <ApplicationStepButtons {...props} onNext={handleSubmit(onSubmit)} />
        </FlexColumnContainer>
    )
}