//Configure external link URLs based on current host

export const URLSUFFIX_CONTACT_US = '/feconnect/interconnection-contacts.html'
export const URLSUFFIX_PAY_INVOICE = '/help/billingpayments/ways-to-pay/non-consumption-payments.html'
export const URLSUFFIX_REQUEST_ACCESS = '/supplierservices.html'
export const URLSUFFIX_CUSTOMER_REGISTER = '/register_for_onlineaccount.html'
export const URLSUFFIX_FORGOT_PASSWORD = '/log_in/forgot-credentials.html'
export const URLSUFFIX_REGISTRATION_AGREEMENT = '/register_for_onlineaccount/username-and-password-guidelines.html'
export const URLSUFFIX_CARE_CENTER = '/help/contact-center.html'
export const URLSUFFIX_REQUEST_UPGRADE = '/service_requests/request-electrical-work/new-upgrade-service.html'
export const URLSUFFIX_WHOLESALE = '/feconnect/wholesale-interconnections.html'

//map from the current host origin to the correct external link host/prefix
const envHosts:  {hostContains: string, externalHost: string}[]= [
    {hostContains: 'localhost:', externalHost: 'https://www-qa-b.firstenergycorp.com'},
    {hostContains: 'interconnect.dev', externalHost: 'https://www-qa-b.firstenergycorp.com'},
    {hostContains: 'interconnect.qa', externalHost: 'https://www-qa-b.firstenergycorp.com'},
    {hostContains: 'firstenergycorp.com', externalHost: 'https://www.firstenergycorp.com'}, //prod
    {hostContains: '/', externalHost: 'https://www.firstenergycorp.com'}, //default
];

/** Constructs an external FirstEnergy URL based on the given URL suffix. 
 * Takes into account the current host to prepend the correct FirstEnergy environment for the link URL. */
export default function GetExternalFeUrl(urlSuffix: string ) {
    const currentHost = window.location.origin;
    return envHosts.find(h => currentHost.includes(h.hostContains))?.externalHost + urlSuffix;
}

export function getBackendHost() {
    return process.env.REACT_APP_SERVER_URL ?? window.location.origin;
}