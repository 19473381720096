import {BaseApplicationStepProps} from "../Application";
import {
    FormFooterAction,
    PrimaryButton,
    SecondaryButton,
} from "../../../styledcomponents/FormStyledComponents";
import {useAuth} from "../../../auth/AuthProvider";
import {FormActionType} from "./applicationFormReducer";
import {Page, usePath} from "../../../PathProvider";
import {SecondaryButtonLink} from "../../misc/ButtonLink";
import {Divider} from "../../../styledcomponents/MiscStyledComponents";
import React from "react";
import {AxiosContextTypes, useAxios} from "../../../AxiosProvider";
import {useNavigate} from "react-router-dom";
import {useMessageService} from "../../../hooks/useMessageService";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";
import {AxiosError, AxiosResponse} from "axios";
import {ApplicationStatus} from "../../../enums/ApplicationStatus";
import styled from "styled-components";
import { ApplicationStep } from "../../../enums/ApplicationStep";
import { HoverSpan } from "../../form/FormElements";

const SaveAndExit = styled(FormFooterAction)`
    margin: 1.5rem 0 1rem 0;
`

type ApplicationStepButtonsProps = BaseApplicationStepProps & {
    onNext?: () => Promise<void> | void
}

export async function saveStep(step: ApplicationStep, axios: AxiosContextTypes, applicationId: string) {
    await axios?.secureApi.put(
        '/api/application/updateCurrentPage',
        {
            lastStepCompleted: step, 
            id: applicationId
        }
    ).catch((e: AxiosError) =>  {
        //no-op, this is a non-critical API call
    });
}

export function ApplicationStepButtons(props: ApplicationStepButtonsProps) {
    const auth = useAuth();
    const path = usePath();
    const axios = useAxios();
    const navigate = useNavigate();
    const messageService = useMessageService();

    let stepOrder          = props.application?.steps;
    let currentStep        = props.application?.currentStep!;
    let indexOfCurrentStep = stepOrder?.indexOf(currentStep);
    let isFirstStep        = indexOfCurrentStep === 0;
    let canGoBack          = !isFirstStep && currentStep !== ApplicationStep.ContactInformation;

    async function saveAndExit() {
        // build out form
        // make call to save current state of application
        await axios?.secureApi.put(
            '/api/application/saveEntireApplication',
            { ...ApplicationFormUtils.buildApplicationFormRequestBody(props.application!, auth.user!) }
        ).then((response: AxiosResponse) => {
            // if success then exit and show success message
            navigate(path.get(Page.ApplicationList), { state: { successMessage: 'Successfully saved application.' } });
        }).catch((err: AxiosError) => {
            console.error(err);
            messageService.error('Error saving application.')
        })
    }

    async function saveCurrentStep(step: ApplicationStep) {
        await saveStep(step, axios!,  props.application?.id!);
    }

    async function onNext() {
        // if an onNext function is provided, call it. Otherwise, just go forward
        if (props.onNext) {
            await props.onNext()
        } else {
            props.applicationFormsDispatch({ type: FormActionType.FORWARD, saveStep: saveCurrentStep });
        }
    }

    return (
        <React.Fragment>
            {/*If the application has an account set, and it is still a draft, let the user save and exit */}
            {
                props.application?.account &&
                props.application.status == ApplicationStatus.Draft &&
                <React.Fragment>
                    <SaveAndExit type={"button"} onClick={saveAndExit} float={"left"} marginBottom={true} marginTop={true}>SAVE AND EXIT APPLICATION</SaveAndExit>
                    <br />
                    <br />
                </React.Fragment>
            }
            <Divider />
            <div>
                {/* If it is the first step, give an exit button. Otherwise, conditionally show a back button */}
                {
                    isFirstStep
                        ? <SecondaryButtonLink to={path.get(Page.ApplicationList)}>Exit Application</SecondaryButtonLink>
                        : <SecondaryButton float={"left"} disabled={!canGoBack} onClick={() => canGoBack ? props.applicationFormsDispatch({ type: FormActionType.BACK }) : null}>
                            Back
                        </SecondaryButton>
                }
                {/* This span captures hover over the button even if it is disabled. When that happens trigger the form validation to show users what they are missing */}
                <HoverSpan onHover={() => (props.application?.nextDisabled && props.triggerValidation) && props.triggerValidation()}>
                {/* If it is the review and submit step, give a submit button. Otherwise, give a next button */}
                {
                    currentStep === ApplicationStep.ReviewAndSubmit
                        ? <PrimaryButton disabled={props.application?.nextDisabled || props.application?.processing} onClick={onNext}>
                            {props.application?.processing ? 'Submitting...' : 'Submit'}
                        </PrimaryButton>
                        : <PrimaryButton disabled={props.application?.nextDisabled || props.application?.processing} onClick={onNext} float='right' >
                            {props.application?.processing ? 'Processing...' : 'Next'}
                        </PrimaryButton>
                }
                </HoverSpan>
            </div>
        </React.Fragment>
    )
}