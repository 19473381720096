import React, {
    createContext,
    ReactElement,
    useCallback,
    useContext,
    useMemo,
    useReducer,
    isValidElement
} from "react";
import _uniqueId from 'lodash/uniqueId';

const MessageContext = createContext<MessageContextTypes>({
    error : () => null,
    warning : () => null,
    success : () => null,
    info : () => null,
    clearAll : () => null,
    removeMessageByType : () => null,
    removeMessageById : () => null,
    messages : []
});

interface MessageProviderProps {
    children : ReactElement
}

export type MessageType = 'error' | 'warning' | 'success' | 'info'
export type Message = {
    type : MessageType,
    id : string
} & ({
    text : string
    content ?: never
} | {
    content : ReactElement
    text ?: never
})


export type MessageContextTypes = {
    error: (data : string | string[] | ReactElement) => void;
    warning: (data : string | string[] | ReactElement) => void;
    success: (data : string | string[] | ReactElement) => void;
    info: (data : string | string[] | ReactElement) => void;
    clearAll : () => void;
    removeMessageByType: (data : MessageType) => void;
    removeMessageById: (data : string) => void;
    messages : Message[];
};

enum MessageActionTypes {
    CLEAR_ALL,
    ADD_MESSAGE,
    ADD_COMPLEX_MESSAGE,
    REMOVE_MESSAGE_BY_TYPE,
    REMOVE_MESSAGE_BY_ID
}

type ClearAllAction = {
    type: typeof MessageActionTypes.CLEAR_ALL
}

type AddMessageAction = {
    type: typeof MessageActionTypes.ADD_MESSAGE,
    message : Message
}

type AddComplexMessageAction = {
    type: typeof MessageActionTypes.ADD_COMPLEX_MESSAGE,
    message : Message
}

type RemoveMessageByTypeAction = {
    type: typeof MessageActionTypes.REMOVE_MESSAGE_BY_TYPE,
    messageType : MessageType
}

type RemoveMessageByIdAction = {
    type: typeof MessageActionTypes.REMOVE_MESSAGE_BY_ID,
    id : string
}

type MessageAction = ClearAllAction | AddMessageAction | AddComplexMessageAction | RemoveMessageByTypeAction | RemoveMessageByIdAction

function messageReducer(state : Message[], action: MessageAction) : Message[] {
    switch (action.type) {
        case MessageActionTypes.CLEAR_ALL:
            return [];
        case MessageActionTypes.ADD_MESSAGE:
            return [
                ...state,
                {
                    type: action.message.type,
                    text: action.message.text ?? '',
                    id : action.message.id
                }
            ];
        case MessageActionTypes.ADD_COMPLEX_MESSAGE:
            return [
                ...state,
                {
                    type: action.message.type,
                    content: action.message.content ?? <></>,
                    id : action.message.id
                }
            ];
        case MessageActionTypes.REMOVE_MESSAGE_BY_TYPE:
            let messagesToKeep = state.filter((message : Message) => { return action.messageType !== message.type });

            return [...messagesToKeep];
        case MessageActionTypes.REMOVE_MESSAGE_BY_ID:
            let copy = [...state];
            let indexOfMessageToRemove = copy.findIndex((message : Message) => { return action.id === message.id });

            copy.splice(indexOfMessageToRemove, 1);

            return [...copy];
        default:
            return state;
    }
}

export function MessageProvider(props : MessageProviderProps) {
    const [messages, messagesDispatch] = useReducer(messageReducer, []);

    // helper function
    function addMessage(type : MessageType, message : string | string[] | ReactElement) {
        let messages : (string | ReactElement)[] = [];

        if   (message instanceof Array)  { messages = messages.concat(message); }
        else                             { messages.push(message); }

        messages.forEach((message : string | ReactElement) => {
            let newMessageId = _uniqueId();

            if (isValidElement(message)) {
                messagesDispatch({ type: MessageActionTypes.ADD_COMPLEX_MESSAGE, message : { type : type, content : message, id: newMessageId}});
            } else {
                let messageString: string | undefined  = message?.toString();
                messagesDispatch({ type: MessageActionTypes.ADD_MESSAGE, message : { type : type, text : messageString, id: newMessageId}});
            }

            if (type === 'success') {
                setTimeout(() => removeMessageById(newMessageId), 5000);
            }
        })
    }

    const error   = useCallback( (message : string | string[] | ReactElement) => { addMessage('error',   message); }, []);
    const warning = useCallback( (message : string | string[] | ReactElement) => { addMessage('warning', message); }, []);
    const success = useCallback( (message : string | string[] | ReactElement) => { addMessage('success', message); }, []);
    const info = useCallback( (message : string | string[] | ReactElement) => { addMessage('info', message); }, []);

    const clearAll = useCallback(() => { messagesDispatch({ type: MessageActionTypes.CLEAR_ALL}); }, []);

    const removeMessageByType = useCallback( (type : MessageType) => {
        messagesDispatch({ type: MessageActionTypes.REMOVE_MESSAGE_BY_TYPE, messageType: type});
    }, []);

    const removeMessageById = useCallback( (id : string) => {
        messagesDispatch({ type: MessageActionTypes.REMOVE_MESSAGE_BY_ID, id: id});
    }, []);

    const value = useMemo<MessageContextTypes>(
        () => ({
            error,
            warning,
            success,
            info,
            clearAll,
            removeMessageByType,
            removeMessageById,
            messages
        }),
        [error, warning, success, info, clearAll, removeMessageByType, removeMessageById, messages]
    );

    return (
        <MessageContext.Provider value={value}>
            {props.children}
        </MessageContext.Provider>
    );
}

export const useMessageService = () => {
    return useContext(MessageContext);
};