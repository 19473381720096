// The string values here MUST match the backend enum ApplicationStatus.java
export enum ApplicationStatus {
    Draft                  = "Draft",
    Submitted              = "Submitted",
    InitialReview          = "Initial Review",
    EngineerReview         = "Engineer Review",
    PendingPucSubmission   = "Pending PUC Submission",
    SubmittedToPuc         = "Submitted to PUC",
    PendingCustomer        = "Pending Customer",
    WithdrawnByCustomer    = "Withdrawn by Customer",
    Rejected               = "Rejected",
    AwaitingFinalDocuments = "Preliminary Approval: Awaiting Final Documents",
    ReviewFinalDocuments   = "Review Final Documents",
    Metering               = "Metering",
    PermissionToOperate    = "Permission to Operate",
    PendingAccountInfo     = "Pending Account Info",
    PendingAccountCreation = "Pending Account Creation",
    AwaitingAdditionalFee  = "Awaiting Additional Fee",
    UpgradeRequired        = "Conditional Approval: Upgrade Required"
}

/** Is the customer allowed to make changes to (at least some parts of) the application? */
export function isApplicationOpen(status ?: ApplicationStatus) {
    return status && [ApplicationStatus.Draft, ApplicationStatus.PendingCustomer, ApplicationStatus.AwaitingFinalDocuments].includes(status);
}