import {
    AdditionalInformationData,
    ApplicationForm,
    ApprovalCodeAndFeesData,
    LoadedApplication,
    PaAgreementExtraData
} from "../Application";
import {SystemFormData} from "../forms/SystemForm";
import {EquipmentFormData} from "../forms/EquipmentForm";
import {AttachmentFormData} from "../forms/AttachmentForm";
import {ApplicationFormAccount} from "../../../models/ApplicationFormAccount";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";
import {ContactInformationFormData} from "../steps/ContactInformation";
import { ApplicationStep } from "../../../enums/ApplicationStep";
import {InterconnectApplicantFormData} from "../forms/interconnectApplicant/types/InterconnectApplicantTypes";

export enum FormActionType {
    START_PROCESSING,
    END_PROCESSING,
    SET_ID,
    LOAD_APPLICATION,
    UPDATE_ACCOUNT,
    UPDATE_SYSTEM,
    COMPLETE_SYSTEM,
    INCOMPLETE_SYSTEM,
    UPDATE_EQUIPMENT,
    COMPLETE_EQUIPMENT,
    INCOMPLETE_EQUIPMENT,
    APPROVAL_CODE_AND_FEES,
    ADDITIONAL_INFORMATION,
    COMPLETE_ADDITIONAL_INFORMATION,
    UPDATE_PA_EXTRA,
    COMPLETE_PA_EXTRA,
    UPDATE_CONTACT_INFORMATION,
    UPDATE_ADDITIONAL_COMMENTS,
    UPDATE_ATTACHMENTS,
    COMPLETE_ATTACHMENTS,
    INCOMPLETE_ATTACHMENTS,
    FORWARD,
    BACK,
    JUMP_BACK,
    UPDATE_ACCOUNT_PENDING_VERIFICATION,
    ENABLE_NEXT,
    DISABLE_NEXT,
    RESET_FORM,
    UPDATE_INTERCONNECT_APPLICANT,
    COMPLETE_CONTACTS
}

type LoadApplication = {
    type: typeof FormActionType.LOAD_APPLICATION,
    loadedApplication: LoadedApplication,
    isVendor?: boolean;
}

type SetId = {
    type: typeof FormActionType.SET_ID,
    id: string
}

type StartProcessing = {
    type: typeof FormActionType.START_PROCESSING
}

type EndProcessing = {
    type: typeof FormActionType.END_PROCESSING
}

type Forward = {
    type: typeof FormActionType.FORWARD,
    saveStep?: (step:ApplicationStep) => Promise<void>
}

type Back = {
    type: typeof FormActionType.BACK
}

type JumpBack = {
    type: typeof FormActionType.JUMP_BACK,
    step: ApplicationStep
}

type UpdateAccount = {
    type: typeof FormActionType.UPDATE_ACCOUNT
    account: ApplicationFormAccount
}

type UpdateSystem = {
    type: typeof FormActionType.UPDATE_SYSTEM
    system: SystemFormData
}

type CompleteSystem = {
    type: typeof FormActionType.COMPLETE_SYSTEM
}

type IncompleteSystem = {
    type: typeof FormActionType.INCOMPLETE_SYSTEM
}

type UpdateEquipment = {
    type: typeof FormActionType.UPDATE_EQUIPMENT
    equipment: EquipmentFormData
}

type UpdateContactInformation = {
    type: typeof FormActionType.UPDATE_CONTACT_INFORMATION
    contactInformation: ContactInformationFormData
}

type UpdateAdditionalComments = {
    type: typeof FormActionType.UPDATE_ADDITIONAL_COMMENTS,
    additionalComments: string
}

type CompleteEquipment = {
    type: typeof FormActionType.COMPLETE_EQUIPMENT
}

type IncompleteEquipment = {
    type: typeof FormActionType.INCOMPLETE_EQUIPMENT
}

type ApprovalCodeAndFees = {
    type: typeof FormActionType.APPROVAL_CODE_AND_FEES,
    approvalCodeAndFees: ApprovalCodeAndFeesData,
}

type AdditionalInformation = {
    type: typeof FormActionType.ADDITIONAL_INFORMATION,
    additionalInformation: AdditionalInformationData
}

type CompleteAdditionalInfo = {
    type: typeof FormActionType.COMPLETE_ADDITIONAL_INFORMATION
    complete: boolean
}

type UpdateAttachments = {
    type: typeof FormActionType.UPDATE_ATTACHMENTS
    attachments: AttachmentFormData
}

type CompleteAttachments = {
    type: typeof FormActionType.COMPLETE_ATTACHMENTS
}

type IncompleteAttachments = {
    type: typeof FormActionType.INCOMPLETE_ATTACHMENTS
}

type UpdateAccountPendingVerification = {
    type: typeof FormActionType.UPDATE_ACCOUNT_PENDING_VERIFICATION
    accountPendingVerification: ApplicationFormAccount
}

type EnableNext = {
    type: typeof FormActionType.ENABLE_NEXT
}

type DisableNext = {
    type: typeof FormActionType.DISABLE_NEXT
}

type ResetForm = {
    type: typeof FormActionType.RESET_FORM,
    initialState: ApplicationForm
}

type UpdateInterconnectApplicant = {
    type: typeof FormActionType.UPDATE_INTERCONNECT_APPLICANT,
    interconnectApplicant: InterconnectApplicantFormData
}

type CompleteContacts = {
    type: typeof FormActionType.COMPLETE_CONTACTS
}
type UpdatePaExtra = {
    type: typeof FormActionType.UPDATE_PA_EXTRA
    paAgreementExtra: PaAgreementExtraData
}
type CompletePaExtra = {
    type: typeof FormActionType.COMPLETE_PA_EXTRA
    complete: boolean
}


export type FormAction =
    LoadApplication |
    SetId |
    StartProcessing |
    EndProcessing |
    UpdateAccount |
    Forward |
    Back |
    JumpBack |
    UpdateSystem |
    CompleteSystem |
    IncompleteSystem |
    UpdateEquipment |
    CompleteEquipment |
    IncompleteEquipment |
    UpdateContactInformation |
    UpdateAdditionalComments |
    ApprovalCodeAndFees |
    AdditionalInformation |
    CompleteAdditionalInfo |
    UpdateAttachments |
    CompleteAttachments |
    IncompleteAttachments |
    UpdateAccountPendingVerification |
    EnableNext |
    DisableNext |
    ResetForm |
    UpdateInterconnectApplicant |
    CompleteContacts |
    UpdatePaExtra |
    CompletePaExtra

function next(applicationForm: ApplicationForm, saveStep?: (step:ApplicationStep) => Promise<void>): ApplicationStep {
    let order = applicationForm.steps
    let currentStep = applicationForm.currentStep;

    let currentIndex = order.indexOf(currentStep);

    if (currentIndex === -1) { throw new Error('something') }

    if (currentIndex === order.length) { return currentStep; }
    let newStep = order[currentIndex + 1];
    if (saveStep) {
        //we don't need to await the completion of this
        saveStep(newStep);
    }
    return newStep;
}

function back(applicationForm: ApplicationForm, newStep?: ApplicationStep): ApplicationStep {
    let order = applicationForm.steps
    let currentStep = applicationForm.currentStep;

    
    let currentIndex = order.indexOf(currentStep);
    let toIndex = newStep ? order.indexOf(newStep) : (currentIndex - 1);
    if (toIndex < 0 || toIndex > currentIndex) {
        console.error('Cannot go back to stage ' + toIndex);
        toIndex = currentIndex;
    }
    return order[toIndex];
}

export function applicationFormReducer(state: ApplicationForm | null, action: FormAction): ApplicationForm | null {
    if (action.type === FormActionType.LOAD_APPLICATION) {
        return {...ApplicationFormUtils.buildApplicationFormFromLoadedApplication(action.loadedApplication, action.isVendor)}
    }
    // if we're not trying to load the application but the application state is null then just return null
    if (state === null) {
        return null;
    }

    switch (action.type) {
        case FormActionType.START_PROCESSING                    : return {...state, processing : true}
        case FormActionType.END_PROCESSING                      : return {...state, processing : false}
        case FormActionType.SET_ID                              : return {...state, id : action.id}
        case FormActionType.FORWARD                             : return {...state, currentStep : next(state, action.saveStep), nextDisabled: true}
        case FormActionType.BACK                                : return {...state, currentStep : back(state), nextDisabled: true}
        case FormActionType.JUMP_BACK                           : return {...state, currentStep : back(state, action.step), nextDisabled: true}
        case FormActionType.UPDATE_ACCOUNT                      : return {...state, account : action.account, accountPendingVerification : undefined}
        case FormActionType.UPDATE_SYSTEM                       : return {...state, system : {...action.system}, systemComplete : false}
        case FormActionType.COMPLETE_SYSTEM                     : return {...state, systemComplete : true}
        case FormActionType.INCOMPLETE_SYSTEM                   : return {...state, systemComplete : false}
        case FormActionType.UPDATE_EQUIPMENT                    : return {...state, equipment : {...action.equipment}, equipmentComplete : false}
        case FormActionType.COMPLETE_EQUIPMENT                  : return {...state, equipmentComplete : true}
        case FormActionType.INCOMPLETE_EQUIPMENT                : return {...state, equipmentComplete : false}
        case FormActionType.UPDATE_CONTACT_INFORMATION          : return {...state, contactInformationContainer : {...action.contactInformation}, contactsComplete: false}
        case FormActionType.APPROVAL_CODE_AND_FEES              : return {...state, approvalCodeAndFees: {...action.approvalCodeAndFees}}
        case FormActionType.ADDITIONAL_INFORMATION              : return {...state, additionalInformation: {...action.additionalInformation}}
        case FormActionType.COMPLETE_ADDITIONAL_INFORMATION     : return {...state, additionalInfoComplete : action.complete}
        case FormActionType.UPDATE_ADDITIONAL_COMMENTS          : return {...state, additionalComments: action.additionalComments}
        case FormActionType.UPDATE_ATTACHMENTS                  : return {...state, attachments : {...action.attachments}}
        case FormActionType.COMPLETE_ATTACHMENTS                : return {...state, attachmentsComplete : true}
        case FormActionType.INCOMPLETE_ATTACHMENTS              : return {...state, attachmentsComplete : false}
        case FormActionType.UPDATE_ACCOUNT_PENDING_VERIFICATION : return {...state, accountPendingVerification : action.accountPendingVerification, account : undefined}
        case FormActionType.ENABLE_NEXT                         : return {...state, nextDisabled : false}
        case FormActionType.DISABLE_NEXT                        : return {...state, nextDisabled : true}
        case FormActionType.RESET_FORM                          : return {...action.initialState}
        case FormActionType.UPDATE_INTERCONNECT_APPLICANT       : return {...state, interconnectApplicant : action.interconnectApplicant}
        case FormActionType.COMPLETE_CONTACTS                   : return {...state, contactsComplete : true}
        case FormActionType.UPDATE_PA_EXTRA                     : return {...state, paAgreementExtra : {...action.paAgreementExtra}}
        case FormActionType.COMPLETE_PA_EXTRA                   : return {...state, attachmentsComplete : action.complete}
        default                                                 : return state;
    }
}