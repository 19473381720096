import {BaseApplicationStepProps} from "../application/Application";
import {StyledOption, StyledSelect} from "../../styledcomponents/FormStyledComponents";
import React from "react";
import styled from "styled-components";
import {useAuth} from "../../auth/AuthProvider";
import {AccountInfo} from "../../models/AccountInfo";

const AccountStyledSelect = styled(StyledSelect)`
  width : 33%;
`

type ApplicationFormAccountSelectProps = BaseApplicationStepProps & {
    setSelectedAccount : (data : AccountInfo) => void
}

type DefaultAccountSelectProps = Partial<BaseApplicationStepProps> & {
    setSelectedAccount ?: never
}

type AccountSelectProps = ApplicationFormAccountSelectProps | DefaultAccountSelectProps

export function AccountSelect(props : AccountSelectProps) {
    const auth = useAuth();

    function onChangeAccountSelect(selectedAccountJson : string) {
        let selectedAccount = JSON.parse(selectedAccountJson);

        if (props.setSelectedAccount) {
            props.setSelectedAccount(selectedAccount);
        } else {
            auth.updateCurrentAccount(selectedAccount);
        }
    }

    return (
        <AccountStyledSelect onChange={(event) => onChangeAccountSelect(event.currentTarget.value)} defaultValue={JSON.stringify(auth.user?.currentAccount)}>
            {
                auth.user?.accounts?.map((accountInfo : AccountInfo) =>
                    <StyledOption key={accountInfo.accountNumber} value={JSON.stringify(accountInfo)}>
                        {accountInfo.accountNumber}, {accountInfo.addressLine2}
                    </StyledOption>
                )
            }
        </AccountStyledSelect>
    )
}