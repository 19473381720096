import {
    Message, MessageType,
    useMessageService
} from "../../hooks/useMessageService";
import React, {ReactElement, useEffect, useRef} from "react";
import styled from "styled-components";
import {
    MessageListItem,
    MessageList,
    AlertIcon,
    AlertContainer,
    MessageContainerLeft, MessageContainerRight
} from "../../styledcomponents/AlertStyledComponents";
import WarningAlert from "../../assets/images/alerts/WarningAlert.svg";
import SuccessAlert from "../../assets/images/alerts/SuccessAlert.svg";
import ErrorAlert from "../../assets/images/alerts/ErrorAlert.svg";
import InfoAlert from "../../assets/images/alerts/InfoAlert.svg";

export const CloseMessageButton = styled.span`
  cursor: pointer;
  opacity: .6;
  &:hover {
    opacity: 1;
  }
`

const Messages = styled.div`
  grid-area: messages;
  margin: 0 auto;
  width: 100%;
  max-width: 944px;
`

type MessageDisplayProps = {
    type : MessageType
    cannotClose ?: boolean
    noMargin ?: boolean
} & ({
    children : ReactElement
    messages ?: never
} | {
    children ?: never
    messages : Message[]
})

export function MessageDisplay(props : MessageDisplayProps) {
    const messageService = useMessageService();

    const [icon, setIcon] = React.useState<string>();

    React.useEffect(() => {
        switch (props.type) {
            case "success" : setIcon(SuccessAlert); break;
            case "warning" : setIcon(WarningAlert); break;
            case "error"   : setIcon(ErrorAlert); break;
            case "info"    : setIcon(InfoAlert); break;
        }
    }, [props.type])

    return (
        <AlertContainer noMargin={props.noMargin}>
            <MessageContainerLeft type={props.type}>
                <AlertIcon src={icon} alt={"alert icon"} />
            </MessageContainerLeft>
            <MessageContainerRight type={props.type}>
                {
                    props.messages
                        ? <MessageList>
                            {
                                props.messages.map((message : Message) => {
                                    if (message.content) { return <li style={{listStyleType : 'none'}}>{message.content}</li> }

                                    return <MessageListItem key={message.id}>{message.text}</MessageListItem>
                                })
                            }
                        </MessageList>
                        : props.children
                }
                {
                    !props.cannotClose &&
                    <CloseMessageButton onClick={() => messageService.removeMessageByType(props.type)}>X</CloseMessageButton>
                }
            </MessageContainerRight>
        </AlertContainer>
    )
}

export function MessageContainer() {
    const messageService = useMessageService();

    const errorMessages   = messageService.messages.filter((message : Message) => { return message.type === 'error' });
    const successMessages = messageService.messages.filter((message : Message) => { return message.type === 'success' });
    const warningMessages = messageService.messages.filter((message : Message) => { return message.type === 'warning' });
    const infoMessages    = messageService.messages.filter((message : Message) => { return message.type === 'info' });

    const messageDivRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (messageDivRef.current && (errorMessages.length > 0 || successMessages.length > 0 || warningMessages.length > 0 || infoMessages.length > 0)) {
            messageDivRef.current.scrollIntoView({behavior: 'smooth'})
        }
    }, [errorMessages, successMessages, warningMessages, infoMessages])

    return (
        <Messages ref={messageDivRef}>
            {
                errorMessages.length > 0 &&
                <MessageDisplay messages={errorMessages} type={'error'} />
            }
            {
                /* We don't let them close these messages because they will be automatically removed after 5 seconds */
                successMessages.length > 0 &&
                <MessageDisplay messages={successMessages} type={'success'} cannotClose={true}/>
            }
            {
                warningMessages.length > 0 &&
                <MessageDisplay messages={warningMessages} type={'warning'} />
            }
            {
                infoMessages.length > 0 &&
                <MessageDisplay messages={infoMessages} type={'info'} />
            }
        </Messages>
    )
};