import React, {useEffect} from "react";
import {BaseApplicationStepProps} from "../Application";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {Form} from "../../../styledcomponents/FormStyledComponents";
import {FormActionType} from "../helpers/applicationFormReducer";
import GreyBanner from "../../misc/GreyBanner";
import {ApplicationStepButtons} from "../helpers/ApplicationStepButtons";
import {ServiceAddressForm} from "./interconnectApplicant/ServiceAddressForm";
import {AccountHolderForm} from "./interconnectApplicant/AccountHolderForm";
import {InterconnectApplicantFormData} from "./interconnectApplicant/types/InterconnectApplicantTypes";

type NoAccountFormProps = BaseApplicationStepProps & {
}

export function NoAccountForm(props : NoAccountFormProps) {
    const methods = useForm<InterconnectApplicantFormData>({
        defaultValues : {
            locationHome : props.application?.interconnectApplicant?.locationHome?.toString() ?? true.toString(),
            businessName : props.application?.interconnectApplicant?.businessName ?? '',
            firstName : props.application?.interconnectApplicant?.firstName ?? '',
            lastName : props.application?.interconnectApplicant?.lastName ?? '',
            primaryPhone : props.application?.interconnectApplicant?.primaryPhone ?? '',
            alternatePhone : props.application?.interconnectApplicant?.alternatePhone ?? '',
            email : props.application?.interconnectApplicant?.email ?? '',
            operatingCompanyAbbreviation : props.application?.interconnectApplicant?.operatingCompanyAbbreviation ?? '',
            serviceAddress : {
                developmentName : props.application?.interconnectApplicant?.serviceAddress?.developmentName ?? '',
                lot : props.application?.interconnectApplicant?.serviceAddress?.lot ?? '',
                block : props.application?.interconnectApplicant?.serviceAddress?.block ?? '',
                houseNumber : props.application?.interconnectApplicant?.serviceAddress?.houseNumber ?? '',
                street : props.application?.interconnectApplicant?.serviceAddress?.street ?? '',
                ruralRoute : props.application?.interconnectApplicant?.serviceAddress?.ruralRoute ?? '',
                city : props.application?.interconnectApplicant?.serviceAddress?.city ?? '',
                state : props.application?.interconnectApplicant?.serviceAddress?.state ?? '',
                zipCode : props.application?.interconnectApplicant?.serviceAddress?.zipCode ?? ''
            }
        },
        mode: "onTouched"
    });

    const {getValues, formState : {isValid}} = methods;

    // whenever the form is valid, make sure next is enabled. If it is invalid then make sure next is disabled
    React.useEffect(() => {
        if (isValid) {
            props.applicationFormsDispatch({type: FormActionType.ENABLE_NEXT});
        } else {
            props.applicationFormsDispatch({type: FormActionType.DISABLE_NEXT});
        }
    }, [isValid])

    function onNext() {
        // If successful then change state to show checkmark for system
        props.applicationFormsDispatch({type: FormActionType.UPDATE_INTERCONNECT_APPLICANT, interconnectApplicant : getValues()});
        props.applicationFormsDispatch({type : FormActionType.FORWARD});
    }

    return (
        <React.Fragment>
            <FormProvider {...methods}>
                <Form>
                    <p>
                        Please provide information regarding the customer (account holder)
                        who will be responsible for paying the electric bill at the service address.
                    </p>
                    <GreyBanner title={'Account Holder'} />
                    <AccountHolderForm canChooseOperatingCompany={true} operatingCompanyId={props.application?.account?.operatingCompanyId} canChooseEmail={true}/>

                    <br />

                    <GreyBanner title={'Service Address'} />
                    <ServiceAddressForm object={'serviceAddress'}/>
                </Form>
            </FormProvider>
            <ApplicationStepButtons {...props} triggerValidation={methods.trigger} onNext={onNext}/>
        </React.Fragment>
    )
}