import {GridColumnContainer} from "../../../../styledcomponents/MiscStyledComponents";
import {Label, StyledOption} from "../../../../styledcomponents/FormStyledComponents";
import {Input, Select} from "../../../form/FormElements";
import {Pattern} from "../../../../enums/Pattern";
import {EligibleStates} from "../../../../enums/State";
import React from "react";
import {FormProvider, useFormContext} from "react-hook-form";
import {ServiceAddressFormData} from "./types/InterconnectApplicantTypes";

type ServiceAddressFormProps = {
    object ?: string
}

export function ServiceAddressForm(props : ServiceAddressFormProps) {
    const methods = useFormContext<ServiceAddressFormData>();
    let {watch, formState : {errors}} = methods
    let objectName = props.object ? `${props.object}.` : ''
    if (props.object) {
        //@ts-ignore
        errors = errors[props.object];
    }

    //@ts-ignore
    let watchRuralRoute = watch(`${objectName}ruralRoute`)
    //@ts-ignore
    let watchStreet = watch(`${objectName}street`)


    return (
        <FormProvider {...methods}>
            <GridColumnContainer columns={2}>
                <div>
                    <Label htmlFor={`${objectName}developmentName`} >Development Name (Optional)</Label>
                    <Input name={`${objectName}developmentName`} maxLength={40} error={errors?.developmentName}/>
                </div>
                <GridColumnContainer columns={2}>
                    <div>
                        <Label htmlFor={`${objectName}lot`} >Lot (Optional)</Label>
                        <Input name={`${objectName}lot`} maxLength={8} error={errors?.lot}/>
                    </div>
                    <div>
                        <Label htmlFor={`${objectName}block`} >Block (Optional)</Label>
                        <Input name={`${objectName}block`} pattern={Pattern.Block} maxLength={8} error={errors?.block}/>
                    </div>
                </GridColumnContainer>
            </GridColumnContainer>
            <GridColumnContainer columns={2}>
                <div>
                    <Label htmlFor={`${objectName}houseNumber`} >House Number (Optional)</Label>
                    <Input name={`${objectName}houseNumber`} pattern={Pattern.HouseNumber} maxLength={10} error={errors?.houseNumber}/>
                </div>
                <div>
                    {/*If rural route is entered then street is optional*/}
                    <Label htmlFor={`${objectName}street`} >Street {watchRuralRoute ? '(Optional)' : ''}</Label>
                    <Input name={`${objectName}street`} required={!watchRuralRoute} pattern={Pattern.Street} maxLength={40} error={errors?.street}/>
                </div>
            </GridColumnContainer>
            <GridColumnContainer columns={2}>
                <div>
                    {/*If street is entered then rural route is optional*/}
                    <Label htmlFor={`${objectName}ruralRoute`} >Rural Route {watchStreet ? '(Optional)' : ''}</Label>
                    <Input name={`${objectName}ruralRoute`} required={!watchStreet} pattern={Pattern.RuralRoute} maxLength={40} error={errors?.ruralRoute} />
                </div>
            </GridColumnContainer>
            <GridColumnContainer columns={2}>
                <div>
                    <Label htmlFor={`${objectName}city`} >City</Label>
                    <Input name={`${objectName}city`} required={true} pattern={Pattern.City} maxLength={28} error={errors?.city}/>
                </div>
                <GridColumnContainer columns={2}>
                    <div>
                        <Label htmlFor={`${objectName}state`}>State</Label>
                        <Select name={`${objectName}state`} required={true} error={errors?.state}>
                            <StyledOption key={''} value={''}>Select One</StyledOption>
                            {
                                Object.values(EligibleStates).map(state => <StyledOption key={state}>{state}</StyledOption>)
                            }
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor={`${objectName}zipCode`}>Zip Code</Label>
                        <Input name={`${objectName}zipCode`} required={true} pattern={Pattern.ZipCode} error={errors?.zipCode} maxLength={5} enforceMaxLength/>
                    </div>
                </GridColumnContainer>
            </GridColumnContainer>
        </FormProvider>
    )
}