import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAxios} from "../../../../AxiosProvider";
import {InterconnectApplicantStep, InterconnectApplicantStepIcons} from "../../helpers/InterconnectApplicantStepIcons";
import AccountInformation from "./AccountInformation";
import ServiceInformation from "./ServiceInformation";
import ConnectionInformation from "./ConnectionInformation";
import {InterconnectApplicantFormData} from "./types/InterconnectApplicantTypes";
import ReviewAndSubmit from "./ReviewAndSubmit";

export type InterconnectApplicantButtonProps = {
    setCurrentStep : (data : InterconnectApplicantStep) => void
    currentStep : InterconnectApplicantStep
    steps : InterconnectApplicantStep[]
}

export type InterconnectApplicantStepProps = InterconnectApplicantButtonProps & {
    interconnectApplicantForm : InterconnectApplicantFormData | null
    setInterconnectApplicantForm : (data : InterconnectApplicantFormData) => void
}

export default function InterconnectApplicantForm() {
    const axios = useAxios();
    const { applicationId } = useParams();
    const [alreadyCompleted, setAlreadyCompleted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState<InterconnectApplicantStep>(InterconnectApplicantStep.AccountInfo)

    const [interconnectApplicantForm, setInterconnectApplicantForm] = useState<InterconnectApplicantFormData | null>(null);

    const steps = [
        InterconnectApplicantStep.AccountInfo,
        InterconnectApplicantStep.ServiceInfo,
        InterconnectApplicantStep.ConnectionInfo,
        InterconnectApplicantStep.ReviewAndSubmit
    ]

    useEffect(() => {
        setAlreadyCompleted(false);
        axios?.openApi.get(
            `/api/noAccount/application/getInterconnectApplicant/${applicationId}`
        ).then(response => {
            setInterconnectApplicantForm(response.data)
        }).catch(e => {
            if (e?.response?.data?.message?.includes("correct status")) {
                setAlreadyCompleted(true);
            }
        }).finally(() => {
            setLoading(false);
        })
    }, [applicationId])

    let content;
    switch (currentStep) {
        case InterconnectApplicantStep.AccountInfo:
            content =
                <AccountInformation interconnectApplicantForm={interconnectApplicantForm}
                                    setInterconnectApplicantForm={setInterconnectApplicantForm}
                                    setCurrentStep={setCurrentStep}
                                    currentStep={currentStep}
                                    steps={steps}
                />;
            break;
        case InterconnectApplicantStep.ServiceInfo:
            content =
                <ServiceInformation interconnectApplicantForm={interconnectApplicantForm}
                                    setInterconnectApplicantForm={setInterconnectApplicantForm}
                                    setCurrentStep={setCurrentStep}
                                    currentStep={currentStep}
                                    steps={steps}
                />;
            break;
        case InterconnectApplicantStep.ConnectionInfo:
            content =
                <ConnectionInformation interconnectApplicantForm={interconnectApplicantForm}
                                       setInterconnectApplicantForm={setInterconnectApplicantForm}
                                       setCurrentStep={setCurrentStep}
                                       currentStep={currentStep}
                                       steps={steps}
                />;
            break;
        case InterconnectApplicantStep.ReviewAndSubmit:
            content =
                <ReviewAndSubmit interconnectApplicantForm={interconnectApplicantForm}
                                 setInterconnectApplicantForm={setInterconnectApplicantForm}
                                 setCurrentStep={setCurrentStep}
                                 currentStep={currentStep}
                                 steps={steps}
                                 applicationId={applicationId!}
                />;
            break;
        default: content = <div>Could not load step</div>
    }

    if (loading) {
        return <div>Loading...</div>
    }

    if (!interconnectApplicantForm) {
        return alreadyCompleted
            ? <p>Additional information complete, please await further instructions via email.</p>
            : <p>Data not found or no longer available</p>
    }

    return (
        <React.Fragment>
            <InterconnectApplicantStepIcons currentStep={currentStep} stepOrder={steps} setCurrentStep={setCurrentStep} />
            <br />
            {content}
        </React.Fragment>
    )

}