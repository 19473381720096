import { decode } from "base64-arraybuffer";
import { State } from "../enums/State";
import { getBackendHost } from "./externalUrl";
import ExternalLink from "../components/misc/ExternalLink";

const agreementUrls = [    
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/oh/OH-Level-1-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/oh/OH-Level-23-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/pa/PA-Level-1-Interconnection-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/pa/PA-Level-234-Interconnection-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/wv/WV-Level-1-Interconnection-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/wv/WV-Level-2-Interconnection-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/md/MD-Level-1-Interconnection-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/md/MD-Level-234-Interconnectiont-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/nj/NJ-Level-1-Interconnection-Portal-App-Signature.pdf',
    'http://firstenergycorp.com/content/dam/feconnect/files/retail/nj/NJ-Level-2-Interconnection-Portal-App-Signature.pdf',
]

const prefilledAgreements = [
    "PA"
]

export type JwtStatus = {
    payload ?: any
    isExpired ?: boolean
    isBroken ?: boolean
    isMissing ?: boolean
    isValid ?: boolean
}

export function parseJwt(token: string) : JwtStatus {
    if (!token) {
        return {isMissing: true};
    }
    let status: JwtStatus = {};
    try {
        status.payload = JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        console.log(e);
        status.isBroken = true;
        return status;
    }
    if (new Date(status.payload.exp * 1000).getTime() < new Date().getTime() ) {
        status.isExpired = true;
    } else {
        status.isValid = true;
    }
    return status;
  }

/**Get the T&C agreement URL based on state and level. */
export const getAgreementUrl = function(state: string, level: string, applicationId: string) {
    //If the agreement needs to be prefilled first, fetch from the server
    if (prefilledAgreements.includes(state)) {
        return `${getBackendHost()}/api/agreement/agreement.pdf?applicationId=${applicationId}`
    }
    //If the agreement URLs become more disparate in the future, we can switch to a nested map structure to define the mapping explicitly.
    let searchString = new RegExp(`${state.toLowerCase()}/${state.toUpperCase()}-Level-.{0,3}${Number(level)}`);
    return agreementUrls.find(u => searchString.test(u));
}

export const getAgreementContent = function(state: string, level: string, signatureBlock: (index: number, agreementLanguage: string) => JSX.Element) {
    const isLevel2 = level !== '01';
    let agreementStatement = ''; 
    switch(state) {
        case State.NJ: 
            if (!isLevel2) { // NJ level 1
                agreementStatement = `1) I have read and understand the Terms and Conditions
                    which are attached hereto by reference and are made a part of this Application/Agreement. \n2); The Equipment
                    Installation Contractor is acting on behalf of the Customer-Generator and the EDC (as defined); is authorized to
                    act in reliance upon the Equipment Installation Contractor’s relationship with the Customer-Generator. \n3); The
                    Applicant shall notify the EDC of any changes to the proposed Customer-Generator Facility that would be
                    subject to the criteria for a Level 1 review (e.g., Equipment Installation Contractor, inverter manufacturer/model number, size, etc.);. \n4); Once an Interconnection Request is deemed complete, any modification to the proposed
                    Customer-Generator Facility that would affect the application review criteria for a Level 1 review that is not
                    agreed to in writing by the EDC, shall require submission of a new Interconnection Request. and \n5); To the best
                    of my knowledge, all of the information provided in this Interconnection Application/Agreement is true and I
                    agree to abide by the attached Terms and Conditions for Interconnection, including the application process set
                        forth therein.`.replace(/  +/g, ' ');
                return <> <p>
                    <ExternalLink href={'https://www.firstenergycorp.com/content/dam/feconnect/files/retail/nj/NJ-Level-1-Interconnection-Application-Agreement.pdf'}>
                        View New Jersey Interconnection Level 1 Terms and Conditions
                    </ExternalLink><br/><br/>
                    <b>Customer-Generator Insurance Disclosure:</b><br/> The attached Terms and Conditions contain provisions related to
                    liability, and indemnification and should be carefully considered by the Customer-Generator. The Customer-
                    Generator is not required to obtain liability insurance coverage as part of this Application/Agreement; however,
                    the Customer-Generator is advised to consider obtaining appropriate coverage.<br/><br/>
                    <b>Customer-Generator Signature:</b><br/> I hereby certify that: <br/>
                    {agreementStatement}
                    </p>
                    {signatureBlock(0, agreementStatement)}
                </>
            } else { //NJ level 2+
                agreementStatement = `I hereby certify that all of the information provided in this application request form is true.`.replace(/  +/g, ' ');
                let termsAndConditionsAgreementStatement = `I hereby certify that: 1) I have read and understand the Terms and Conditions which are attached
                    hereto by reference and are made a part of this Agreement. 2) The Equipment Installation Contractor
                    may be acting on behalf of the Customer-Generator and JCP&L (as defined) is authorized to act in
                    reliance upon the Equipment Installation Contractor’s relationship with the Customer-Generator. 3)
                    The Applicant shall notify JCP&L of any changes to the proposed Customer-Generator Facility that
                    would be subject to the criteria for the Level of review (e.g., Electrical Contractor / Installer, Inverter
                    Manufacturer/Model Number, size, etc.). 4) Once an Interconnection Request is deemed complete,
                    any modification to the proposed Customer-Generator Facility that would affect the application review
                    criteria for the Level of review that is not agreed to in writing by JCP&L, shall require submission of a
                    new Interconnection Request. and 5) To the best of my knowledge, all of the information provided in
                    this Interconnection Application/Agreement is true and I agree to abide by the attached Terms and
                    Conditions for Interconnection, including the application process set forth therein.`.replace(/  +/g, ' ');
                return <>
                    <p>
                        <ExternalLink href={'https://www.firstenergycorp.com/content/dam/feconnect/files/retail/nj/NJ-Level-23-Interconnection-Agreement-No-Form.pdf'}>
                            View New Jersey Interconnection Level 2, 3 Terms and Conditions
                        </ExternalLink><br/><br/>
                        {termsAndConditionsAgreementStatement}
                    </p>
                    {signatureBlock(0, termsAndConditionsAgreementStatement)}
                    <p> 
                        {agreementStatement} 
                    </p>
                    {signatureBlock(1, agreementStatement)}
                </>
            }
        
        case State.OH: 
        agreementStatement = 'I hereby certify that, to the best of my knowledge, all the information provided in the Interconnection Application is true and correct.';
        return <>
            {level === '03' && <><p>
                <b>Customer-Generator Equipment Information for Parallel Rotating Equipment Based Systems</b><br/>
                (May be applicable to a Level 3 Review)<br/><br/>
                It is anticipated that many projects proposing to utilize directly coupled rotating generation may not have the specific information necessary for the EDC to adequately evaluate the impact of the proposed facility on the EDC’s electrical distribution system at the time of the initial application. Often times the equipment for which this information is needed hasn’t been specified. The type information necessary may be conveyed during a scoping meeting or other correspondence early on during the project development. Depending on the nature of the project, this is often an iterative process. Different EDC’s analytical systems may require that data be provided conforming to specific standard formats which will be conveyed by the EDC. While not all inclusive, examples of the information commonly required are as follows:
                <br/><br/><b>For Synchronous Machines:</b> Copies of the Saturation Curve and the Vee Curve - Salient vs. Non-Salient - Torque: (lb-ft) - Rated RPM - Field Amperes at rated generator voltage and current and % PF over-excited - Maximum Leading and Lagging Reactive Output Power - Type of Exciter - Output Power of Exciter - Type of Voltage Regulator - Direct-axis Synchronous Reactance (Xd) ohms - Direct-axis Transient Reactance (X'd) ohms - Direct-axis Sub-transient Reactance (X"d) ohms - Rated Nominal Frequency
                <br/><br/><b>For Induction Machines:</b> Rotor Resistance (Rr) ohms - Exciting Current (Amps) - Rotor Reactance (Xr) (ohms) - VARs (No Load) - Magnetizing Reactance (Xm) - Stator Resistance (Rs) - VARs (Full Load) - Stator Reactance (Xs) – Short Circuit Reactance (X"d) - Number of Phases - Frame Size - Design Letter - Temp. Rise °C
                <br/><br/><b>Protective Equipment:</b> The Customer Generator shall design a protective scheme that will provide the protective functions specified in IEEE 1547 and submit it to the EDC for review & acceptance. The submittal shall include a single line drawing showing the location of instrument transformers (current and voltage) and the location of the relays, breakers and fuses. Indicate the manufacturer and model number of each type of device. Breaker data shall include continuous and interrupting ampere ratings. If relays are used, indicate function, the tripping source and its voltage.
                Isolation Transformer: Manufacturer - Manufacturer reference number - Nominal Voltage Ratio – High / Low Voltage Taps - Number of Units - Rated kVA – Percentage Impedance @ kVA base – High / Low Voltage Winding Configuration
            </p></>}
            <p>
                <b>{isLevel2 ?  'Customer-Generator' : 'Electric Utility Account Customer'} Signature:</b><br/> {agreementStatement}
            </p>
            {signatureBlock(0, agreementStatement)}
        </>

        case State.MD: 
            if (!isLevel2) { //MD level 1
                agreementStatement = `I hereby certify that: 1); I have read and understand the terms and conditions which are attached
                    hereto by reference and are a part of this Agreement; 2); I hereby agree to comply with the
                    attached terms and conditions; and 3); to the best of my knowledge, all of the information
                    provided in this application request form is complete and true. I consent to permit the PSC and
                    interconnecting utility to exchange information regarding the generating system to which this
                    application applies.`.replace(/  +/g, ' ');
                return <><p><b>Insurance Disclosure</b><br/>
                        The attached terms and conditions contain provisions related to liability and
                        indemnification, and should be carefully considered by the interconnection customer.
                        The interconnection customer is not required to obtain general liability insurance
                        coverage as a precondition for interconnection approval; however, the interconnection
                        customer is advised to consider obtaining appropriate insurance coverage to cover the
                        interconnection customer’s potential liability under this agreement.<br/><br/>
                        <b>Customer Signature</b><br/>
                        {agreementStatement}
                    </p>
                    {signatureBlock(0, agreementStatement)}
                </>
            } else { //MD level 2+
                agreementStatement =`I hereby certify that: to the best of my knowledge, all of the information
                    provided in this application request form is complete and true. I consent to permit the PSC and
                    interconnecting utility to exchange information regarding the generating system to which this
                    application applies.`.replace(/  +/g, ' ');
                return <>
                    <p>
                        {agreementStatement}
                    </p>
                    {signatureBlock(0, agreementStatement)}
                </>
            }
    

        case State.NY:
        case State.WV: 
        agreementStatement = 'I hereby certify that all of the information provided in this application request form is true.'
        return <>
            <p>
                <b>Customer-Generator Signature:</b><br/> {agreementStatement}
            </p>
            {signatureBlock(0, agreementStatement)}
        </>

    }
}

export const getTermsAndConditionsContent = function(state: string, level: string) {
    const isLevel2 = level !== '01'; 
    switch(state) {
        case 'MD': return isLevel2 ? null : <>
        <b>Terms and Conditions for Maryland Interconnection</b><br/>
            <br/>1&#41;<b> Construction of the Small Generator Facility.</b> The Interconnection Customer may proceed
            to construct &#40;including operational testing not to exceed 2 hours&#41; the Small Generator Facility
            once the Conditional Agreement to Interconnect a Small Generator Facility on the preceding
            page has been signed by the EDC.
            <br/>2&#41;<b> Final Interconnection and Operation.</b> The Interconnection Customer may operate the
            Small Generator Facility and interconnect with the EDC’s Electric Distribution System after
            all of the following have occurred:
            a&#41; Electrical Inspection: Upon completing construction, the Interconnection Customer will
            cause the Small Generator Facility to be inspected by the local electrical wiring inspector
            with jurisdiction who shall establish that the Small Generator Facility meets the
            requirements of the National Electrical Code.
            b&#41; Certificate of Completion: The Interconnection Customer shall provide the EDC with a
            completed copy of the Interconnection Agreement Certificate of Completion, including
            evidence of the electrical inspection performed by the local authority having jurisdiction.
            The evidence of completion of the electrical inspection may be provided on inspection
            forms used by local inspecting authorities. The Interconnection request shall not be finally
            approved until the EDC’s representative signs the Interconnection Agreement Certificate
            of Completion.
            c&#41; EDC has either waived the right to a Witness Test in the Interconnection Request, or
            completed its Witness Test as per the following:
            i&#41; Within five &#40;5&#41; business days of the estimated commissioning date, the EDC may,
            upon reasonable notice and at a mutually convenient time, conduct a Witness Test of
            the Small Generator Facility to ensure that all equipment has been appropriately
            installed and that all electrical connections have been made in accordance with
            applicable codes;
            ii&#41; If the EDC does not perform the Witness Test within the 5-day period or such other
            time as is mutually agreed to by the parties, the Witness Test is deemed waived.
            <br/>3&#41;<b> IEEE 1547.</b> The Small Generator Facility is installed operated and tested in accordance with
            the requirements of IEEE standard 1547, “Standard for Interconnecting Distributed
            Resources with Electric Power Systems”, as amended and supplemented, at the time the
            interconnection request is submitted.
            <br/>4&#41;<b> Access.</b> The EDC shall have direct, unabated access to the disconnect switch and metering
            equipment of the Small Generator Facility at all times. The EDC shall provide reasonable
            notice to the customer when possible prior to using its right of access.
            <br/>5&#41;<b> Metering.</b> Any required metering shall be installed pursuant to appropriate tariffs and tested
            by the EDC pursuant to the EDC’s meter testing requirements pursuant to the Code of
            Maryland Regulations &#40;COMAR&#41;
            <br/>6&#41;<b> Disconnection.</b> The EDC may temporarily disconnect the Small Generator Facility upon the
            following conditions:
            a&#41; For scheduled outages upon reasonable notice;
            b&#41; For unscheduled outages or emergency conditions;
            c&#41; If the Small Generator Facility does not operate in the manner consistent with this
            Agreement;
            d&#41; Improper installation or failure to pass the Witness Test;
            e&#41; If the Small Generator Facility is creating a safety, reliability or a power quality problem;
            or
            f&#41; The Interconnection Equipment used by the Small Generator Facility is de-listed by the
            Nationally Recognized Testing Laboratory that provided the listing at the time the
            interconnection was approved.
            <br/>7&#41;<b> Indemnification.</b> The parties shall at all times indemnify, defend, and save the other party
            harmless from any and all damages, losses, claims, including claims and actions relating to
            injury to or death of any person or damage to property, demand, suits, recoveries, costs and
            expenses, court costs, attorney fees, and all other obligations by or to third parties, arising
            out of or resulting from the other party's performance or failure to perform under this
            Agreement on behalf of the indemnifying party, except in cases of gross negligence or
            intentional wrongdoing by the indemnified party.
            <br/>8&#41;<b> Limitation of Liability.</b> Each party’s liability to the other party for any loss, cost, claim,
            injury, liability, or expense, including reasonable attorney’s fees, relating to or arising from
            any act or omission in its performance of this Agreement, shall be limited to the amount of
            direct damage actually incurred. In no event shall either party be liable to the other party for
            any indirect, incidental, special, consequential, or punitive damages of any kind whatsoever.
            <br/>9&#41;<b> Termination.</b> This Agreement may be terminated under the following conditions:
            a&#41; By Interconnection Customer - The Interconnection Customer may terminate this
            application agreement by providing written notice to the EDC.
            b&#41; By the EDC - The EDC may terminate this Agreement if the Interconnection Customer
            fails to remedy a violation of terms of this Agreement within 30 calendar days after notice,
            or such other date as may be mutually agreed to prior to the expiration of the 30 calendar
            day remedy period. The termination date can be no less than 30 calendar days after the
            Interconnection Customer receives notice of its violation from the EDC.
            <br/>10&#41;<b> Modification of Small Generator Facility.</b> The Interconnection Customer must receive
            written authorization from the EDC before making any changes to the Small Generator
            Facility, other than minor changes that do not have a significant impact on safety or reliability
            of the Electric Distribution System as determined by the EDC. If the Interconnection
            Customer makes such modifications without the EDC’s prior written authorization, the EDC
            shall have the right to temporarily disconnect the Small Generator Facility.
            <br/>11&#41;<b> Permanent Disconnection.</b> In the event the Agreement is terminated, the EDC shall have
            the right to disconnect its facilities or direct the customer to disconnect its Small Generator
            Facility.
            <br/>12&#41;<b> Disputes.</b> Each party agrees to attempt to resolve all disputes regarding the provisions of
            these interconnection procedures pursuant to the dispute resolution provisions of the
            Maryland Standard Small Generator Interconnection Rules.
            <br/>13&#41;<b> Governing Law, Regulatory Authority, and Rules.</b> The validity, interpretation and
            enforcement of this Agreement and each of its provisions shall be governed by the laws of
            the State of Maryland. Nothing in this Agreement is intended to affect any other agreement
            between the EDC and the Interconnection Customer. However, in the event that the
            provisions of this agreement are in conflict with the provisions of the EDC’s tariff, the EDC
            tariff shall control.
            <br/>14&#41;<b> Survival Rights.</b> This Agreement shall continue in effect after termination to the extent
            necessary to allow or require either party to fulfill rights or obligations that arose under the
            Agreement.
            <br/>15&#41;<b> Assignment/Transfer of Ownership of the Small Generator Facility.</b> This Agreement
            shall terminate upon the transfer of ownership of the Small Generator Facility to a new
            Eligible Customer Generator &#40;owner or tenant&#41;, unless the new Eligible Customer Generator
            notifies the EDC of the change, their agreement to abide by the Terms and Conditions of the
            original Interconnection Agreement, and so notifies the EDC in writing prior to or coincident
            with the transfer of electric service to the new customer. Should an interconnection
            agreement terminate for failure of a new customer to provide appropriate written agreement
            within 30 days, the EDC shall notify the Public Service Commission the Interconnection
            Agreement has been terminated.
            <br/>16&#41;<b> Definitions.</b> Any capitalized term used herein and not defined shall have the same meaning
            as the defined terms used in the Maryland Standard Small Generator Interconnection Rule.
            <br/>17&#41;<b> Notice.</b> Unless otherwise provided in this Agreement, any written notice, demand, or request
            required or authorized in connection with this Agreement &#40;“Notice”&#41; shall be deemed properly
            given if delivered in person, delivered by recognized national courier service, or sent by first
            class mail, postage prepaid, to the person specified below:
            If to Interconnection Customer:
            Use the contact information provided in the Agreement for the Interconnection Customer. The
            Interconnection Customer is responsible for notifying the EDC of any change in the contact
            party information, including change of ownership.
            If to EDC:
            Use the contact information provided on the EDC’s web page for small generator
            interconnection.
        </>
        default: return null; 
    }
}