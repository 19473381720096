import React from 'react';
import {Link, LinkProps} from "react-router-dom";
import {PrimaryButton, SecondaryButton,StyledLinkSecondaryButton} from "../../styledcomponents/FormStyledComponents";

type ButtonLinkProps = LinkProps & {
    disabled ?: boolean
}

function ButtonLink(props : ButtonLinkProps & {type : 'primary' | 'secondary'|'link'}) {
    return(
        <Link to={props.disabled ? '#' : props.to} state={props.state} style={props.style} tabIndex={-1}>
                {props.type === 'primary' &&<PrimaryButton disabled={props.disabled}>{props.children}</PrimaryButton> }
                {props.type === 'secondary' &&<SecondaryButton disabled={props.disabled}>{props.children}</SecondaryButton> }
                {props.type === 'link' &&<StyledLinkSecondaryButton disabled={props.disabled}>{props.children}</StyledLinkSecondaryButton> }
        </Link>
    )
}

export function PrimaryButtonLink(props : ButtonLinkProps) {
    return(
        <ButtonLink {...props} type={'primary'} />
    )
}

export function SecondaryButtonLink(props : ButtonLinkProps) {
    return(
        <ButtonLink {...props} type={'secondary'} />
    )
}

export function TextButtonLink(props : ButtonLinkProps) {
    return(
        <ButtonLink {...props} type={'link'} />
    )
}