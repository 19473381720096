import {InterconnectApplicantStep} from "../../helpers/InterconnectApplicantStepIcons";
import {PrimaryButton, SecondaryButton} from "../../../../styledcomponents/FormStyledComponents";
import React from "react";
import {InterconnectApplicantButtonProps} from "./InterconnectApplicantForm";

function getNextStep(currentStep: InterconnectApplicantStep, stepOrder : InterconnectApplicantStep[]): InterconnectApplicantStep {
    let currentIndex = stepOrder.indexOf(currentStep);

    if (currentIndex === -1) { throw new Error('something') }

    if (currentIndex === stepOrder.length) { return currentStep; }

    return stepOrder[currentIndex + 1];
}

function getBackStep(currentStep : InterconnectApplicantStep, stepOrder : InterconnectApplicantStep[]): InterconnectApplicantStep {
    let currentIndex = stepOrder.indexOf(currentStep);

    let toIndex = currentIndex - 1;

    if (toIndex < 0) {
        console.error('Cannot go back');
        toIndex = currentIndex;
    }

    return stepOrder[toIndex];
}

type InterconnectApplicantButtonsProps = InterconnectApplicantButtonProps & {
    step : InterconnectApplicantStep
    nextDisabled ?: boolean
    processing ?: boolean
    onNext?: () => Promise<void> | void
}

export function InterconnectApplicantButtons(props : InterconnectApplicantButtonsProps) {
    async function onNext() {
        // if an onNext function is provided, call it. Otherwise, just go forward
        if (props.onNext) {
            await props.onNext()
        }

        if (props.step !== InterconnectApplicantStep.ReviewAndSubmit) {
            props.setCurrentStep(getNextStep(props.currentStep, props.steps))
        }
    }

    return (
        <div>
            {/* If it is the first step, give an exit button. Otherwise, conditionally show a back button */}
            {
                props.step != InterconnectApplicantStep.AccountInfo &&
                <SecondaryButton type={"button"} float={"left"} onClick={() => props.setCurrentStep(getBackStep(props.currentStep, props.steps))}>
                    Back
                </SecondaryButton>
            }
            <PrimaryButton disabled={props.nextDisabled || props.processing} onClick={onNext}>
                {
                    props.step === InterconnectApplicantStep.ReviewAndSubmit
                        ? props.processing ? 'Submitting...' : 'Submit'
                        : props.processing ? 'Processing...' : 'Next'
                }
            </PrimaryButton>
        </div>
    )
}