import {useLocation, useNavigate} from "react-router-dom";
import {useAxios} from "../../AxiosProvider";
import {useAuth} from "../../auth/AuthProvider";
import {Page, PathMap, usePath} from "../../PathProvider";
import {useMessageService} from "../../hooks/useMessageService";
import {VendorRegisterFormData} from "./RegistrationPage";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {Divider, GridColumnContainer, PageTitle} from "../../styledcomponents/MiscStyledComponents";
import {Form, Label, PrimaryButton} from "../../styledcomponents/FormStyledComponents";
import {SecondaryButtonLink} from "../misc/ButtonLink";
import ErrorUtils from "../../utils/errorUtils";
import {ResponseCode} from "../../enums/ResponseCode";
import ReCAPTCHA from "react-google-recaptcha";
import GreyBanner from "../misc/GreyBanner";
import {State} from "../../enums/State";

export default function ReviewRegistration() {
    const { state } = useLocation();
    const axios = useAxios();
    const navigate = useNavigate();
    const path = usePath();
    const messageService = useMessageService();

// useForm hooks
    const methods = useForm<VendorRegisterFormData>({
        defaultValues: state
    });
    const {handleSubmit, formState : {isSubmitting, isValid}} = methods;

    // reCAPTCHA token handler
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    // Form state handlers
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

    // useEffects
    useEffect(() => {
        setSubmitDisabled(isSubmitting || !isValid || !recaptchaToken);
    }, [isSubmitting, isValid, recaptchaToken]);

    const onSubmit : SubmitHandler<VendorRegisterFormData> = async (data : VendorRegisterFormData) => {
        // clear any messages once we submit
        messageService.clearAll();

        await axios?.openApi.post(
            '/api/register',
            {
                name: data.name,
                streetAddress: data.streetAddress,
                city: data.city,
                state: data.state,
                primaryState: data.primaryState === State.NY ? State.PA : data.primaryState,
                zipCode: data.zipCode,

                contactFirstName: data.contactFirstName,
                contactLastName: data.contactLastName,
                email: data.email,
                phoneNumber: data.phoneNumber,

                recaptchaResponse : recaptchaToken!
            }
        ).then((response : AxiosResponse) => {
            navigate(PathMap.get(Page.RegistrationSuccess));
        }).catch((err : AxiosError) => {
            let errorResponse : AxiosResponse | undefined = err.response;

            if (errorResponse?.status === 400) {
                messageService.error(ErrorUtils.getValidationErrors(errorResponse))
            } else if (errorResponse?.status === 409 ) {
                messageService.error(err.response?.data?.message)
                switch(errorResponse?.data.message) {
                    case ResponseCode.UserExists:
                        messageService.error("Username already exists.")
                        break;
                }
            } else {
                messageService.error(ErrorUtils.UNKNOWN_ERROR);
            }
        });
    };

    return(
        <FormProvider {...methods} >
            <PageTitle>Contractor/Installer Registration</PageTitle>

            <GreyBanner title={"Review & Submit"}/>
            <div>Please verify the information you have entered is correct. If you need to make a change please select the back button.</div><br />

            <Form onSubmit={handleSubmit(onSubmit)}>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"name"}  >Vendor Name:</Label>
                    <div>{state.name}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"streetAddress"}  >Street Address:</Label>
                    <div>{state.streetAddress}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"city"}  >City:</Label>
                    <div>{state.city}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"state"}  >State:</Label>
                    <div>{state.state}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"zipCode"}  >Zip Code:</Label>
                    <div>{state.zipCode}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"primaryState"}  >In Which State Will You Be Requesting Interconnection?:</Label>
                    <div>{state.primaryState}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"contactFirstName"}  >First Name:</Label>
                    <div>{state.contactFirstName}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"contactLastName"}  >Last Name:</Label>
                    <div>{state.contactLastName}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"email"}  >Email:</Label>
                    <div>{state.email}</div>
                </GridColumnContainer>

                <GridColumnContainer columns={2}>
                    <Label htmlFor={"phoneNumber"}  >Phone:</Label>
                    <div>{state.phoneNumber}</div>
                </GridColumnContainer>

                <Divider />

                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY ?? ''} onChange={token => {
                    setRecaptchaToken(token);
                }} /><br/>
                <div>
                    <SecondaryButtonLink to={path.get(Page.Registration)} state={state}>Back</SecondaryButtonLink>
                    <PrimaryButton type={"submit"} disabled={submitDisabled}>{isSubmitting ? 'Submitting...' : 'Submit'}</PrimaryButton>
                </div>

            </Form>

        </FormProvider>
    )

}